import { useMutation, useQuery } from "@apollo/client";
import mixpanel from "mixpanel-browser";
import React, { useEffect, useRef, useState } from "react";

import { apolloClient } from "src/components/context/ApolloContext";

import Button from "src/components/elements/Button";
import Checkbox from "src/components/elements/Checkbox";
import EmailInput from "src/components/elements/EmailInput";
import { FlashTypes } from "src/components/elements/Flash";
import Icon from "src/components/elements/Icon";
import Input from "src/components/elements/Input";
import Select from "src/components/elements/Select";
import Text from "src/components/elements/Text";

import { PasswordValidations } from "src/components/blocks/auth/PasswordValidations";
import { Captcha, captchaErrors } from "src/components/blocks/captcha";

import { useFlags } from "src/hooks/useFlags";
import useFlash from "src/hooks/useFlash";
import useQueryParams from "src/hooks/useQueryParams";

import {
  TEAMS_SUBSCRIPTION_PLANS,
  TEAM_SUBSCRIPTION_TYPES,
} from "src/models/team-subscription";

import { getSessionEmailHint } from "src/services/auth";
import { SUBSCRIPTION_TYPE } from "src/services/billing";
import { report } from "src/services/error-reporting";
import {
  clean,
  getAccessToken,
  setAccessToken,
  setPendingUserSubscription,
  setRefreshToken,
} from "src/services/storage";
import {
  Pages,
  SignupFailure,
  SignupOriginBillingCycle,
  SignupOriginProduct,
  SignupOriginSubscription,
  SignupSources,
  TeamsSignupSource,
  UpgradeSource,
  getSignupOriginPropsFromUrl,
  trackExperimentViewed,
  trackSignupCompleted,
  trackSignupEmailAdded,
  trackSignupFailed,
  trackSignupStarted,
  trackUpgradeStarted,
} from "src/services/tracking";
import {
  COUNTRIES,
  isValidEmail,
  prepRedirectUrlFromSearchParams,
  prepareTeamsOnboardingUrl,
} from "src/services/utils";

import { CREATE_ACCOUNT, VALIDATE_EMAIL } from "src/graphql/mutations";
import {
  CREATE_DRIVER_CHECKOUT_SESSION,
  GET_COUNTRY_CODE,
} from "src/graphql/queries";

import IllustrationTeams from "public/assets/img/illustrations/sign-up-illustration-teams.png";
import IllustrationTeams2x from "public/assets/img/illustrations/sign-up-illustration-teams@2x.png";
import Illustration from "public/assets/img/illustrations/sign-up-illustration.png";
import Illustration2x from "public/assets/img/illustrations/sign-up-illustration@2x.png";
import IllustrationTeamsQSignupLite from "public/assets/img/signup/teams-lite@2x.png";
import IllustrationTeamsQSignupLite2x from "public/assets/img/signup/teams-lite@4x.png";
import IllustrationTeamsQSignupPro from "public/assets/img/signup/teams-pro@2x.png";
import IllustrationTeamsQSignupPro2x from "public/assets/img/signup/teams-pro@4x.png";
import IllustrationTeamsQSignupStandard from "public/assets/img/signup/teams-standard@2x.png";
import IllustrationTeamsQSignupStandard2x from "public/assets/img/signup/teams-standard@4x.png";

export default QuickSignup;

export const SIGNUP_WITH_NAMES_VERSIONS = {
  v1: "v1",
  control: "control",
};

function redirectAfterSignup(url) {
  window.history.replaceState(null, "", "/");
  window.history.pushState(null, "", "/");
  window.location.replace(url);
}

function getTeamsPlanCode(sub) {
  let code;
  switch (sub) {
    case SignupOriginSubscription.STANDARD:
      code = TEAMS_SUBSCRIPTION_PLANS.TEAMS;
      break;
    case SignupOriginSubscription.PRO:
      code = TEAMS_SUBSCRIPTION_PLANS.TEAMS_PRO;
      break;
    case SignupOriginSubscription.LITE:
    default:
      code = TEAMS_SUBSCRIPTION_PLANS.TEAMS_LITE;
      break;
  }
  return code;
}

const countrySelectorData = {
  [COUNTRIES.US]: "United States",
  [COUNTRIES.CA]: "Canada",
  [COUNTRIES.GB]: "United Kingdom",
};

function QuickSignup() {
  const { signupOriginProduct: product, signupOriginSubscription: sub } =
    getSignupOriginPropsFromUrl();
  const {
    miqDashboardMinimalSignupCopiesWeb: minimalSignupCopies,
    miqDashboardTeamsSignupTestWeb: teamsSignupTest,
    miqDashboardStandaloneSignupTestWeb: standaloneSignupTest,
  } = useFlags();

  const {
    standalone: standaloneCopies = minimalSignupCopies?.standalone || {
      title: "We track your miles so you don't have to.",
      subtitle:
        "Join millions of MileIQ drivers who have logged over $10B in reimbursements and tax deductions.",
    },
    teams: teamsCopies = minimalSignupCopies?.teams || {
      title: "Your team has better things to do than count miles.",
      subtitle:
        "Create your MileIQ Team to automatically track your employees' drives, making reimbursements easier than ever.",
    },
  } = minimalSignupCopies || {};

  const isProductTeams = product === SignupOriginProduct.TEAMS;

  // this is where A/B signups are done
  let titleCopy, subtitleCopy, backgroundColor, logoClasses, logoUrl;
  let isABTest1, isABTest2;
  // is product teams
  if (isProductTeams) {
    titleCopy = teamsCopies.title;
    subtitleCopy = teamsCopies.subtitle;
    backgroundColor = "bg-[#D6F2FF]";
    logoClasses = "logo-with-text logo--teams";
    logoUrl = "https://mileiq.com/for-business";

    isABTest1 = teamsSignupTest === "Test 1";
    isABTest2 = teamsSignupTest === "Test 2";
    if (isABTest1) {
      titleCopy = "Start tracking drives in minutes";
      subtitleCopy = "Invite your team and you're off to the races";
      backgroundColor = "bg-[#0846a0]";
    }
    if (isABTest2) {
      titleCopy =
        "Teams save on average 70 payroll hours per employee annually with MileIQ";
      backgroundColor = "bg-[#D6F2FF]";
    }
  } else {
    backgroundColor = "bg-[#F8F3E7]";

    isABTest1 = standaloneSignupTest === "Test 1";
    isABTest2 = standaloneSignupTest === "Test 2";
    if (isABTest1) {
      backgroundColor = "bg-[#000]";
    }
    if (isABTest2) {
      backgroundColor = "bg-[#fc0]";
    }

    titleCopy = standaloneCopies.title;
    subtitleCopy = standaloneCopies.subtitle;
    logoClasses = "logo-with-text";
    logoUrl = "https://mileiq.com/";
  }

  const TeamsIllustrationSignup = (isABTest1 &&
    {
      [TEAMS_SUBSCRIPTION_PLANS.TEAMS_LITE]: {
        src: IllustrationTeamsQSignupLite,
        w540: IllustrationTeamsQSignupLite,
        w1080: IllustrationTeamsQSignupLite2x,
      },
      [TEAMS_SUBSCRIPTION_PLANS.TEAMS]: {
        src: IllustrationTeamsQSignupStandard,
        w540: IllustrationTeamsQSignupStandard,
        w1080: IllustrationTeamsQSignupStandard2x,
      },
      [TEAMS_SUBSCRIPTION_PLANS.TEAMS_PRO]: {
        src: IllustrationTeamsQSignupPro,
        w540: IllustrationTeamsQSignupPro,
        w1080: IllustrationTeamsQSignupPro2x,
      },
    }[getTeamsPlanCode(sub)]) || {
    src: IllustrationTeams,
    w540: IllustrationTeams,
    w1080: IllustrationTeams2x,
  };

  useEffect(() => {
    if (isProductTeams && typeof teamsSignupTest !== "undefined") {
      let variationId, variationName;
      switch (teamsSignupTest) {
        case "Test 1":
          variationId = 2;
          variationName = `V1 (How it Works) - ${sub}`;
          break;
        case "Test 2":
          variationId = 3;
          variationName = "V2 (Value)";
          break;
        default:
          variationId = 1;
          variationName = "Control";
          break;
      }
      trackExperimentViewed({
        experimentId: 22,
        experimentName: "Teams Sign Up Screen",
        variationId,
        variationName,
      });
    }
    if (!isProductTeams && typeof standaloneSignupTest !== "undefined") {
      let variationId, variationName;
      switch (standaloneSignupTest) {
        case "Test 1":
          variationId = 2;
          variationName = `V1 (Photography 1) - ${sub}`;
          break;
        case "Test 2":
          variationId = 3;
          variationName = "V2 (Photography 2)";
          break;
        default:
          variationId = 1;
          variationName = "Control";
          break;
      }
      trackExperimentViewed({
        experimentId: 26,
        experimentName: "SA Sign Up Screen",
        variationId,
        variationName,
      });
    }
  }, [teamsSignupTest, standaloneSignupTest, isProductTeams, sub]);

  // hiding displayed version until we have flag fetched to avoid flicker
  if (isProductTeams && typeof teamsSignupTest == "undefined") {
    return false;
  }

  const productTestClasses = [];
  if (isProductTeams) {
    if (isABTest1) {
      productTestClasses.push("quick-signup-teams-test1");
    }
    if (isABTest2) {
      productTestClasses.push("quick-signup-teams-test2");
    }
  }
  if (!isProductTeams) {
    if (isABTest1) {
      productTestClasses.push("quick-signup-standalone-test1");
    }
    if (isABTest2) {
      productTestClasses.push("quick-signup-standalone-test2");
    }
  }
  return (
    <div
      className={`${productTestClasses.join(
        " "
      )} w-full page-signup ${backgroundColor} mobile:bg-white flex justify-center mobile:items-start pt-[calc((100vh-640px)/2)] mobile:py-0`}
    >
      <a
        className={`${logoClasses} absolute left-[30px] top-[20px] mobile:left-[20px] mobile:top-[20px] z-10`}
        href={logoUrl}
        target="_blank"
        rel="noopener"
      />
      <div className="grid grid-cols-[490px_490px] gap-[100px] tablet:grid-cols-1">
        <div className="tablet:hidden pt-[80px]">
          <Text
            paragraph
            custom
            className="text-[28px] text-[#171717] font-medium"
          >
            {titleCopy}
          </Text>
          <Text
            paragraph
            custom
            className="mt-[15px] text-[16px] text-[#171717]/60"
          >
            {subtitleCopy}
          </Text>
          <div className="w-[490px] mt-[75px]">
            {isProductTeams ? (
              <img
                src={TeamsIllustrationSignup.src}
                srcSet={`${TeamsIllustrationSignup.w540} 540w, ${TeamsIllustrationSignup.w1080} 1080w`}
                sizes="(max-width: 1280px) 450px, 540px"
                alt="illustration"
                className="w-full"
              />
            ) : (
              <img
                src={Illustration}
                srcSet={`${Illustration} 540w, ${Illustration2x} 1080w`}
                sizes="(max-width: 1280px) 450px, 540px"
                alt="illustration"
                className="w-full"
              />
            )}
          </div>
        </div>
        <SignupForm />
      </div>
    </div>
  );
}
const countryOpts = {
  [COUNTRIES.US]: {
    label: countrySelectorData[COUNTRIES.US],
  },
  [COUNTRIES.GB]: {
    label: countrySelectorData[COUNTRIES.GB],
  },
  [COUNTRIES.CA]: {
    label: countrySelectorData[COUNTRIES.CA],
  },
};
function SignupForm() {
  const captchaRef = useRef();
  const [loadingCaptcha, setLoadingCaptcha] = useState(true);

  const {
    miqDashboardPricesWeb,
    miqDashboardTeamsProfileNames,
    miqDashboardSignupNamesWeb,
    miqDashboardQuickSignupRedirectGetTheAppWeb,
  } = useFlags();
  const isSignupNamesEnabled =
    miqDashboardTeamsProfileNames && miqDashboardSignupNamesWeb;
  const searchParams = useQueryParams();
  const [flash, Flash] = useFlash();

  useQuery(GET_COUNTRY_CODE, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setPromoOptIn(data?.countryCode !== COUNTRIES.GB);
      if (
        data?.countryCode &&
        Object.values(COUNTRIES).includes(data.countryCode)
      ) {
        setSelectedCountry(data.countryCode);
      }
    },
  });

  const [createAccount, createAccountMutState] = useMutation(CREATE_ACCOUNT, {
    notifyOnNetworkStatusChange: true,
  });
  const [validateEmailMut, { loading }] = useMutation(VALIDATE_EMAIL, {
    notifyOnNetworkStatusChange: true,
  });

  const hint =
    searchParams?.get?.("hint") ||
    searchParams?.get?.("email") ||
    getSessionEmailHint();
  const [email, setEmail] = useState(hint || "");
  const [suggestedEmail, setSuggestedEmail] = useState(null);
  const [emailErrorMessage, setEmailErrorMessage] = useState(null);
  const [showImmediateEmailError, setShowImmediateEmailError] = useState(false);

  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const isFirstNameValid = firstName?.trim() !== "" || !isSignupNamesEnabled;
  const [lastName, setLastName] = useState("");
  const isLastNameValid = lastName?.trim() !== "" || !isSignupNamesEnabled;
  const [isValidPassword, setIsValidPassword] = useState(false);
  const [showPasswordValidationErrors, setShowPasswordValidationErrors] =
    useState(false);
  const [showPasswordRequirements, setShowPasswordRequirements] =
    useState(false);

  const [selectedCountry, setSelectedCountry] = useState(COUNTRIES.US);

  const [promoOptIn, setPromoOptIn] = useState(true);

  const [onceSubmitted, setOnceSubmitted] = useState(false);

  const [checkoutInProgress, setCheckoutInProgress] = useState(false);

  const lastUsedSuggestion = useRef(null); // need this for tracking

  const hasCheckoutParams = () => {
    return searchParams.get("sub") && searchParams.get("product");
  };

  const checkout = async (user) => {
    if (checkoutInProgress) return;

    setCheckoutInProgress(true);

    const {
      signupOriginProduct: product,
      signupOriginBillingCycle: billingCycle,
      signupOriginSubscription: sub,
    } = getSignupOriginPropsFromUrl();

    const userId = user.objectId;

    const GET_THE_APP_PATH = "/get-the-app";
    const GET_STARTED_PATH = "/user/get-started";

    let url = new URL(
      miqDashboardQuickSignupRedirectGetTheAppWeb
        ? GET_THE_APP_PATH
        : GET_STARTED_PATH,
      window.location.origin
    );

    if (miqDashboardQuickSignupRedirectGetTheAppWeb) {
      url.searchParams.append("uid", userId);
    }

    // FREE sub - no checkout
    if (sub === SignupOriginSubscription.FREE) {
      redirectAfterSignup(url.toString());
      return;
    }

    if (product === SignupOriginProduct.STANDALONE) {
      const type =
        billingCycle === SignupOriginBillingCycle.MONTHLY
          ? SUBSCRIPTION_TYPE.MONTHLY
          : SUBSCRIPTION_TYPE.ANNUAL;
      const featureFlagPricesForUserCountry = miqDashboardPricesWeb?.filter(
        (price) => price?.country === user.country
      );
      const stripePriceID = featureFlagPricesForUserCountry?.find(
        (price) => price?.type === type
      )?.stripePriceID;

      url.searchParams.append("checkout-status", "success");
      const successUrl = url.toString();
      url.searchParams.set("checkout-status", "cancel");
      const cancelUrl = url.toString();

      const { data } = await apolloClient.query({
        query: CREATE_DRIVER_CHECKOUT_SESSION,
        variables: {
          subscriptionType: type,
          stripePriceID,
          promoCode: searchParams.get("promo_code"),
          successUrl,
          cancelUrl,
        },
        context: {
          headers: {
            authorization: `Bearer ${getAccessToken()}`,
          },
        },
      });
      if (data?.createDriverCheckoutSession?.url) {
        setPendingUserSubscription({
          isNew: true,
          subType: type,
          uid: userId,
        });
        trackUpgradeStarted({
          page: Pages.ONBOARDING,
          src: UpgradeSource.ONBOARDING,
        });
        redirectAfterSignup(data.createDriverCheckoutSession.url);
        return;
      }
    } else if (product === SignupOriginProduct.TEAMS) {
      const type =
        billingCycle === SignupOriginBillingCycle.MONTHLY
          ? TEAM_SUBSCRIPTION_TYPES.MONTH
          : TEAM_SUBSCRIPTION_TYPES.YEAR;
      let url = prepareTeamsOnboardingUrl(
        getTeamsPlanCode(sub),
        type,
        searchParams.get("src") == SignupSources.CONTACTS_DASHBOARD
          ? TeamsSignupSource.CONTACTS_DASHBOARD_NON_DRIVER
          : ""
      );
      if (searchParams.get("singleSku")) {
        url += "&singleSku=true";
      }
      redirectAfterSignup(url);
      return;
    } else {
      redirectAfterSignup("/");
      return;
    }
  };

  const handleSuggestionClick = (e) => {
    e.preventDefault();
    lastUsedSuggestion.current = suggestedEmail;
    handleEmailChange(suggestedEmail);
    trackSignupEmailAdded({
      email,
      isSuggested: true,
    });
    validateEmail(suggestedEmail);
  };

  const handleCloseSuggestion = (e) => {
    e.preventDefault();
    setSuggestedEmail(null);
  };

  const handleEmailChange = (email) => {
    setSuggestedEmail(null);
    setEmailErrorMessage(null);
    setEmail(email);
  };

  const validateEmail = async (email) => {
    setEmailErrorMessage(null);

    if (!isValidEmail(email)) {
      setShowImmediateEmailError(true);
      setEmailErrorMessage("Please enter a valid email address");
      trackSignupFailed({
        email,
        signupFailure: SignupFailure.INVALID_EMAIL,
      });
      return false;
    }

    const {
      data: {
        validateEmail: { suggestion, userExists },
      },
    } = await validateEmailMut({ variables: { email } });

    if (userExists) {
      setShowImmediateEmailError(true);
      trackSignupFailed({
        email,
        signupFailure: SignupFailure.ACCOUNT_ALREADY_EXISTS,
      });
      const sp = new URLSearchParams(searchParams);
      sp.set("email", email);
      setEmailErrorMessage(
        <span>
          An account with this email address already exists.{" "}
          <a
            href={`/login?${sp.toString()}`}
            className="text-blue hover:text-blue-hover underline font-medium"
          >
            Log in
          </a>
        </span>
      );
      return false;
    }
    if (suggestion) {
      const suggest = email.split("@")[0] + "@" + suggestion;
      return setSuggestedEmail(suggest);
    }
    return true;
  };

  const handleEmailBlur = async () => {
    setTimeout(() => {
      // miq-6245: need short timeout for coutry select to register click
      //           (in case it was clicked and caused blur)
      if (!validateEmail(email)) return;
      trackSignupEmailAdded({
        email,
        isSuggested: false,
      });
    }, 100);
  };

  const handleFirstNameChange = (e) => {
    const val = e.target.value || "";
    setFirstName(val);
  };

  const handleLastNameChange = (e) => {
    const val = e.target.value || "";
    setLastName(val);
  };

  const handlePasswordChange = (password) => {
    setPassword(password);
  };

  const handleValidPassword = (valid) => {
    setIsValidPassword(valid);
    if (valid) {
      setShowPasswordValidationErrors(false);
    }
  };

  const handleSelectCountry = (c) => {
    setSelectedCountry(c);
  };

  const handleAgreementChange = (checked) => {
    setPromoOptIn(checked);
  };

  const onCaptchaLoad = () => {
    setLoadingCaptcha(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setOnceSubmitted(true);
    setFirstName(firstName.trim());
    setLastName(lastName.trim());

    if (loadingCaptcha || createAccountMutState.loading) return;

    const emailValid = isValidEmail(email);
    const pwdValid = isValidPassword && password.length;
    if (!emailValid) {
      setEmailErrorMessage("Please enter a valid email address");
      trackSignupFailed({
        email,
        signupFailure: SignupFailure.INVALID_EMAIL,
      });
    }

    if (!pwdValid) {
      setShowPasswordValidationErrors(true);
      trackSignupFailed({
        email,
        signupFailure: SignupFailure.INVALID_PASSWORD,
      });
    }

    if (!isFirstNameValid) {
      trackSignupFailed({
        email,
        signupFailure: SignupFailure.FIRST_NAME_REQUIRED,
      });
    }

    if (!isLastNameValid) {
      trackSignupFailed({
        email,
        signupFailure: SignupFailure.LAST_NAME_REQUIRED,
      });
    }

    if (!emailValid || !pwdValid || !isFirstNameValid || !isLastNameValid)
      return;

    setEmailErrorMessage(null);
    setShowPasswordValidationErrors(false);

    try {
      let headers;
      if (captchaRef?.current) {
        const captchaToken = await captchaRef.current.executeAsync?.();
        captchaRef.current.reset?.();
        if (!captchaToken) {
          throw new Error(captchaErrors.RECAPTCHA_TOKEN_REQUIRED);
        }
        headers = {
          "x-recaptcha-token": captchaToken,
        };
      }

      const {
        data: {
          createAccount: { code, user, token, refreshToken },
        },
      } = await createAccount({
        variables: {
          mixpanelDistinctId: mixpanel?.get_distinct_id() || "",
          username: email,
          password,
          promoOptIn,
          country: selectedCountry,
          firstName,
          lastName,
        },
        context: {
          headers,
        },
      });

      if (code === 200) {
        if (mixpanel) mixpanel.alias(user.objectId);
        setAccessToken(token);
        setRefreshToken(refreshToken);

        // this is for Tinuiti TV ads pixel
        setTimeout(() => {
          trackSignupCompleted({ userId: user.objectId });

          // this is for Tinuiti TV ads pixel
          setTimeout(() => {
            if (hasCheckoutParams()) {
              return checkout(user);
            } else {
              const redirectUrl = prepRedirectUrlFromSearchParams(
                searchParams,
                "/onboarding"
              );
              return redirectAfterSignup(redirectUrl);
            }
          }, 200);
        }, 200);
      }
      if (code === 403) {
        trackSignupFailed({
          email,
          signupFailure: SignupFailure.ACCOUNT_ALREADY_EXISTS,
        });
        flash(
          <p>
            This email is already registered, please{" "}
            <a
              href={`/login?${searchParams?.toString()}`}
              className="underline"
            >
              try login
            </a>{" "}
            instead.
          </p>,
          {
            type: FlashTypes.ERROR,
            hideAfterMs: 10000,
          }
        );
      }
    } catch (e) {
      const errorMessage =
        e?.message || e || "Something went wrong while creating this account.";
      report(errorMessage);
      if (
        [400, 422, 500].includes(e?.networkError?.statusCode) ||
        (errorMessage === captchaErrors.RECAPTCHA_TOKEN_REQUIRED &&
          captchaRef?.current)
      ) {
        captchaRef.current.reset?.();
        captchaRef.current.execute?.();
      }
      flash(
        <p>
          Something went wrong while creating your account. Please try again
          later or contact the support.
        </p>,
        {
          type: FlashTypes.ERROR,
        }
      );
    }
  };

  useEffect(() => {
    clean();
    trackSignupStarted();
  }, []);

  useEffect(() => {
    if (isSignupNamesEnabled === true || isSignupNamesEnabled === false) {
      trackExperimentViewed({
        experimentId: 14,
        experimentName: "Signup with Names",
        variationId: isSignupNamesEnabled ? 2 : 1,
        variationName: isSignupNamesEnabled
          ? SIGNUP_WITH_NAMES_VERSIONS.v1
          : SIGNUP_WITH_NAMES_VERSIONS.control,
      });
    }
  }, [isSignupNamesEnabled]);

  return (
    <div className="relative flex w-full h-max min-h-[640px] items-start justify-center px-[60px] py-[80px] mobile:px-[30px] mobile:pt-[115px] bg-white rounded-[30px] shadow-[0px_4px_12px_0px_rgba(0,0,0,0.08)] mobile:shadow-none">
      <div className="page-signup__form">
        <div className="text-center">
          <h2>Sign up</h2>
          <form
            onSubmit={handleSubmit}
            noValidate
            className="flex flex-col gap-[12px] mt-[40px]"
          >
            {isSignupNamesEnabled && (
              <div className="flex gap-[12px]">
                <div className="w-full">
                  <Input
                    autoFocus
                    value={firstName}
                    onChange={handleFirstNameChange}
                    placeholder="First name"
                    valid={
                      !onceSubmitted || (onceSubmitted && isFirstNameValid)
                    }
                    data-testid="signup-first-name-input"
                  />
                  {onceSubmitted && !isFirstNameValid && (
                    <Text
                      regular
                      color="red"
                      paragraph
                      className="text-left mt-[10px]"
                      data-testid="first-name-require-message"
                    >
                      {"Required Field"}
                    </Text>
                  )}
                </div>
                <div className="w-full">
                  <Input
                    value={lastName}
                    onChange={handleLastNameChange}
                    placeholder="Last name"
                    valid={!onceSubmitted || (onceSubmitted && isLastNameValid)}
                    data-testid="signup-last-name-input"
                  />
                  {onceSubmitted && !isLastNameValid && (
                    <Text
                      regular
                      color="red"
                      paragraph
                      className="text-left mt-[10px]"
                      data-testid="last-name-require-message"
                    >
                      {"Required Field"}
                    </Text>
                  )}
                </div>
              </div>
            )}
            <div>
              <EmailInput
                value={email}
                onChange={handleEmailChange}
                onBlur={handleEmailBlur}
                error={
                  (onceSubmitted || showImmediateEmailError) &&
                  emailErrorMessage
                }
                data-testid="signup-email-input"
                placeholder="Email address"
              />
              {suggestedEmail && (
                <p className="text-black/50 text-left mt-[10px]">
                  Did you mean:
                  <button
                    type="button"
                    tabIndex="-1"
                    onClick={handleSuggestionClick}
                    className="text-blue hover:text-blue-hover px-2 font-medium"
                  >
                    {suggestedEmail}
                  </button>
                  <button
                    type="button"
                    tabIndex="-1"
                    onClick={handleCloseSuggestion}
                  >
                    <Icon name="close-small" />
                  </button>
                </p>
              )}
            </div>
            <div className="[&_.miq-password-input-wrap]:m-0">
              <PasswordValidations
                password={password}
                onChange={handlePasswordChange}
                onValid={handleValidPassword}
                showValidationErrors={
                  onceSubmitted && showPasswordValidationErrors
                }
                inputProps={{
                  "data-testid": "signup-password-input",
                  autoFocus: false,
                  onFocus: () => setShowPasswordRequirements(true),
                }}
                requirementsClassName={[
                  "!mt-[12px]",
                  showPasswordRequirements || showPasswordValidationErrors
                    ? "animate-slideTopBottom"
                    : "hidden",
                ].join(" ")}
              />
            </div>
            <div>
              <Select
                disabled={loading || checkoutInProgress}
                triggerIcon="globe"
                options={countryOpts}
                placeholder="Select country"
                selected={selectedCountry}
                onSelect={handleSelectCountry}
                matchTriggerWidth
                dropdownOptions={{
                  processElements: ({ containerEl, triggerEl }) => {
                    if (!triggerEl || !containerEl) return;
                    const triggerRect = triggerEl.getBoundingClientRect();
                    containerEl.style.position = "absolute";
                    containerEl.style.left = 0;
                    containerEl.style.top = triggerRect.height + 4 + "px";
                    containerEl.style.width = triggerRect.width + "px";
                  },
                  renderTrigger: () => {
                    return (
                      <>
                        <Icon name="globe" className="prefix" />
                        <Text
                          semibold
                          className={`w-full miq-dropdown-label font-normal`}
                        >
                          {countrySelectorData[selectedCountry]}
                        </Text>
                        <div className="suffix">
                          <Icon name="caret" color="black" />
                        </div>
                      </>
                    );
                  },
                }}
              />
            </div>
            <label className="flex text-black/60">
              <Checkbox checked={promoOptIn} onChange={handleAgreementChange} />{" "}
              I agree to receive MileIQ news and updates.
            </label>
            <div className="flex mt-[14px] mb-[14px] justify-center">
              <Captcha ref={captchaRef} onLoad={onCaptchaLoad} />
            </div>
            <Button
              lg
              type="submit"
              className={"w-full"}
              loading={createAccountMutState.loading || checkoutInProgress}
              disabled={
                loadingCaptcha ||
                loading ||
                createAccountMutState.loading ||
                checkoutInProgress
              }
              data-testid="signup-btn"
            >
              Create account
            </Button>
          </form>
          <p className="text-13 mt-[40px] text-black/50">
            By continuing, you agree to MileIQ{" "}
            <a
              className="underline"
              href="https://mileiq.com/terms"
              target="_blank"
              rel="noopener"
            >
              Terms
            </a>{" "}
            and{" "}
            <a
              className="underline whitespace-nowrap"
              href="https://mileiq.com/privacy"
              target="_blank"
              rel="noopener"
            >
              Privacy Policy
            </a>
          </p>
        </div>
      </div>
      {Flash}
    </div>
  );
}
