{
  "name": "mileiq-dashboard",
  "version": "3.47.0",
  "description": "",
  "lint-staged": {
    "**/*": [
      "prettier --write --ignore-unknown",
      "eslint"
    ]
  },
  "scripts": {
    "clean": "rm -rf dist .cache .parcel-cache",
    "start": "parcel ./public/index.html  & npm run watch",
    "postinstall": "npm run cp_mds_assets",
    "cp_mds_assets": "cp -r node_modules/@mileiq/design-system/dist/mds-assets ./public",
    "cp_robots": "cp ./public/robots.txt ./dist/",
    "prebuild": "NODE_ENV=production npm run tw",
    "build": "NODE_ENV=production parcel build ./public/index.html",
    "postbuild": "cp -r node_modules/@mileiq/design-system/dist/mds-assets ./dist/mds-assets",
    "cy:open": "./node_modules/.bin/cypress open",
    "cy:run": "./node_modules/.bin/cypress run",
    "test:e2e": "cypress run",
    "test:unit": "jest",
    "test:create": "node ./cypress/support/create-feature.js",
    "format": "prettier --write \"src/**/*.{js,jsx}\"",
    "lint": "eslint \"src/**/*.{js,jsx}\" --quiet",
    "tw": "postcss src/styles/tailwind.css -o public/assets/css/tailwind.css",
    "prepare": "husky install",
    "watch": "chokidar 'src/**/*.{js,jsx,css}' --ignoreDotFiles --ignoreUnreadable --command 'npm run tw'",
    "dev": "npm run clean && npm run start"
  },
  "browserslist": [
    "last 2 Chrome versions"
  ],
  "alias": {
    "public": "./public",
    "src": "./src"
  },
  "keywords": [],
  "author": "",
  "license": "ISC",
  "engines": {
    "node": ">=18.11.0",
    "npm": ">=8.19.0"
  },
  "dependencies": {
    "@apollo/client": "3.6.9",
    "@braze/web-sdk": "4.5.0",
    "@bugsnag/js": "7.10.5",
    "@bugsnag/plugin-react": "7.10.0",
    "@chamaeleonidae/chmln": "^1.0.1",
    "@mileiq/design-system": "0.1.4",
    "@parcel/plugin": "^2.12.0",
    "@segment/analytics-next": "1.38.0",
    "@stripe/react-stripe-js": "2.4.0",
    "@stripe/stripe-js": "2.3.0",
    "avo-inspector": "1.2.7",
    "date-fns": "2.23.0",
    "firebase": "^9.12.1",
    "graphql": "16.5.0",
    "launchdarkly-react-client-sdk": "3.0.6",
    "libphonenumber-js": "^1.10.14",
    "mixpanel-browser": "2.45.0",
    "qrcode": "1.5.3",
    "react": "18.2.0",
    "react-dom": "18.2.0",
    "react-dropzone": "14.2.2",
    "react-google-recaptcha": "3.1.0",
    "react-router-dom": "5.2.0",
    "react-transition-group": "4.4.5",
    "recharts": "2.9.3",
    "rudder-sdk-js": "2.31.1",
    "serve-static": "^1.15.0"
  },
  "devDependencies": {
    "@babel/plugin-proposal-optional-chaining": "7.21.0",
    "@babel/preset-env": "7.24.4",
    "@babel/preset-react": "7.24.1",
    "@badeball/cypress-cucumber-preprocessor": "20.0.3",
    "@bahmutov/cypress-esbuild-preprocessor": "2.2.0",
    "@googlemaps/jest-mocks": "2.7.5",
    "@testing-library/jest-dom": "6.4.2",
    "@testing-library/react": "15.0.2",
    "@trivago/prettier-plugin-sort-imports": "4.3.0",
    "babel-jest": "28.1.2",
    "babel-plugin-inline-react-svg": "2.0.1",
    "babel-plugin-module-resolver": "4.1.0",
    "chokidar-cli": "^3.0.0",
    "cypress": "13.0.0",
    "eslint": "8.19.0",
    "eslint-config-prettier": "8.5.0",
    "eslint-plugin-cucumber": "^2.0.0",
    "eslint-plugin-cypress": "2.12.1",
    "eslint-plugin-json": "3.1.0",
    "eslint-plugin-react": "7.29.4",
    "eslint-plugin-react-hooks": "4.6.0",
    "eslint-plugin-spellcheck": "github:mileiq/eslint-plugin-spellcheck",
    "husky": "7.0.0",
    "jest": "28.1.2",
    "jest-environment-jsdom": "28.1.2",
    "lint-staged": "13.0.3",
    "parcel": "2.12.0",
    "postcss": "8.4.14",
    "postcss-cli": "8.3.1",
    "postcss-import": "14.0.2",
    "prettier": "2.3.2",
    "process": "0.11.10",
    "react-test-renderer": "18.2.0",
    "tailwindcss": "3.3.5",
    "whatwg-fetch": "^3.6.2",
    "yargs": "^17.7.1"
  },
  "cypress-cucumber-preprocessor": {
    "stepDefinitions": [
      "cypress/e2e/[filepart]/*.js",
      "cypress/e2e/common/*.js"
    ]
  }
}
