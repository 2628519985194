import React from "react";

import { useUser } from "src/components/context/UserContext";

import Text from "src/components/elements/Text";

import { useFlags } from "src/hooks/useFlags";

export default function TeamMemberName({ member, adminMeTag }) {
  const { miqDashboardTeamsProfileNames: isProfileNamesEnabled } = useFlags();
  const { user } = useUser();
  const memberName =
    member.firstName && member.lastName
      ? `${member.firstName} ${member.lastName}`
      : null;
  let displayNameText =
    isProfileNamesEnabled && memberName ? memberName : member?.email;
  let emailText = member.email;
  if (adminMeTag && member.id === user.id) {
    displayNameText += " (me)";
  }
  return (
    <div className="flex flex-col">
      <Text
        lg
        bold={!isProfileNamesEnabled}
        className="truncate"
        title={displayNameText}
      >
        {displayNameText}
      </Text>
      {isProfileNamesEnabled && (
        <Text sm color={"black/50"} className="truncate" title={emailText}>
          {emailText}
        </Text>
      )}
    </div>
  );
}
