import { useMutation, useQuery } from "@apollo/client";
import { useContext, useRef, useState } from "react";

import { registerElement } from "src/lib/layers/LayersProvider";

import { useAppFlash } from "src/components/context/Flash";

import Button from "src/components/elements/Button";
import { FlashTypes } from "src/components/elements/Flash";
import Icon from "src/components/elements/Icon";
import Input from "src/components/elements/Input";
import Modal from "src/components/elements/Modal";
import Select from "src/components/elements/Select";
import Text from "src/components/elements/Text";

import {
  PAYMENT_METHOD,
  PAYMENT_METHOD_DATA,
  SUBSCRIPTION_TYPE,
} from "src/services/billing";
import {
  trackSubscriptionCancelCompleted,
  trackSubscriptionCancelReasonSelected,
} from "src/services/tracking";
import {
  COUNTRIES_DATA,
  formatCurrency,
  toUIDistance,
} from "src/services/utils";

import { CANCEL_INDIVIDUAL_SUBSCRIPTION } from "src/graphql/mutations";
import { GET_USER_ALL_TIME_TOTALS } from "src/graphql/queries";

import ImgPiggyBank from "public/assets/img/piggy-bank.svg";

import { UserDataContext } from "../context/UserContext";

export const ELEMENT_ID = "CANCEL_DRIVER_SUBSCRIPTION";
registerElement(ELEMENT_ID, CancelDriverSubscription);

const CANCEL_REASONS = {
  NO_NEED: "no_need",
  TOO_EXPENSIVE: "too_expensive",
  BROKEN: "broken",
  SWITCH_TOOL: "switch_tool",
  MISSING_FEATURES: "missing_features",
  OTHER: "other",
};

const CANCEL_REASON_OPTIONS = {
  [CANCEL_REASONS.NO_NEED]: {
    label: "No longer need to track my business miles",
  },
  [CANCEL_REASONS.TOO_EXPENSIVE]: { label: "It's too expensive" },
  [CANCEL_REASONS.BROKEN]: { label: "It didn't work properly" },
  [CANCEL_REASONS.SWITCH_TOOL]: { label: "Switching to another tool" },
  [CANCEL_REASONS.MISSING_FEATURES]: { label: "Missing features" },
  [CANCEL_REASONS.OTHER]: { label: "Other" },
};

function CancelDriverSubscription({ onClose }) {
  const { refreshSubscription } = useContext(UserDataContext);
  const modalRef = useRef();
  const handleDropdownOpen = () => {
    if (modalRef?.current) {
      const scrollHeight = modalRef.current.scrollHeight;
      const modalHeight = modalRef.current.offsetHeight;
      modalRef.current.scrollTop = scrollHeight - modalHeight - 10;
    }
  };

  const handleDropdownClose = () => {
    if (modalRef?.current) {
      modalRef.current.scrollTop = 0;
    }
  };

  async function handleDone() {
    await refreshSubscription();
    onClose();
  }

  return (
    <Modal
      onClose={onClose}
      className="w-[440px]"
      additionalContentSize={190}
      ref={modalRef}
      contentClassName="p-[30px] pt-[50px]"
      passiveBackdrop
    >
      <CancelationForm
        onDone={handleDone}
        onDismiss={onClose}
        onDropdownOpen={handleDropdownOpen}
        onDropdownClose={handleDropdownClose}
      />
    </Modal>
  );
}

export function CancelationForm({
  onDropdownOpen,
  onDropdownClose,
  onDone,
  onDismiss,
}) {
  const { userData } = useContext(UserDataContext);
  const { paymentService, type: subscriptionType } = userData.subscriptionData;
  const [selectedReason, setSelectedReason] = useState("");
  const [customReason, setCustomReason] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { flash } = useAppFlash();
  const [cancelSubMutFn] = useMutation(CANCEL_INDIVIDUAL_SUBSCRIPTION, {
    notifyOnNetworkStatusChange: true,
  });

  const isCustomReason = selectedReason === CANCEL_REASONS.OTHER;
  const isValidReason = isCustomReason ? !!customReason : !!selectedReason;

  function handleReasonSelected(reason) {
    setSelectedReason(reason);
    setCustomReason("");
    trackSubscriptionCancelReasonSelected({
      reason: CANCEL_REASON_OPTIONS[reason].label,
      subType: subscriptionType,
      service: paymentService,
    });
  }

  async function handleSubmit(e) {
    e.preventDefault();

    if (loading) return;

    try {
      setLoading(true);

      trackSubscriptionCancelCompleted({
        reason: CANCEL_REASON_OPTIONS[selectedReason].label,
        subType: subscriptionType,
        service: paymentService,
        feedback: selectedReason === CANCEL_REASONS.OTHER ? customReason : "",
      });

      switch (paymentService) {
        case PAYMENT_METHOD.STRIPE:
          await cancelSubMutFn();
          await onDone();
          break;
        case PAYMENT_METHOD.APPLE:
        case PAYMENT_METHOD.APPLE_SANDBOX:
        case PAYMENT_METHOD.GOOGLE:
          if (PAYMENT_METHOD_DATA[paymentService].editPaymentInfoUrl) {
            await onDone();
            window.location.href =
              PAYMENT_METHOD_DATA[paymentService].editPaymentInfoUrl;
            return;
          }
      }

      const msg = `You’ve canceled your Unlimited, ${
        subscriptionType === SUBSCRIPTION_TYPE.MONTHLY ? "Monthly" : "Annual"
      } subscription`;

      flash(
        <Text custom className="text-15 leading-[21px]">
          {msg}
        </Text>,
        {
          type: FlashTypes.BLACK,
        }
      );
    } catch (err) {
      console.error("Something went wrong while cancelling subscription", err);
      setError(true);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      {error ? (
        <>
          <h5>Sorry, something went wrong.</h5>

          <Text paragraph className="mt-2.5">
            Please check your connection and try again later. Or contact our{" "}
            <a
              href="https://support.mileiq.com"
              target="_blank"
              className="text-blue hover:text-blue"
            >
              customer support
            </a>{" "}
            team.
          </Text>
          <div className="mt-[30px] flex items-center gap-2.5 justify-end">
            <Button
              className="w-[120px] font-medium"
              secondary
              onClick={onDismiss}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              className="font-medium w-[120px]"
              destructive
            >
              {loading ? <Icon name="spinner" /> : "Try again"}
            </Button>
          </div>
        </>
      ) : (
        <>
          <h5>Are you sure you want to cancel?</h5>
          <Text paragraph className="mt-2.5">
            Please let us know why you're cancelling your subscription.
          </Text>
          <form className="mt-[30px]" onSubmit={handleSubmit}>
            <div className="flex items-center gap-2.5 mt-2.5">
              <Select
                options={CANCEL_REASON_OPTIONS}
                placeholder="Select reason"
                selected={selectedReason}
                onOpen={onDropdownOpen}
                onClose={onDropdownClose}
                onSelect={handleReasonSelected}
                dropdownOptions={{
                  className: `miq-select ${
                    isCustomReason ? "w-[120px]" : "w-full"
                  }`,
                  contentClassName: "min-w-max w-full",
                  processElements: null,
                }}
                listClassName="max-h-[250px] overflow-y-auto"
              />
              {isCustomReason && (
                <Input
                  value={customReason}
                  onChange={(e) => setCustomReason(e.target.value)}
                  placeholder="Please specify"
                  className="flex-grow"
                  maxLength="100"
                />
              )}
            </div>
            <Savings />
            <div className="mt-[30px] flex items-center gap-2.5 justify-end">
              <Button
                className="font-medium min-w-[163.55px]"
                secondary
                type="submit"
                disabled={!isValidReason}
              >
                {loading ? <Icon name="spinner" /> : "Cancel subscription"}
              </Button>
              <Button
                className="w-[120px] font-medium"
                type="button"
                onClick={onDismiss}
              >
                Keep my plan
              </Button>
            </div>
          </form>
        </>
      )}
    </>
  );
}

function Savings() {
  const { userData } = useContext(UserDataContext);
  const allTimeTotalsQuery = useQuery(GET_USER_ALL_TIME_TOTALS, {
    notifyOnNetworkStatusChange: true,
  });

  if (allTimeTotalsQuery.loading) {
    return null;
  }

  const { value, distance } = allTimeTotalsQuery.data.drivesSummary;

  if (!distance) return null;

  const distanceFormatted = `${Math.round(
    toUIDistance(distance, userData.distanceUnit)
  )} ${
    userData.distanceUnit === "km"
      ? COUNTRIES_DATA[userData.country].unitName.toLowerCase()
      : "miles"
  }`;
  const valueFormatted = formatCurrency({
    value: value || 0,
    currency: userData.currency,
  });

  return (
    <div className="mt-[30px] bg-bone-white rounded px-[30px] py-[20px] flex gap-[20px] items-center">
      <ImgPiggyBank className="shrink-0" />
      <Text paragraph>
        MileIQ has tracked <Text bold>{distanceFormatted}</Text> and saved you{" "}
        <Text bold>{valueFormatted}</Text>
      </Text>
    </div>
  );
}
