import React, { createRef, useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

import TeamContext from "src/components/context/TeamContext";
import { useUser, useUserData } from "src/components/context/UserContext";

import Dropdown from "src/components/elements/Dropdown";
import Icon from "src/components/elements/Icon";
import Text from "src/components/elements/Text";
import Tooltip from "src/components/elements/Tooltip";

import { useFlags } from "src/hooks/useFlags";
import useQueryParams from "src/hooks/useQueryParams";

import { TEAMS_SUBSCRIPTION_PLANS } from "src/models/team-subscription";

import { logout } from "src/services/auth";
import { SUBSCRIPTION_STATUS } from "src/services/billing";
import {
  BlogSource,
  DashboardSwitchFrom,
  DashboardSwitchedTo,
  trackBlogSelected,
  trackDashboardSwitched,
  trackHelpSelected,
} from "src/services/tracking";
import { evaluate, getZendeskJwtAuthUrl } from "src/services/utils";

import { version } from "src/env";

import TeamsLogo from "public/assets/img/nav/teams-logo.svg";
import IndividualWordMark from "public/assets/img/nav/wordmark-individual.svg";
import TeamsWordMark from "public/assets/img/nav/wordmark-teams.svg";

import { useNotifications } from "./context/NotificationsContext";

const driverDashboardNav = [
  {
    isGroupTitle: true,
    title: "Menu",
  },
  {
    label: "Get Started",
    path: "/user/get-started",
    highlighted: true,
    icon: "arrow-circle-right",
    hide: (userData) => !userData.showGetStartedPage,
  },
  {
    label: "Drives",
    path: "/user/drives",
    icon: "car-side",
  },
  {
    label: "Reports",
    path: "/user/reports",
    icon: "reports",
  },
  {
    id: "sharedReports",
    label: "Shared with me",
    path: "/user/shared-reports",
    icon: "shared-reports",
  },
  {
    id: "userSubscription",
    notifications: 0,
    label: "Subscription",
    path: "/user/subscription",
    icon: "card",
  },
  {
    label: "Settings",
    path: "/user/settings",
    icon: "gear",
    liClass: "last-in-group",
  },
  {
    isGroupTitle: true,
    title: "MileIQ for Teams",
  },
  {
    isExternalLink: (isTeamAdmin) => !!isTeamAdmin,
    label: (isTeamAdmin) => (isTeamAdmin ? "Teams Dashboard" : "Teams"),
    path: (isTeamAdmin) => (isTeamAdmin ? "/teams" : "/user/teams"),
    rel: (isTeamAdmin) => (isTeamAdmin ? "opener" : null),
    target: (isTeamAdmin) => (isTeamAdmin ? "mileiq.teams" : null),
    notifications: (notifications) => {
      return notifications?.invite_link ? 1 : null;
    },
    notificationsColor: "blue",
    icon: (isTeamAdmin) => (isTeamAdmin ? "building" : "people"),
    onClick: (isTeamAdmin) => {
      if (isTeamAdmin) {
        trackDashboardSwitched({
          from: DashboardSwitchFrom.DRIVER_DASHBOARD_SIDEBAR,
          to: DashboardSwitchedTo.TEAMS,
        });
      }
    },
    liClass: "last-in-group tablet:[&_a]:min-h-[40px]",
  },
  {
    isGroupTitle: true,
    title: "Help",
  },
  {
    isExternalLink: true,
    label: "Blog",
    url: "https://www.mileiq.com/blog",
    icon: "book-open",
    onClick: () =>
      trackBlogSelected({
        src: BlogSource.HELP,
      }),
  },
  {
    isExternalLink: true,
    label: "Help",
    getUrl: (userData) => getZendeskJwtAuthUrl(userData?.zendeskJWT),
    icon: "comment-message",
    onClick: () => trackHelpSelected(),
  },
];

function getItems({ userData, user, team, flags, hasSharedReports }) {
  const isTeamAdmin = team?.isAdmin(user?.email);
  const isTeamAdminOnly = user.isAdmin && !user.isDriver;

  return driverDashboardNav
    .filter((item) => {
      const { id, hide } = item;
      if (id === "sharedReports") {
        return (
          flags?.miqDashboardContactsLightweightDashWeb && hasSharedReports
        );
      }
      if (id === "userSubscription" && userData.subscriptionData) {
        const { status } = userData.subscriptionData;

        if (userData.isTeamsUser) {
          if (status === SUBSCRIPTION_STATUS.ACTIVE) {
            item.notifications = 1;
          } else if (!isTeamAdminOnly) {
            // don't show Subscription menu page to teams users without active personal sub
            return false;
          }
        } else if (
          status === SUBSCRIPTION_STATUS.CANCELLED ||
          status === SUBSCRIPTION_STATUS.EXPIRED
        ) {
          item.notifications = 1;
        }
      }
      return !(typeof hide === "function" ? hide(userData) : hide);
    })
    .map((item) => {
      const { label, isExternalLink, rel, target, icon, path } = item || {};

      return {
        ...item,
        path: evaluate(path, isTeamAdmin),
        label: evaluate(label, isTeamAdmin),
        isExternalLink: evaluate(isExternalLink, isTeamAdmin),
        rel: evaluate(rel, isTeamAdmin),
        target: evaluate(target, isTeamAdmin),
        icon: evaluate(icon, isTeamAdmin),
      };
    });
}

export function DriverDashboardSidenav() {
  const { userData } = useUserData();
  const { user, hasSharedReports } = useUser();
  const { team } = useContext(TeamContext);
  const { miqDashboardContactsLightweightDashWeb } = useFlags();

  const [items, setItems] = useState([]);

  useEffect(() => {
    setItems(
      getItems({
        userData,
        user,
        team,
        flags: { miqDashboardContactsLightweightDashWeb },
        hasSharedReports,
      })
    );
  }, [userData, team, user]);
  return <Sidenav items={items} renderMenu={() => <DriverDashboardMenu />} />;
}

const TEAMS_NAV_LABELS = {
  OVERVIEW: "Overview",
  USERS: "Users",
  DRIVES: "Drives",
  DRIVES_TO_APPROVE: "Drives to approve",
  DRIVES_AND_REPORTS: "Drives and reports",
  REPORTS: "Reports",
  CUSTOM_RATES: "Custom Rates",
  TEAM_LOCATIONS: "Team Locations",
  SUBSCRIPTION: "Subscription",
  SETTINGS: "Settings",
  MILEIQ_FOR_DRIVERS: "MileIQ for Drivers",
  DRIVER_DASHBOARD: "Driver Dashboard",
  BLOG: "Blog",
  SUPPORT: "Support",
};

export function TeamsDashboardSidenav() {
  const flags = useFlags();
  const {
    miqDashboardTeamsOverviewPageWeb,
    miqDashTeamLocationsWeb: isTeamLocationsPageEnabled,
  } = flags || {};
  const { hasSharedReports } = useUser();
  const { team } = useContext(TeamContext);
  const queryParams = useQueryParams();
  const rangeDates = queryParams.get("rangeDates");
  const rangeDatesSuffix = rangeDates ? `?rangeDates=${rangeDates}` : "";

  const teamsDashboardNav = [
    {
      label: TEAMS_NAV_LABELS.OVERVIEW,
      path: "/teams/overview",
      icon: "apps",
      hide: !miqDashboardTeamsOverviewPageWeb?.active,
    },
    {
      label: TEAMS_NAV_LABELS.USERS,
      path: "/teams/users",
      icon: "people",
    },
    {
      label: TEAMS_NAV_LABELS.DRIVES,
      path: "/teams/drives",
      icon: "car-side",
      isBeta: true,
      dropdownItems: [
        {
          label: TEAMS_NAV_LABELS.DRIVES_TO_APPROVE,
          path: `/teams/drives-to-approve${rangeDatesSuffix}`,
        },
        {
          label: TEAMS_NAV_LABELS.DRIVES_AND_REPORTS,
          path: `/teams/drives${rangeDatesSuffix}`,
          chameleonTag: "menu-teams-drives-and-reports",
        },
      ],
      hide: (flags) =>
        flags?.miqTeamsDrivesAllPlatforms === false ||
        [
          TEAMS_SUBSCRIPTION_PLANS.TEAMS,
          TEAMS_SUBSCRIPTION_PLANS.TEAMS_PRO,
        ].includes(team?.subscription?.plan) === false,
    },
    {
      label: TEAMS_NAV_LABELS.REPORTS,
      path: "/teams/reports",
      icon: "reports",
      chameleonTag: "menu-teams-reports",
    },
    {
      label: TEAMS_NAV_LABELS.CUSTOM_RATES,
      path: "/teams/custom-rates",
      icon: "dollar",
    },
    {
      label: TEAMS_NAV_LABELS.TEAM_LOCATIONS,
      path: "/teams/locations",
      icon: "name-location-pin",
      hide:
        typeof isTeamLocationsPageEnabled === "boolean" &&
        !isTeamLocationsPageEnabled,
    },
    {
      label: TEAMS_NAV_LABELS.SUBSCRIPTION,
      path: "/teams/subscription",
      icon: "card",
      notifications: team?.isExpired || team?.isInGracePeriod ? 1 : 0,
    },
    {
      label: TEAMS_NAV_LABELS.SETTINGS,
      path: "/teams/settings",
      icon: "gear",
      liClass: "last-in-group",
    },
    {
      id: "sharedReports",
      label: "Shared with me",
      path: "/teams/shared-reports",
      icon: "shared-reports",
      liClass: "last-in-group",
      hide: (flags) =>
        !flags.miqDashboardContactsLightweightDashWeb || !hasSharedReports,
    },
    {
      isGroupTitle: true,
      title: TEAMS_NAV_LABELS.MILEIQ_FOR_DRIVERS,
    },
    {
      isExternalLink: true,
      label: TEAMS_NAV_LABELS.DRIVER_DASHBOARD,
      path: "/user",
      target: "mileiq.dd",
      rel: "opener",
      icon: "car-side",
      liClass: "last-in-group tablet:[&_a]:min-h-[40px]",
      onClick: () =>
        trackDashboardSwitched({
          from: DashboardSwitchFrom.TEAMS_DASHBOARD_SIDEBAR,
          to: DashboardSwitchedTo.DRIVER,
        }),
    },
    {
      isExternalLink: true,
      label: TEAMS_NAV_LABELS.BLOG,
      url: "https://www.mileiq.com/blog",
      icon: "book-open",
      onClick: () =>
        trackBlogSelected({
          src: BlogSource.HELP,
        }),
    },
    {
      isExternalLink: true,
      label: TEAMS_NAV_LABELS.SUPPORT,
      getUrl: (userData) => getZendeskJwtAuthUrl(userData?.zendeskJWT),
      icon: "comment-message",
      onClick: () => trackHelpSelected(),
    },
  ];

  return (
    <Sidenav
      items={teamsDashboardNav.filter(({ hide }) => {
        return !(typeof hide === "function" ? hide(flags) : hide);
      })}
      renderMenu={() => <TeamsDashboardMenu />}
    />
  );
}

function Sidenav({ items, renderMenu }) {
  const { userData } = useUserData();
  const { user } = useUser();
  const [navItems, setNavItems] = useState(items);
  const { notifications } = useNotifications();
  const { team } = useContext(TeamContext);
  const [isDropdownOpen, setDropdownOpen] = useState({
    [TEAMS_NAV_LABELS.DRIVES]: true,
  });

  const isTeamAdmin = team?.isAdmin(user?.email);

  useEffect(() => {
    setNavItems(
      [...items].map(({ ...item }) => {
        item.notifications =
          typeof item.notifications === "function"
            ? item.notifications(notifications)
            : item.notifications;
        return item;
      })
    );
  }, [notifications, items]);

  const renderItems = (items) => {
    return items.map((item) => {
      const itemRef = createRef();
      const hasNotifications = item.notifications > 0;
      const { isExternalLink } = item;

      const isOpen = Boolean(isDropdownOpen[item.label]) === true;
      const isClosed = isOpen === false;

      const linkContent = (
        <div className="w-full flex items-center justify-between">
          <div className="flex justify-between items-center w-full">
            <div className="flex items-center">
              <span className="menu-icon" ref={itemRef}>
                {item.icon ? (
                  <Icon
                    name={item.icon}
                    color={item.highlighted ? "blue" : "black"}
                  />
                ) : (
                  <div className="w-6 h-6" />
                )}
              </span>
              <Tooltip
                triggerRef={itemRef}
                alignX="left"
                offset={{ x: 40 }}
                className="hidden tablet:block"
              >
                <Text nowrap>{item.label}</Text>
              </Tooltip>
              <span
                className={`menu-item-label ${
                  item.highlighted ? "text-blue font-semibold" : ""
                }`}
                data-testid="sidenav-link"
              >
                {item.label}
              </span>
              {item.isBeta && (
                <Text className="miq-badge bg-skyblue text-blue font-semibold text-sm ml-1">
                  Beta
                </Text>
              )}
            </div>
            {item.dropdownItems && (
              <Icon
                name={isOpen ? "chevron-up-small" : "chevron-down-small"}
                className="pl-3"
              />
            )}
          </div>
          {(hasNotifications || isExternalLink) && (
            <div className="flex items-center gap-2">
              {isExternalLink && (
                <div className="hidden tablet:group-hover/link:hidden group-hover/link:block mobile:group-hover/link:block">
                  <Icon name="external-link" color="gray" />
                </div>
              )}
              {hasNotifications && (
                <span
                  className={`badge bg-${item.notificationsColor || "red"} `}
                >
                  <Text md color="white">
                    {item.notifications}
                  </Text>
                </span>
              )}
            </div>
          )}
        </div>
      );

      if (item.isGroupTitle) {
        return (
          <li key={`group-${item.title}`} className="tablet:hidden mb-3 px-3">
            <Text md semibold color="black/50">
              {item.title}
            </Text>
          </li>
        );
      }

      if (item.dropdownItems) {
        return (
          <li
            key={item.label}
            className={`${item.liClass || ""} relative`}
            data-chmln={item.chameleonTag}
          >
            <NavLink
              isActive={(_, location) => {
                return (
                  isClosed &&
                  item.dropdownItems.some((dropdownItem) =>
                    location.pathname.includes(dropdownItem.path?.split("?")[0])
                  )
                );
              }}
              target={item.target}
              rel={item.rel}
              className={`group/link ${isOpen ? "hover:bg-transparent" : ""}`}
              activeClassName="active"
              to={item.path}
              onClick={(e) => {
                e.preventDefault();

                if (typeof item.onClick === "function")
                  item.onClick(isTeamAdmin);

                setDropdownOpen((prev) => ({
                  ...prev,
                  [item.label]: !prev[item.label],
                }));
              }}
            >
              {linkContent}
            </NavLink>
            <ul
              data-testid={`${item.label?.toLowerCase()}-dropdown-items`}
              className={`transition-opacity duration-500 ease-out 
              ${isOpen ? "opacity-100 visible" : "opacity-0 invisible h-0"}`}
            >
              {renderItems(item.dropdownItems)}
            </ul>
          </li>
        );
      }

      const url = item.getUrl ? item.getUrl(userData) : item.url;

      return (
        <li
          className={`${item.liClass || ""} relative`}
          key={item.label}
          data-chmln={item.chameleonTag}
        >
          {url ? (
            <a
              className="group/link"
              href={url}
              target="_blank"
              rel="noreferrer noopener"
              onClick={() => {
                if (typeof item.onClick === "function")
                  item.onClick(isTeamAdmin);
              }}
            >
              {linkContent}
            </a>
          ) : (
            <NavLink
              target={item.target}
              rel={item.rel}
              className="group/link"
              activeClassName="active"
              to={item.path}
              onClick={() => {
                if (typeof item.onClick === "function")
                  item.onClick(isTeamAdmin);
              }}
            >
              {linkContent}
            </NavLink>
          )}
        </li>
      );
    });
  };

  return (
    <div className="z-10 w-full tablet:w-0">
      <div className="sidenav">
        <div className="tablet:p-0 sidenav-menu mb-[30px] laptop:mb-[20px] pl-2 mobile:pl-2 w-full relative">
          {renderMenu()}
        </div>
        <nav className="flex-grow self-stretch">
          <ul>{renderItems(navItems)}</ul>
        </nav>
        <NavLogo />
      </div>
    </div>
  );
}

function DriverDashboardMenu() {
  const { user } = useUser();
  const [isOpen, setIsOpen] = useState(false);
  const { miqDashboardTeamsProfileNames: isProfileNamesEnabled } = useFlags();
  const nameExists = user.firstName && user.lastName;

  const menuItems = [
    {
      key: "logout",
      render: () => (
        <button onClick={logout} className="flex items-center w-full">
          <Icon name="signout" color="black" className="mr-3" />
          <Text semibold>Sign out</Text>
        </button>
      ),
    },
  ];

  return (
    <Menu
      WordMark={IndividualWordMark}
      itemsHeader={() =>
        isProfileNamesEnabled && nameExists ? (
          <div>
            <Text
              paragraph
              custom
              className="leading-[140%] text-15 font-semibold truncate select-text"
              data-testid="sidenav-user-name"
              title={`${user.firstName} ${user.lastName}`}
            >
              {`${user.firstName} ${user.lastName}`}
            </Text>
            <Text
              paragraph
              sm
              className="leading-[140%] text-15 truncate select-text"
              data-testid="sidenav-user-email"
              title={user.email}
            >
              {user.email}
            </Text>
          </div>
        ) : (
          <Text
            paragraph
            custom
            className="leading-[140%] text-15 font-semibold truncate select-text"
            data-testid="sidenav-user-email"
            title={user.email}
          >
            {user.email}
          </Text>
        )
      }
      items={menuItems}
      isOpen={isOpen}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
    />
  );
}

function TeamsDashboardMenu() {
  const [isOpen, setIsOpen] = useState(false);
  const { team } = useContext(TeamContext);
  const { user } = useUser();

  return (
    <Menu
      WordMark={TeamsWordMark}
      Logo={TeamsLogo}
      itemsHeader={() => (
        <div className="flex flex-col gap-0.5">
          <Text
            paragraph
            custom
            className="leading-[140%] text-15 font-semibold truncate select-text"
            data-testid="sidenav-team-name"
            title={team.name}
          >
            {team.name}
          </Text>
          <Text
            paragraph
            custom
            className="leading-[140%] text-11 truncate select-text"
            data-testid="sidenav-user-email"
            title={user.email}
          >
            {user.email}
          </Text>
        </div>
      )}
      items={[
        {
          key: "logout",
          render: () => (
            <button onClick={logout} className="flex items-center w-full">
              <Icon name="signout" color="black" className="mr-3" />
              <Text semibold>Sign out</Text>
            </button>
          ),
        },
      ]}
      isOpen={isOpen}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
    />
  );
}

function Menu({ WordMark, isOpen, onOpen, onClose, items, itemsHeader }) {
  return (
    <Dropdown
      open={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      triggerClassName="p-0 bg-transparent shadow-none tablet:h-[25px]"
      contentClassName="top-[52px] mobile:-left-3 -left-3 tablet:left-0 tablet:top-[38px] p-0 border border-border-1"
      minContentWidth={210}
      maxContentWidth={300}
      renderTrigger={() => (
        <div
          className="group/menu flex items-center justify-between w-full"
          data-testid="sidenav-top-menu-label"
        >
          <div>
            <WordMark />
          </div>
          <div
            className={`flex items-center mobile:h-[40px] tablet:h-auto h-[40px] mobile:p-[10px] p-[10px] tablet:p-0 mobile:ml-0 tablet:ml-[-3px] ml-0 rounded-[14px] mobile:group-hover/menu:bg-beige-medium tablet:group-hover/menu:bg-transparent group-hover/menu:bg-beige-medium  ${
              isOpen
                ? "mobile:bg-beige-medium tablet:bg-transparent bg-beige-medium"
                : ""
            }`}
          >
            <Icon name="chevron-down" className="tablet:scale-75" />
          </div>
        </div>
      )}
    >
      <div className="p-[7px]">
        {itemsHeader && (
          <>
            <div className="p-2.5">{itemsHeader()}</div>
            <div className="h-[1px] bg-border-1 m-[7px]" />
          </>
        )}
        <ul className="m-0">
          {items.map((item, i) => {
            let classes =
              "cursor-pointer m-0 px-2.5 py-2.5 rounded-10 hover:bg-beige active:bg-beige-medium";
            if (i > 0) classes += " mt-1";

            return (
              <li key={item.key} className={classes}>
                {item.render()}
              </li>
            );
          })}
        </ul>
      </div>
    </Dropdown>
  );
}

function NavLogo() {
  return (
    <div className="flex items-center">
      <p className="ml-3 mt-3 text-12 text-black/50 menu-item-label">
        v{version}
      </p>
    </div>
  );
}
