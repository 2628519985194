import { useLazyQuery, useMutation } from "@apollo/client";
import React, { useContext, useEffect } from "react";

import { useFlags } from "src/hooks/useFlags";

import { ADD_CONTACT, REMOVE_CONTACT } from "src/graphql/mutations";
import { GET_CONTACTS, GET_CONTACT_TYPES } from "src/graphql/queries";

import { useUserData } from "./UserContext";

const ContactsContext = React.createContext({});

export function useContacts() {
  return useContext(ContactsContext);
}

export const ContactsProvider = ({ children }) => {
  const { miqDashboardReportsContactsWeb } = useFlags();
  const { userData } = useUserData();

  const [
    fetchContactTypes,
    { data: { getContactTypes: contactTypes } = { getContacts: [] } },
  ] = useLazyQuery(GET_CONTACT_TYPES, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  const [
    fetchContacts,
    { data: { getContacts: contacts } = { getContacts: [] }, error, loading },
  ] = useLazyQuery(GET_CONTACTS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  const [addContactMut, addContactState] = useMutation(ADD_CONTACT, {
    notifyOnNetworkStatusChange: true,
    refetchQueries: ["getContacts"],
    onQueryUpdated: (q) => q.refetch(),
  });

  const [removeContactMut, removeContactState] = useMutation(REMOVE_CONTACT, {
    notifyOnNetworkStatusChange: true,
    refetchQueries: ["getContacts"],
    onQueryUpdated: (q) => q.refetch(),
  });

  useEffect(() => {
    const enabled =
      miqDashboardReportsContactsWeb === true && !userData?.isTeamsUser;

    if (enabled) {
      fetchContactTypes();
      fetchContacts();
    }
  }, [miqDashboardReportsContactsWeb, userData?.isTeamsUser]);

  async function addContact({ firstName, lastName, email, relationship }) {
    return await addContactMut({
      variables: {
        data: {
          firstName,
          lastName,
          email,
          relationship,
        },
      },
    }).then((res) => res?.data?.addContact);
  }

  async function removeContact(id) {
    return await removeContactMut({
      variables: {
        data: {
          id,
        },
      },
    });
  }

  return (
    <ContactsContext.Provider
      value={{
        contactTypes,
        contacts,
        loading,
        error,
        refetch: fetchContacts,
        addContact,
        addContactState,
        removeContact,
        removeContactState,
      }}
    >
      {children}
    </ContactsContext.Provider>
  );
};
