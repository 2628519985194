import * as braze from "@braze/web-sdk";
import React, { useContext, useEffect } from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

import useElement from "src/lib/layers/useElement";

import { CustomRatesProvider } from "src/components/context/CustomRatesContext";
import { useAppFlash } from "src/components/context/Flash";
import TeamContext from "src/components/context/TeamContext";
import { useTeamDunning } from "src/components/context/TeamDunningContext";
import {
  UserDataContext,
  useUser,
  useUserData,
} from "src/components/context/UserContext";

import { FlashTypes } from "src/components/elements/Flash";
import Icon from "src/components/elements/Icon";
import Page from "src/components/elements/Page";
import Text from "src/components/elements/Text";
import { BannerStrip, TopBanner } from "src/components/elements/TopBanner";

import { DUNNING_DRIVERS_WARNING_MODAL_ID } from "src/components/modals/DunningDriversWarning";
import { ELEMENT_ID as WELCOME_TO_NEW_DASHBOARD_MODAL_ID } from "src/components/modals/WelcomeToNewDashboard";

import DrivesPage from "src/components/pages/DriverDashboard/Drives";
import ReportsPage from "src/components/pages/DriverDashboard/Reports";
import SettingsPage from "src/components/pages/DriverDashboard/Settings";
import SubscriptionPage from "src/components/pages/DriverDashboard/Subscription";

import BestExperienceNotification from "src/components/BestExperienceNotification";
import { DriverDashboardSidenav } from "src/components/Sidenav";

import { useFlags } from "src/hooks/useFlags";

import { SUBSCRIPTION_STATUS } from "src/services/billing";
import {
  getHasSeenFeedbackSurveyFlash,
  getHasSeenWelcomeToNewDashboardModal,
  setDriverDunningExpiredSubscriptionModalLastSeen,
  setDriverDunningPaymentFailedModalLastSeen,
  setHasSeenFeedbackSurveyFlash,
  setHasSeenWelcomeToNewDashboardModal,
} from "src/services/storage";

import ZendeskWidget from "src/vendors/zendesk";

import { GetStartedPage } from "./GetStarted";
import SharedReportPage from "./SharedReports";
import { TeamsPage } from "./Teams";

export default DriverDashboardPages;

function DriverDashboardPages() {
  const { flash } = useAppFlash();
  const {
    miqDashWelcomeModalWeb: shouldShowWelcomeModalFlag,
    miqDashFlashFeedbackSurveyWeb: shouldShowFeedbackSurveyFlashFlag,
  } = useFlags();
  const hasSeenWelcomeToNewDashboardModal =
    getHasSeenWelcomeToNewDashboardModal();
  const hasSeenFeedbackSurveyFlash = getHasSeenFeedbackSurveyFlash();

  const welcomeToNewDashboard = useElement(WELCOME_TO_NEW_DASHBOARD_MODAL_ID, {
    props: {
      onClose: () => {
        setHasSeenWelcomeToNewDashboardModal();
        welcomeToNewDashboard.deactivate();
      },
    },
  });
  const { daysUntilGracePeriodEnds, dayLabel } = useTeamDunning();
  const { team } = useContext(TeamContext);
  const {
    userData: { isPremium, subscriptionData },
  } = useUserData();

  const isFree = !isPremium || !subscriptionData.status;

  useEffect(() => {
    if (shouldShowWelcomeModalFlag && !hasSeenWelcomeToNewDashboardModal) {
      welcomeToNewDashboard.activate();
    }

    if (shouldShowFeedbackSurveyFlashFlag && !hasSeenFeedbackSurveyFlash) {
      setTimeout(() => {
        flash(
          (hideFlash) => {
            const onClose = () => {
              setHasSeenFeedbackSurveyFlash();
              hideFlash();
            };

            return (
              <div className="flex gap-2">
                <Text color="black">
                  👋 We'd love your feedback!{" "}
                  <a
                    onClick={onClose}
                    target="_blank"
                    className="underline"
                    href=" https://mile-iq.typeform.com/to/oWZdJQG8"
                  >
                    Tell us what you think of the new dashboard
                  </a>
                </Text>
                <Icon
                  className="cursor-pointer"
                  name="close"
                  onClick={onClose}
                />
              </div>
            );
          },
          { type: FlashTypes.SPECIAL, ignoreTimeout: true }
        );
      }, 60000);
    }
  }, [
    shouldShowWelcomeModalFlag,
    shouldShowFeedbackSurveyFlashFlag,
    hasSeenWelcomeToNewDashboardModal,
    hasSeenFeedbackSurveyFlash,
  ]);

  window.name = "mileiq.dd";
  return (
    <div className="w-full h-full flex flex-col">
      <TopBanner>
        {team?.isInGracePeriod && (
          <BannerStrip backgroundVariant="warning">
            <Icon name="warning" />
            <p>
              Your team's subscription is ending in {daysUntilGracePeriodEnds}{" "}
              {dayLabel}.
            </p>
          </BannerStrip>
        )}
        {team?.isExpired && (
          <BannerStrip backgroundVariant="danger">
            <Icon name="error" />

            <p>
              Your team's subscription has expired.{" "}
              {isFree && "Miss unlimited drives?"}{" "}
              <a
                className="text-blue font-medium"
                href={`mailto:${team?.owner?.email || ""}`}
              >
                Contact your admin
              </a>{" "}
              to ask about resubscribing.
            </p>
          </BannerStrip>
        )}
      </TopBanner>
      <div className="w-screen max-w-full min-h-screen flex bg-white">
        <DriverRoutes />
      </div>
    </div>
  );
}

function DriverRoutes() {
  const { path } = useRouteMatch();
  const { userData } = useContext(UserDataContext);
  const { team } = useContext(TeamContext);
  const {
    shouldShowDriversExpiredSubscriptionModal,
    shouldShowDriversPaymentFailedModal,
  } = useTeamDunning();

  const { miqDashboardContactsLightweightDashWeb } = useFlags();

  const dunningWarningModal = useElement(DUNNING_DRIVERS_WARNING_MODAL_ID, {
    props: {
      onClose: () => {
        dunningWarningModal.deactivate();
        const setModalLastSeen = team?.isExpired
          ? setDriverDunningExpiredSubscriptionModalLastSeen
          : setDriverDunningPaymentFailedModalLastSeen;

        setModalLastSeen(team.id, userData.id);
      },
    },
  });

  useEffect(() => {
    braze.logCustomEvent("driver_dash_view");

    if (
      shouldShowDriversPaymentFailedModal() ||
      shouldShowDriversExpiredSubscriptionModal()
    ) {
      dunningWarningModal.activate();
    }
  }, []);

  return (
    <CustomRatesProvider>
      <DriverDashboardSidenav />
      <ZendeskWidget />
      <BestExperienceNotification />
      <div className="page-content relative">
        <Switch>
          {userData.showGetStartedPage ? (
            <Route path={`${path}/get-started`} exact>
              <Page title="Get started">
                <GetStartedPage />
              </Page>
            </Route>
          ) : null}
          <Route path={`${path}/drives`} exact>
            <Page title="Drives">
              <DrivesPage />
            </Page>
          </Route>
          <Route path={`${path}/reports`} exact>
            <Page title="Reports">
              <ReportsPage />
            </Page>
          </Route>
          {miqDashboardContactsLightweightDashWeb && (
            <Route path={`${path}/shared-reports`} exact>
              <Page title="Shared Reports">
                <SharedReportPage />
              </Page>
            </Route>
          )}
          <Route path={`${path}/subscription`}>
            <Page title="Subscription Plan">
              <SubscriptionPageGuard />
            </Page>
          </Route>
          <Route path={`${path}/settings`}>
            <SettingsPage />
          </Route>
          <Route path={`${path}/teams`} exact>
            <Page title="Teams">
              <TeamsPage />
            </Page>
          </Route>
          <Redirect
            to={
              userData.showGetStartedPage
                ? `${path}/get-started`
                : `${path}/drives`
            }
          />
        </Switch>
      </div>
    </CustomRatesProvider>
  );
}

function SubscriptionPageGuard(props) {
  const { userData } = useContext(UserDataContext);
  const { user } = useUser();

  const isTeamAdminOnly = user.isAdmin && !user.isDriver;
  // teams users without active unlim sub should not see subsctiption page
  if (
    !isTeamAdminOnly &&
    userData.isTeamsUser &&
    userData.subscriptionData?.status !== SUBSCRIPTION_STATUS.ACTIVE
  ) {
    return <Redirect to={`/users/teams`} />;
  }

  return <SubscriptionPage {...props} />;
}
