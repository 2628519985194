import { useContext } from "react";

import {
  CANCELLATION_STEPS,
  CANCEL_REASON_KEYS,
  CANCEL_REASON_OPTIONS,
  useTeamCancellation,
} from "src/components/context/TeamCancellationContext";
import TeamContext from "src/components/context/TeamContext";

import Button from "src/components/elements/Button";
import Icon from "src/components/elements/Icon";
import Input from "src/components/elements/Input";
import Loader from "src/components/elements/Loader";
import Select from "src/components/elements/Select";
import Text from "src/components/elements/Text";

import { useFlags } from "src/hooks/useFlags";

import { NETWORK_STATES } from "src/services/http";
import {
  CancellationAbandonedMethods,
  trackTeamsSubscriptionCancellationAbandoned,
} from "src/services/tracking";

export function CancellationReason({ modalRef, closeModal }) {
  const {
    selectedReason,
    setSelectedReason,
    customReason,
    setCustomReason,
    goToNextStep,
    cancelSubscription,
    shouldSkipTeamStatsStep,
    shouldSkipDowngradeStep,
    shouldSkipClaimDiscountStep,
    cancelSubscriptionNetworkState,
    checkDiscountEligibilityNetworkState,
    teamAllTimeStatsNetworkState,
    isEligibleToClaimDiscount,
  } = useTeamCancellation();
  const {
    miqDashboardTeamsSubscriptionDiscountsOnCancellationWeb:
      isDiscountsOnCancellationActive,
  } = useFlags();
  const { team } = useContext(TeamContext);

  const isCustomReason = selectedReason === CANCEL_REASON_KEYS.OTHER;
  const isValidReason = isCustomReason ? !!customReason : !!selectedReason;

  const isCheckDiscountEligibilityLoading = [
    NETWORK_STATES.IDLE,
    NETWORK_STATES.LOADING,
  ].includes(checkDiscountEligibilityNetworkState);

  const isTeamAllTimeStatsLoading = [
    NETWORK_STATES.IDLE,
    NETWORK_STATES.LOADING,
  ].includes(teamAllTimeStatsNetworkState);

  const isCancelSubscriptionLoading =
    cancelSubscriptionNetworkState === NETWORK_STATES.LOADING;

  const shouldSkipAllNextSteps =
    shouldSkipTeamStatsStep &&
    shouldSkipDowngradeStep &&
    shouldSkipClaimDiscountStep;

  const shouldOfferCancellationStraightAway =
    !isDiscountsOnCancellationActive || shouldSkipAllNextSteps;

  const handleDropdownOpen = () => {
    if (modalRef?.current) {
      const scrollHeight = modalRef.current.scrollHeight;
      const modalHeight = modalRef.current.offsetHeight;
      modalRef.current.scrollTop = scrollHeight - modalHeight - 10;
    }
  };

  const handleDropdownClose = () => {
    if (modalRef?.current) {
      modalRef.current.scrollTop = 0;
    }
  };

  const handleCancelPlan = async (e) => {
    e.preventDefault();

    const successfullyCanceledSubscription = await cancelSubscription();

    if (successfullyCanceledSubscription) closeModal();
  };

  if (isCheckDiscountEligibilityLoading || isTeamAllTimeStatsLoading) {
    return (
      <div className="flex items-center justify-center h-[300px]">
        <Loader sm />
      </div>
    );
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    trackTeamsSubscriptionCancellationAbandoned({
      orgId: team.orgId,
      orgGroupId: team.orgGroupId,
      teamsPlanId: team.subscription?.plan,
      subType: team.subscription?.type,
      teamId: team.id,
      freeTrial: team.subscription?.isFreeTrialActive,
      teamsDriverCount: team.subscription?.numberOfSeats || 0,
      eligibleToDiscounts: isEligibleToClaimDiscount,
      cancellationStep: CANCELLATION_STEPS.CANCELLATION_REASON,
      method: CancellationAbandonedMethods.KEEP_MY_PLAN_CTA,
    });

    closeModal();
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col gap-[30px] mt-5">
      <div>
        <h5>Why do you want to cancel?</h5>
        <Text paragraph className="mt-2.5">
          Please let us know why you're canceling your subscription.
        </Text>
      </div>
      <div className="flex items-center gap-2.5">
        <Select
          options={CANCEL_REASON_OPTIONS}
          placeholder="Select reason"
          selected={selectedReason}
          onOpen={handleDropdownOpen}
          onClose={handleDropdownClose}
          onSelect={(reason) => {
            setSelectedReason(reason);
            setCustomReason("");
          }}
          dropdownOptions={{
            className: `miq-select ${isCustomReason ? "w-[120px]" : "w-full"}`,
            contentClassName: "min-w-max w-full",
            processElements: null,
          }}
          listClassName="max-h-[250px] overflow-y-auto"
        />
        {isCustomReason && (
          <Input
            value={customReason}
            onChange={(e) => setCustomReason(e.target.value)}
            placeholder="Please specify"
            className="flex-grow"
          />
        )}
      </div>
      <div className="flex items-center gap-2.5 justify-end">
        <Button
          className="font-medium min-w-[163.55px]"
          secondary
          onClick={
            shouldOfferCancellationStraightAway
              ? handleCancelPlan
              : goToNextStep
          }
          type="button"
          disabled={!isValidReason}
        >
          {isCancelSubscriptionLoading ? (
            <Icon name="spinner" />
          ) : (
            <span>
              {shouldOfferCancellationStraightAway
                ? "Cancel subscription"
                : "Continue to cancel"}
            </span>
          )}
        </Button>
        <Button
          disabled={isCancelSubscriptionLoading}
          className="w-[120px] font-medium"
          type="submit"
        >
          Keep my plan
        </Button>
      </div>
    </form>
  );
}
