import { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { useNotifications } from "src/components/context/NotificationsContext";
import TeamContext from "src/components/context/TeamContext";
import { useUser } from "src/components/context/UserContext";

import PageLayout from "src/components/PageLayout";

import { useFlags } from "src/hooks/useFlags";

import { CreateTeamPage } from "./CreateTeam";
import { TeamInvitedPage } from "./TeamInvited";

export function TeamsPage() {
  const history = useHistory();
  const { team } = useContext(TeamContext);
  const { user } = useUser();
  const { notifications, loading } = useNotifications();
  const {
    miqDashboardTeamsOverviewPageWeb: { active: isTeamsOverviewActive },
  } = useFlags();

  const teamsHomePage = isTeamsOverviewActive
    ? "/teams/overview"
    : "/teams/users";

  useEffect(() => {
    const isTeamAdmin = team?.isAdmin(user?.email);
    if (isTeamAdmin) {
      if (window.parent?.name === "mileiq.teams") {
        window.parent.focus();
      } else {
        window.open(teamsHomePage, "mileiq.teams")?.focus();
        if (history.length > 2) {
          history.goBack();
        } else {
          window.location.href = "/user";
        }
      }
    }
  }, []);

  return (
    <PageLayout className="page-create-team bg-beige overflow-x-hidden">
      <PageLayout.Main>
        {team || loading || (notifications && notifications.code !== 404) ? (
          <TeamInvitedPage />
        ) : (
          <CreateTeamPage />
        )}
      </PageLayout.Main>
    </PageLayout>
  );
}
