import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import TeamContext from "src/components/context/TeamContext";
import { useTeamOverview } from "src/components/context/TeamOverviewContext";
import { useUser } from "src/components/context/UserContext";

import Button from "src/components/elements/Button";
import Loader from "src/components/elements/Loader";
import MobMenu from "src/components/elements/MobMenu";

import CustomActiveDot from "src/components/blocks/teams-overview/CustomActiveDot";
import CustomYAxisTick from "src/components/blocks/teams-overview/CustomYAxisTick";
import TeamStat from "src/components/blocks/teams-overview/TeamStat";

import PageLayout from "src/components/PageLayout";

import { useFlags } from "src/hooks/useFlags";
import { DUNNING_STATUS, useTeamsCTA } from "src/hooks/useTeamCTA";
import {
  usePickFirstPlanFlow,
  useUpgradePlanFlow,
} from "src/hooks/useUpgradePlan";

import { MEMBER_STATUS } from "src/models/team-member";
import {
  TEAMS_SUBSCRIPTION_PLANS,
  isTeamsLiteSubscription,
  isTeamsProSubscription,
  isTeamsSubscription,
} from "src/models/team-subscription";

import { NETWORK_STATES } from "src/services/http";
import { getTeamMembers } from "src/services/teams";
import {
  teamsInviteSources,
  teamsUpgradeSources,
  trackExperimentViewed,
  trackTeamsUpgradeStarted,
} from "src/services/tracking";
import {
  formatNumberWithSuffix,
  generateChartRoundedTicks,
  getLastMonths,
} from "src/services/utils";

import AttentionCar from "public/assets/img/illustrations/attention-car.png";

export default TeamsOverview;

const LINE_CHART_NUMBER_OF_MONTHS = 6;

const AXIS_STYLE = {
  stroke: "transparent",
};

function calculateYAxisDomain(hasValues, highestValue) {
  const minValue = 0;
  const upperLimit = 8000;

  if (!hasValues || highestValue === 0) {
    return [minValue, upperLimit];
  }

  return [0, highestValue];
}

function populateMissingMonths(data) {
  const lastMonths = getLastMonths({
    amount: LINE_CHART_NUMBER_OF_MONTHS,
    formatTemplate: "MMM yyyy",
  });

  return lastMonths
    .map(
      (month) =>
        data.find(({ label }) => label === month) || {
          label: month,
          value: 0,
        }
    )
    .slice(-LINE_CHART_NUMBER_OF_MONTHS);
}

function getProPlanFlowOpts(team) {
  return {
    forceShowPickPlan: true,
    onBeforeStart: () => {
      trackTeamsUpgradeStarted({
        src: teamsUpgradeSources.TEAMS_OVERVIEW_PAGE,
        orgId: team.orgId,
        orgGroupId: team.orgGroupId,
      });
    },
    excludePlans: [TEAMS_SUBSCRIPTION_PLANS.TEAMS_LITE],
    currentSubPlan: team.subscription?.plan,
  };
}

function TeamsOverview() {
  const [activeChartDot, setActiveChartDot] = useState(null);
  const { team } = useContext(TeamContext);
  const { miqDashboardTeamsOverviewPageWeb } = useFlags();
  const tooltipRef = useRef();
  const { teamStats, teamOverviewNetworkState, fetchTeamStats } =
    useTeamOverview();
  const { user } = useUser();
  const proPlanFlowOpts = getProPlanFlowOpts(team);
  const [startUpgradeFlow, renderUpgradeFlow] = team.subscription
    ? // eslint-disable-next-line
      useUpgradePlanFlow(proPlanFlowOpts) // preselect pro and confirm
    : // eslint-disable-next-line
      usePickFirstPlanFlow(proPlanFlowOpts); // preselect pro and invite first users
  const { checkAndHandleDunning } = useTeamsCTA();

  useEffect(() => {
    fetchTeamStats();
  }, []);

  const handleUpgrade = () => {
    const dunningStatus = checkAndHandleDunning();

    if (dunningStatus === DUNNING_STATUS.EXPIRED) return;

    startUpgradeFlow();
  };

  let {
    title,
    description,
    upsellCopy,
    news: {
      active: newsActive,
      title: newsTitle,
      description: newsDescription,
      items: newsItems,
    },
    metrics: {
      month: {
        active: monthActive,
        items: monthItems,
        title: monthTitle,
        description: monthDescription,
      },
      totalDistance: {
        active: totalDistanceActive,
        title: totalDistanceTitle,
        description: totalDistanceDescription,
      },
    },
  } = miqDashboardTeamsOverviewPageWeb || {
    title: "Welcome, {username}!",
    description:
      "This is where you can see your Team summary, stats, news at a glance.",
    upsellCopy:
      "As a Teams Lite admin, you do not get reports from your drivers and therefore you won't see the summary stats and graph below. To see stats, upgrade to Teams or Teams Pro.",
    news: {
      active: false,
      title: "What's new",
      description: "New features, announcements from MileIQ",
      items: [],
    },
    metrics: {
      month: {
        active: true,
        items: [
          "Team members submitted reports",
          "Manually-entered drives",
          "Total value of drives",
          "Total kilometres",
          "Total miles",
        ],
        title: "This month so far",
        description: "",
      },
      totalDistance: {
        active: true,
        title: "Total {distanceUnit} trend",
        description:
          "Total business {distanceUnit} driven by your team each month.",
      },
    },
  };

  const showUpsell = isTeamsLiteSubscription(team?.subscription?.plan);

  const isLoading = [NETWORK_STATES.LOADING, NETWORK_STATES.IDLE].includes(
    teamOverviewNetworkState
  );

  let lineChartData = teamStats?.total?.values || [];

  if (lineChartData.length < LINE_CHART_NUMBER_OF_MONTHS) {
    lineChartData = populateMissingMonths(lineChartData);
  }

  const isAnyMetricGroupActive = monthActive || totalDistanceActive;
  const lineChartDistanceUnit =
    teamStats?.total?.unit === "km" ? "kilometres" : "miles";
  const activeDotValue = activeChartDot?.value || 0;
  const tooltipValue = formatNumberWithSuffix({
    value: activeDotValue,
    fractionDigits: activeDotValue > 1e6 ? 2 : 1,
  });
  const highestValue = Math.max(...lineChartData.map(({ value }) => value));
  const hasValues = highestValue > 0;
  const ticks = hasValues
    ? generateChartRoundedTicks(5, highestValue)
    : [0, 2000, 4000, 6000, 8000];
  // filter by feature flag + ensure order from feature flag
  let monthStats = monthItems.reduce((acc, item) => {
    const monthStat = teamStats?.month.find(({ label }) => label === item);

    return monthStat ? [...acc, monthStat] : acc;
  }, []);

  // sanitize data for teams lite users
  if (showUpsell) {
    lineChartData = lineChartData.map((stat) => ({
      ...stat,
      value: 0,
    }));
    monthStats = monthStats.map((stat) => ({
      ...stat,
      value: 0,
    }));
  }

  totalDistanceTitle = totalDistanceTitle?.replace(
    "{distanceUnit}",
    lineChartDistanceUnit
  );
  totalDistanceDescription = totalDistanceDescription?.replace(
    "{distanceUnit}",
    lineChartDistanceUnit
  );
  title = user?.firstName
    ? title?.replace("{username}", user.firstName)
    : user?.email
    ? title?.replace("{username}", user.email)
    : "Welcome!";

  return (
    <PageLayout className="page-overview">
      <PageLayout.Main>
        {renderUpgradeFlow()}
        <div className="h-full flex flex-col">
          <div className="page-header">
            <div className="flex items-center">
              <MobMenu />
              <h3 className="break-all">{title}</h3>
            </div>
            <p className="mt-2">{description}</p>
          </div>
          {isLoading && (
            <div className="mt-20">
              <Loader
                className="p-8"
                message="Processing... This may take a few minutes depending on your team's stats."
                timeout={10000}
              />
            </div>
          )}

          {teamOverviewNetworkState === NETWORK_STATES.ERROR && (
            <div className="mt-20 md:h-full md:m-0 flex flex-col items-center justify-center gap-5">
              <img src={AttentionCar} alt="Attention Car" />
              <div className="text-center text-15 text-black/50">
                <p>Oops! Something went wrong.</p>
                <p>Please try again later.</p>
              </div>
            </div>
          )}

          {teamOverviewNetworkState === NETWORK_STATES.LOADED && (
            <div className="m-5">
              <div className="grid grid-flow-row lg:grid-cols-3 lg:grid-flow-col gap-5">
                <div className="flex flex-col lg:col-span-2 gap-5">
                  {showUpsell && (
                    <div className="flex gap-[8px] items-center justify-between p-[15px] bg-[#FFFAF0] rounded-[14px]">
                      <p className="text-15">{upsellCopy}</p>
                      <Button className="text-15" onClick={handleUpgrade}>
                        Upgrade
                      </Button>
                    </div>
                  )}
                  <BannerInviteDrivers />
                  {monthActive && (
                    <div className="p-5 rounded-[14px] border border-border-1">
                      <h5>{monthTitle}</h5>
                      {monthDescription && (
                        <p className="mt-[5px] text-black/70 text-15">
                          {monthDescription}
                        </p>
                      )}
                      <div className="mt-5 grid grid-cols-2 grid-rows-2 lg:grid-rows-none lg:grid-cols-4 justify-between items-center gap-5">
                        {monthStats.map((stat) => (
                          <TeamStat key={stat.label} {...stat} />
                        ))}
                      </div>
                    </div>
                  )}
                  {totalDistanceActive && (
                    <div className="p-5 rounded-[14px] border border-border-1">
                      <h5>{totalDistanceTitle}</h5>
                      {totalDistanceDescription && (
                        <p className="mt-[5px] text-black/70 text-15">
                          {totalDistanceDescription}
                        </p>
                      )}
                      <div className="relative mt-5 -ml-5">
                        <ResponsiveContainer width="99%" height={225}>
                          <LineChart
                            width={786}
                            height={225}
                            data={lineChartData}
                            onMouseLeave={() => setActiveChartDot(null)}
                          >
                            <CartesianGrid stroke="#D9D9D9" />
                            <XAxis
                              dataKey="label"
                              padding={{ left: 40, right: 40 }}
                              tickMargin={15}
                              axisLine={AXIS_STYLE}
                              tickLine={false}
                            />
                            <YAxis
                              domain={calculateYAxisDomain(
                                hasValues,
                                ticks.at(-1)
                              )}
                              ticks={ticks}
                              tickCount={5}
                              axisLine={AXIS_STYLE}
                              tickLine={false}
                              tick={(props) => (
                                <CustomYAxisTick
                                  {...props}
                                  highestValue={highestValue}
                                />
                              )}
                            />
                            <Tooltip cursor={false} content={() => null} />
                            <Line
                              hide={!hasValues}
                              dataKey="value"
                              stroke="#206DD0"
                              strokeWidth={2}
                              animationDuration={500}
                              animationEasing="linear"
                              activeDot={(props) => (
                                <CustomActiveDot
                                  {...props}
                                  onMouseOut={() => setActiveChartDot(null)}
                                  onMouseOver={() => setActiveChartDot(props)}
                                />
                              )}
                            />
                          </LineChart>
                        </ResponsiveContainer>
                        {activeChartDot && (
                          <div
                            ref={tooltipRef}
                            className="absolute top-0 left-0 rounded-10 px-3 py-2.5 pointer-events-none"
                            style={{
                              background:
                                "linear-gradient(0deg, rgba(5, 5, 5, 0.80) 0%, rgba(5, 5, 5, 0.80) 100%), #FFF",
                              transform: `translate(calc(${
                                activeChartDot.cx
                              }px - 50%), calc(${
                                activeChartDot.cy - 10
                              }px - 100%)`,
                            }}
                          >
                            <p className="text-white text-13">
                              {tooltipValue} Business {lineChartDistanceUnit}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
                {newsActive && (
                  <div
                    className={`p-5 flex flex-col self-start gap-8 rounded-[14px] border border-border-1 ${
                      isAnyMetricGroupActive || showUpsell
                        ? ""
                        : "lg:col-span-full"
                    }`}
                  >
                    <div>
                      <h5>{newsTitle}</h5>
                      <p className="mt-[5px] text-black/70 text-15">
                        {newsDescription}
                      </p>
                    </div>
                    {newsItems.map(({ title, description }) => (
                      <div key={title}>
                        <p className="text-[16px] font-semibold">{title}</p>
                        <p>{description}</p>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </PageLayout.Main>
    </PageLayout>
  );
}

function BannerInviteDrivers() {
  const [showInviteBanner, setShowInviteBanner] = useState(false);
  const history = useHistory();
  const { user } = useUser();
  const { team } = useContext(TeamContext);
  const { miqDashboardTeamsOverviewInviteBannerWeb } = useFlags();

  useEffect(() => {
    (async () => {
      const isStandardOrPro =
        isTeamsSubscription(team?.subscription?.plan) ||
        isTeamsProSubscription(team?.subscription?.plan);

      if (!isStandardOrPro) return; // don't show if not a standard or pro plan, also don't include in experiment

      const members = await getTeamMembers(team?.id);
      const hasDriversToReport = members.some(
        (m) =>
          m.isDriver && m.status === MEMBER_STATUS.ACTIVE && m.id !== user?.id
      );
      if (hasDriversToReport) return; // don't show if team has drivers to report, also don't include in experiment

      let canShow = miqDashboardTeamsOverviewInviteBannerWeb?.active;

      trackExperimentViewed({
        experimentId: 18,
        experimentName: "Invite Drivers on Dash",
        variationId: canShow ? 2 : 1,
        variationName: canShow ? "V1" : "Control",
      });

      setShowInviteBanner(canShow);
    })();
  }, []);

  function handleInviteDrivers() {
    history.push(
      `/teams/users?invite=true&inviteSrc=${encodeURIComponent(
        teamsInviteSources.OVERVIEW_PAGE
      )}`
    );
  }

  if (!showInviteBanner) return null;

  return (
    <div className="flex gap-[8px] items-center justify-between p-[15px] bg-skyblue rounded-[14px] animate-fade-in-200">
      <p className="text-15">
        {miqDashboardTeamsOverviewInviteBannerWeb?.copy}
      </p>
      <Button icon="plus" className="text-15" onClick={handleInviteDrivers}>
        Invite drivers
      </Button>
    </div>
  );
}
