import { endOfMonth, format, startOfMonth, subMonths } from "date-fns";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";

import { useAppFlash } from "src/components/context/Flash";
import TeamContext from "src/components/context/TeamContext";
import { RECORDS_PER_PAGE } from "src/components/context/TeamDrivesContext";

import { FlashTypes } from "src/components/elements/Flash";

import { guessPageFromPath } from "src/hooks/usePageTracking";
import useQueryParams from "src/hooks/useQueryParams";
import { DUNNING_STATUS, useTeamsCTA } from "src/hooks/useTeamCTA";
import {
  usePickFirstPlanFlow,
  useUpgradePlanFlow,
} from "src/hooks/useUpgradePlan";

import { MEMBER_STATUS } from "src/models/team-member";
import {
  TEAMS_SUBSCRIPTION_PLANS,
  isTeamsProSubscription,
} from "src/models/team-subscription";

import { report } from "src/services/error-reporting";
import { NETWORK_STATES, SORT_ORDER } from "src/services/http";
import {
  DRIVE_REVIEW_STATUS,
  DRIVE_REVIEW_STATUS_ACTION,
  actOnTeamDrives,
  exportTeamDrives,
  fetchTeamDriversSummary,
  getTeamMembers,
} from "src/services/teams";
import {
  reportDownloadFormats,
  teamsUpgradeFeatures,
  teamsUpgradeSources,
  trackTeamsDriveReviewCompleted,
  trackTeamsDriveReviewCompletedBraze,
  trackTeamsDriveReviewFailed,
  trackTeamsDriveReviewStarted,
  trackTeamsDrivesExportCompleted,
  trackTeamsUpgradeStarted,
} from "src/services/tracking";
import { changeTimezoneToUTC, isValidDate, timeout } from "src/services/utils";

const TeamDrivesSummaryContext = createContext({});

export function useTeamDrivesSummary() {
  return useContext(TeamDrivesSummaryContext);
}

export const COLUMNS = {
  DRIVER: "DRIVER",
  DRIVE_COUNT: "DRIVE_COUNT",
  APPROVED_DRIVE_COUNT: "APPROVED_DRIVE_COUNT",
  MANUALLY_ADDED_DRIVES_COUNT: "MANUALLY_ADDED_DRIVES_COUNT",
  DISTANCE: "DISTANCE",
  APPROVED_DISTANCE: "APPROVED_DISTANCE",
  VALUE: "VALUE",
  APPROVED_VALUE: "APPROVED_VALUE",
  ACTIONS: "ACTIONS",
};

export const DEFAULT_DATE_RANGE = [
  startOfMonth(subMonths(new Date(), 1)),
  endOfMonth(subMonths(new Date(), 1)),
];

export const TeamDrivesSummaryProvider = ({ children }) => {
  const { flash } = useAppFlash();
  const { team } = useContext(TeamContext);
  const history = useHistory();
  const queryParams = useQueryParams();
  const page = Number(queryParams.get("page")) || 1;
  const sortBy = queryParams.get("sortBy") || COLUMNS.DRIVE_COUNT;
  const order = queryParams.get("order") || SORT_ORDER.DESC;
  const rangeDates = queryParams.get("rangeDates");
  const { checkAndHandleDunning } = useTeamsCTA();

  const selectedRangeDatesFilter = useMemo(() => {
    if (rangeDates) {
      const [startDate, endDate] = rangeDates
        .split(",")
        .map((date) => new Date(Number(date)));

      if (
        startDate &&
        endDate &&
        isValidDate(startDate) &&
        isValidDate(endDate)
      ) {
        return { values: [startDate, endDate], isCustom: true };
      }
    }

    return { values: DEFAULT_DATE_RANGE, isCustom: false };
  }, [rangeDates]);

  const filteredDrivers = useMemo(() => {
    return queryParams.get("drivers")?.split?.(",") || [];
  }, [queryParams.get("drivers")]);

  const [driverSummaries, setDriverSummaries] = useState({});
  const [loadingItemsCount, setLoadingItemsCount] = useState(0);
  const [teamSummary, setTeamSummary] = useState({});
  const [teamSummaryNetworkState, setTeamSummaryNetworkState] = useState(
    NETWORK_STATES.IDLE
  );
  const [downloadReportDropdownOpen, setDownloadReportDropdownOpen] =
    useState(null);
  const [isGeneratingPDF, setGeneratingPDF] = useState(false);
  const [isGeneratingCSV, setGeneratingCSV] = useState(false);
  const [approveDriverDrivesNetworkState, setApproveDriverDrivesNetworkState] =
    useState(NETWORK_STATES.IDLE);
  const [teamMembers, setTeamMembers] = useState([]);

  const getProPlanFlowOpts = {
    forceShowPickPlan: true,
    onBeforeStart: () => {
      trackTeamsUpgradeStarted({
        src: teamsUpgradeSources.DRIVES_TO_APPROVE_PAGE,
        orgId: team.orgId,
        orgGroupId: team.orgGroupId,
        feature: teamsUpgradeFeatures.APPROVE_REJECT,
      });
    },
    only: TEAMS_SUBSCRIPTION_PLANS.TEAMS_PRO,
    currentSubPlan: team.subscription?.plan,
    customTitle: <p>Upgrade for Approvals</p>,
  };

  const [startGetProPlanFlow, renderGetProPlanFlow] = team.subscription
    ? // eslint-disable-next-line
      useUpgradePlanFlow(getProPlanFlowOpts) // preselect pro and confirm
    : // eslint-disable-next-line
      usePickFirstPlanFlow(getProPlanFlowOpts); // preselect pro and invite first users

  const isTeamSummaryLoading = [
    NETWORK_STATES.LOADING,
    NETWORK_STATES.IDLE,
  ].includes(teamSummaryNetworkState);
  const hasTeamSummaryLoaded =
    teamSummaryNetworkState === NETWORK_STATES.LOADED;
  const isTeamSummaryEmpty =
    hasTeamSummaryLoaded && driverSummaries?.data?.length === 0;

  const isApprovingDriverDrives =
    approveDriverDrivesNetworkState === NETWORK_STATES.LOADING;

  const [startDate, endDate] = [
    parseInt(
      changeTimezoneToUTC(selectedRangeDatesFilter?.values?.[0]).getTime() /
        1000
    ),
    parseInt(
      changeTimezoneToUTC(selectedRangeDatesFilter?.values?.[1]).getTime() /
        1000
    ),
  ];

  useEffect(() => {
    fetchTeamMembers();
  }, []);

  const fetchTeamDrivesSummary = async ({
    additionalFilters,
    loadingItemsCount,
    silent,
  } = {}) => {
    if (!silent) {
      setTeamSummaryNetworkState(NETWORK_STATES.LOADING);
    }

    setLoadingItemsCount(loadingItemsCount || RECORDS_PER_PAGE);

    try {
      const filters = {
        page,
        n_per_page: RECORDS_PER_PAGE,
        sortBy,
        order,
        start_date: startDate,
        end_date: endDate,
        ...additionalFilters,
      };

      const searchParams = new URLSearchParams(filters);

      if (filteredDrivers?.length > 0) {
        filteredDrivers.forEach((driver) =>
          searchParams.append("user_id", driver)
        );
      }

      const { team_summary: summary, drivers } = await fetchTeamDriversSummary(
        searchParams
      );

      setDriverSummaries(drivers);
      setTeamSummary(summary);

      if (!silent) {
        setTeamSummaryNetworkState(NETWORK_STATES.LOADED);
      }
    } catch (error) {
      console.error(error);
      report(error);

      if (!silent) {
        setTeamSummaryNetworkState(NETWORK_STATES.ERROR);
      }
    }

    setLoadingItemsCount(0);
  };

  // TODO: Move driver list data management to a separate context
  const fetchTeamMembers = async () => {
    try {
      const members = await getTeamMembers(team?.id);
      setTeamMembers(
        members
          .filter((m) => {
            return MEMBER_STATUS.ACTIVE == m.status;
          })
          .map((m) => m.user)
      );
    } catch (error) {
      console.error(error);
      report(error);
    }
  };

  const exportDrives = async ({ type = "pdf", driverId }) => {
    if (isGeneratingCSV || isGeneratingPDF) return;

    type === "pdf" ? setGeneratingPDF(true) : setGeneratingCSV(true);

    try {
      const filters = {
        start_date: startDate,
        end_date: endDate,
        type,
      };

      if (driverId != "all-drivers") {
        filters.user_id = driverId;
      }

      const searchParams = new URLSearchParams(filters);

      if (driverId === "all-drivers" && filteredDrivers?.length > 0) {
        filteredDrivers.forEach((driver) =>
          searchParams.append("user_id", driver)
        );
      }

      const blob = await exportTeamDrives(searchParams);
      const blobUrl = URL.createObjectURL(blob);
      const dateRange = `${format(
        selectedRangeDatesFilter?.values?.[0],
        "yyyy-MM-dd"
      )}_${format(selectedRangeDatesFilter?.values?.[1], "yyyy-MM-dd")}`;
      const fileName = `team-drives_${driverId}_${dateRange}.${
        blob.type.split("/")[1]
      }`;

      const a = document.createElement("a");
      a.href = blobUrl;
      a.download = fileName;

      document.body.appendChild(a);
      a.click();
      a.remove();

      trackTeamsDrivesExportCompleted({
        orgId: team.orgId,
        orgGroupId: team.orgGroupId,
        subPlan: team.subscription?.plan,
        downloadFormat:
          type === "pdf"
            ? reportDownloadFormats.PDF
            : reportDownloadFormats.CSV,
        driverCount: driverSummaries?.data?.length,
      });

      setTimeout(() => URL.revokeObjectURL(blobUrl), 100);
    } catch (error) {
      console.error(error);
      report(error);
      flash(
        "Something went wrong while exporting drives, please try again later.",
        {
          type: FlashTypes.ERROR,
        }
      );
    }

    type === "pdf" ? setGeneratingPDF(false) : setGeneratingCSV(false);
  };

  const approveTeamDrives = async ({ driverId }) => {
    if (!isTeamsProSubscription(team.subscription?.plan)) {
      return startGetProPlanFlow();
    }

    const dunningStatus = checkAndHandleDunning();
    if (dunningStatus === DUNNING_STATUS.EXPIRED) return;

    if (!driverId) return console.error("Driver ID is required");
    if (isApprovingDriverDrives) return;

    setApproveDriverDrivesNetworkState(NETWORK_STATES.LOADING);

    trackTeamsDriveReviewStarted({
      orgId: team.orgId,
      orgGroupId: team.orgGroupId,
      subPlan: team.subscription?.plan,
      webPage: guessPageFromPath(window.location.pathname),
    });

    try {
      const filters = {
        start_date: startDate,
        end_date: endDate,
        review_status: DRIVE_REVIEW_STATUS.PENDING,
      };
      const searchParams = new URLSearchParams(filters);

      const { drives } = await actOnTeamDrives({
        searchParams,
        userId: [driverId],
        action: DRIVE_REVIEW_STATUS_ACTION.ACCEPT,
      });

      const succeededDrives = drives.filter(
        ({ status }) => status === "success"
      );

      const succeededDrivesCount = succeededDrives.length;

      if (succeededDrivesCount === 0)
        throw new Error("No drives were approved");

      trackTeamsDriveReviewCompleted({
        orgId: team.orgId,
        orgGroupId: team.orgGroupId,
        subPlan: team.subscription?.plan,
        driveCount: succeededDrivesCount,
        reviewStatusAction: DRIVE_REVIEW_STATUS_ACTION.ACCEPT,
        webPage: guessPageFromPath(window.location.pathname),
      });

      trackTeamsDriveReviewCompletedBraze({
        orgId: team.orgId,
        orgGroupId: team.orgGroupId,
        subPlan: team.subscription?.plan,
        driveCount: succeededDrivesCount,
        reviewStatusAction: DRIVE_REVIEW_STATUS_ACTION.ACCEPT,
        webPage: guessPageFromPath(window.location.pathname),
      });

      flash(
        <p>
          {succeededDrivesCount} {succeededDrivesCount > 1 ? "drives" : "drive"}{" "}
          approved.
        </p>,
        { type: FlashTypes.BLACK }
      );

      const currentPage = driverSummaries?.page;
      const pagesCount = driverSummaries?.pages;
      const isLastPage = currentPage === pagesCount;
      const hasMultiplePages = pagesCount > 1;

      const haveAllDrivesBeenApproved = succeededDrivesCount === drives.length;
      const removedItemsCount = haveAllDrivesBeenApproved ? 1 : 0;
      const hasRemainingItems =
        driverSummaries?.data?.length - removedItemsCount > 0;

      const removeApprovedDriver = () =>
        setDriverSummaries((prev) => ({
          ...prev,
          data: prev?.data?.filter((driver) => driver.id !== driverId),
          total_results: prev?.total_results - 1,
        }));

      const decreaseTotalDrives = () =>
        setTeamSummary((prev) => ({
          ...prev,
          drives: {
            ...prev.drives,
            value: prev.drives.value - succeededDrivesCount,
          },
        }));

      const refetchDrives = () =>
        fetchTeamDrivesSummary({
          loadingItemsCount: hasRemainingItems ? 1 : RECORDS_PER_PAGE,
          silent: hasRemainingItems,
          additionalFilters: { review_status: DRIVE_REVIEW_STATUS.PENDING },
        });

      if (
        haveAllDrivesBeenApproved &&
        hasMultiplePages &&
        isLastPage &&
        !hasRemainingItems
      ) {
        queryParams.set("page", currentPage - 1);
        history.replace({ search: queryParams.toString() });
      } else {
        if (haveAllDrivesBeenApproved) {
          removeApprovedDriver();
          decreaseTotalDrives();
        }

        await timeout(200);

        if (hasRemainingItems && !isLastPage) refetchDrives();
      }

      setApproveDriverDrivesNetworkState(NETWORK_STATES.LOADED);

      return {
        error: false,
        drives,
        allDrivesSucceeded: succeededDrivesCount === drives.length,
      };
    } catch (error) {
      console.error(error);
      report(error);
      setApproveDriverDrivesNetworkState(NETWORK_STATES.ERROR);
      flash(
        <p>
          Something went wrong while approving the drives. Please try again
          later or contact the support.
        </p>,
        { type: FlashTypes.ERROR }
      );
      trackTeamsDriveReviewFailed({
        orgId: team.orgId,
        orgGroupId: team.orgGroupId,
        subPlan: team.subscription?.plan,
        reviewStatusAction: DRIVE_REVIEW_STATUS_ACTION.ACCEPT,
        webPage: guessPageFromPath(window.location.pathname),
      });
      return { error: true };
    }
  };

  return (
    <TeamDrivesSummaryContext.Provider
      value={{
        fetchTeamDrivesSummary,
        approveTeamDrives,
        exportDrives,
        driverSummaries,
        teamSummary,
        teamMembers,
        isTeamSummaryLoading,
        isTeamSummaryEmpty,
        hasTeamSummaryLoaded,
        isApprovingDriverDrives,
        isGeneratingPDF,
        isGeneratingCSV,
        downloadReportDropdownOpen,
        setDownloadReportDropdownOpen,
        page,
        sortBy,
        order,
        selectedRangeDatesFilter,
        filteredDrivers,
        loadingItemsCount,
      }}
    >
      {renderGetProPlanFlow()}
      {children}
    </TeamDrivesSummaryContext.Provider>
  );
};
