import * as braze from "@braze/web-sdk";
import format from "date-fns/format";
import React, { useContext, useEffect, useRef, useState } from "react";

import useElement from "src/lib/layers/useElement";

import { useTeamCancellation } from "src/components/context/TeamCancellationContext";
import TeamContext from "src/components/context/TeamContext";
import { useTeamDunning } from "src/components/context/TeamDunningContext";

import Button from "src/components/elements/Button";
import Dropdown, {
  MiqDropdownItem as DropdownItem,
} from "src/components/elements/Dropdown";
import Icon from "src/components/elements/Icon";
import Loader from "src/components/elements/Loader";
import MobMenu from "src/components/elements/MobMenu";
import Text from "src/components/elements/Text";
import Tooltip from "src/components/elements/Tooltip";

import QuickTips from "src/components/blocks/QuickTips";

import { ELEMENT_ID } from "src/components/modals/CancelTeamsSubscription";
import { DUNNING_SUBSCRIPTION_RECOVER_PAYMENT_MODAL_ID } from "src/components/modals/DunningSubscriptionRecoverPayment";

import PageLayout from "src/components/PageLayout";

import {
  usePickFirstPlanFlow,
  useUpgradePlanFlow,
} from "src/hooks/useUpgradePlan";

import {
  TEAMS_SUBSCRIPTION_PLANS,
  TEAMS_SUBSCRIPTION_PLANS_DATA,
  isTeamsLiteSubscription,
  isTeamsProSubscription,
  isTeamsSubscription,
} from "src/models/team-subscription";

import { createTeamsPortalSession, fetchInvoices } from "src/services/billing";
import {
  TeamsUpdatePaymentSources,
  teamsUpgradeSources,
  trackTeamsSubscriptionCancelationStarted,
  trackTeamsUpgradeStarted,
} from "src/services/tracking";
import { formatCurrency, plural } from "src/services/utils";

import BadgeTeamsLite from "public/assets/img/badge-sub-lite.svg";
import BadgeTeamsPro from "public/assets/img/badge-sub-pro.svg";
import ImgNoPlan from "public/assets/img/car-on-fire.svg";

export default Subscription;

function Subscription() {
  const { team, refreshTeam } = useContext(TeamContext);
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(true);
  const {
    fetchTeamAllTimeStats,
    checkDiscountEligibility,
    isEligibleToClaimDiscount,
    reset,
  } = useTeamCancellation();
  const [showManageSubscriptionMenu, setShowManageSubscriptionMenu] =
    useState(false);
  const cancelSubscriptionModal = useElement(ELEMENT_ID, {
    props: {
      onClose: () => {
        cancelSubscriptionModal.deactivate();
        reset();
      },
    },
  });
  const { resetDunningState, daysUntilGracePeriodEnds, dayLabel } =
    useTeamDunning();

  const subscriptionRecoverPayment = useElement(
    DUNNING_SUBSCRIPTION_RECOVER_PAYMENT_MODAL_ID,
    {
      props: {
        onClose: () => {
          subscriptionRecoverPayment.deactivate();
          resetDunningState();
        },
        source: TeamsUpdatePaymentSources.MANAGE_SUBSCRIPTION_DROPDOWN,
      },
    }
  );
  const cancelSubscriptionRef = useRef();

  const sub = team?.subscription;
  const pendingSub = team?.pendingSubscription;

  const flowOpts = {
    forceShowPickPlan: true,
    onBeforeStart: () => {
      trackTeamsUpgradeStarted({
        src: teamsUpgradeSources.SUBSCRIPTION_PAGE,
        orgId: team.orgId,
        orgGroupId: team.orgGroupId,
      });
    },
  };
  const [startGetAnyPlanFlow, renderGetAnyPlanFlow] = sub
    ? // eslint-disable-next-line
      useUpgradePlanFlow({
        ...flowOpts,
        currentSubPlan: team?.subscription?.plan,
        only: isTeamsSubscription(team?.subscription?.plan)
          ? TEAMS_SUBSCRIPTION_PLANS.TEAMS_PRO
          : null,
      }) // select new plan and confirm
    : // eslint-disable-next-line
      usePickFirstPlanFlow(flowOpts); // select plan and then invite first users

  useEffect(() => {
    fetchTeamAllTimeStats();
    checkDiscountEligibility();

    braze.logCustomEvent("teams_dash_subscription_view");

    async function init() {
      try {
        const [invoices] = await Promise.all([
          fetchInvoices(team?.id),
          refreshTeam(),
        ]);
        if (invoices) setInvoices(invoices);
      } catch (err) {
        console.log(err);
      }
      setLoading(false);
    }
    init();

    return () => reset;
  }, []);

  const handleManageSubscription = async () => {
    if (!team?.subscription) return;

    try {
      const res = await createTeamsPortalSession();
      if (res?.portal_url) {
        setShowManageSubscriptionMenu(false);
        setTimeout(() => {
          window.open(res.portal_url, "_blank").focus();
        }, 0);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleCancelSubscription = () => {
    trackTeamsSubscriptionCancelationStarted({
      teamsPlanId: team.subscription?.plan,
      subType: team.subscription?.type,
      teamsDriverCount: team.subscription?.numberOfSeats,
      teamId: team.id,
      freeTrial: team.subscription?.isFreeTrialActive,
      eligibleToDiscounts: isEligibleToClaimDiscount,
    });

    setShowManageSubscriptionMenu(false);
    cancelSubscriptionModal.activate();
  };

  const handleRecoverPayment = () => {
    setShowManageSubscriptionMenu(false);
    subscriptionRecoverPayment.activate();
  };

  const data = [
    {
      id: "plan",
      renderTitle: () => {
        if (!sub?.plan) {
          return (
            <div className="flex items-baseline mb-2">
              <Text className="text-gray-dark">Plan</Text>
              <Button
                link
                className="text-blue text-13 ml-2 font-bold"
                onClick={() => startGetAnyPlanFlow()}
              >
                {pendingSub?.stripeSubCode ? "Update plan" : "Choose"}
              </Button>
            </div>
          );
        }

        const isTeamsPro = isTeamsProSubscription(sub?.plan);

        if (team?.isExpired) {
          return (
            <div className="flex items-baseline mb-2">
              <Text className={`text-gray-dark ${isTeamsPro ? "mb-2" : ""}`}>
                Plan
              </Text>
              <Button
                link
                className="text-blue text-13 ml-2 font-bold"
                onClick={() => startGetAnyPlanFlow()}
              >
                Resubscribe
              </Button>
            </div>
          );
        }

        if (isTeamsPro) {
          return <Text className="text-gray-dark mb-2">Plan</Text>;
        }

        return (
          <div className="flex items-baseline mb-2">
            <Text className="text-gray-dark">Plan</Text>
            <Button
              link
              className="text-blue text-13 ml-2 font-bold"
              onClick={() => startGetAnyPlanFlow()}
            >
              Upgrade
            </Button>
          </div>
        );
      },
      renderValue: () => {
        const plan = sub?.plan || pendingSub?.stripeSubCode;
        if (!plan) return <h6>-</h6>;
        return (
          <h6 className="text-black flex items-center">
            <span className="mr-2">Teams</span>
            {isTeamsLiteSubscription(plan) ? (
              <BadgeTeamsLite />
            ) : isTeamsProSubscription(plan) ? (
              <BadgeTeamsPro />
            ) : null}
          </h6>
        );
      },
    },
    {
      id: "team_size",
      title: "Teams size",
      value: sub
        ? `${sub.numberOfSeats}  ${plural(sub.numberOfSeats, "user", "users")}`
        : "-",
    },
    {
      id: "next_billing_date",
      title: "Next billing date",
      value:
        sub && !sub.cancelAtPeriodEnd
          ? format(new Date(sub.nextPaymentAt * 1000), "MMM dd, yyyy")
          : "-",
    },
    {
      id: "next_billing_amount",
      title: "Next billing amount",
      value:
        sub && !sub.cancelAtPeriodEnd
          ? formatCurrency({ value: sub.nextBillingAmount / 100 })
          : "-",
    },
  ];

  if (!team?.id) return null;

  // TODO: Remove isTeamsProSubscription check once BE is ready to correctly provide `free_trial_end` and `free_trial_start` properties
  const shouldRenderFreeTrialWarning =
    sub?.isFreeTrialActive &&
    isTeamsProSubscription(sub?.plan) &&
    !team?.isPaymentFailing;

  const shouldRenderSubscriptionExpirationWarning =
    sub?.cancelAtPeriodEnd && !!sub?.cancelAt && !shouldRenderFreeTrialWarning;

  const recoverPaymentLabel = team?.isInGracePeriod
    ? "Update payment"
    : "Resubscribe";

  return (
    <>
      <PageLayout className="page-subscription">
        <PageLayout.Main className="flex flex-col">
          {loading ? (
            <Loader className="p-8" />
          ) : (
            <>
              <div className="page-header relative">
                <div className="title-row flex items-center justify-between gap-2.5">
                  <div className="flex items-center">
                    <MobMenu />
                    <h3>Subscription</h3>
                  </div>
                  {!!sub && (
                    <Dropdown
                      triggerClassName="bg-blue border-2 border-transparent active:border-[#1179FF] active:bg-blue-hover hover:bg-blue-hover justify-between px-[15px]"
                      openTriggerClassName="bg-blue-hover border-[#1179FF]"
                      contentClassName="border border-border-1 right-0 xl:right-auto"
                      contentWidth={201.28}
                      open={showManageSubscriptionMenu}
                      onOpen={() => setShowManageSubscriptionMenu(true)}
                      onClose={() => setShowManageSubscriptionMenu(false)}
                      renderTrigger={() => (
                        <div className="flex items-center gap-[7px]">
                          <Text
                            lg
                            semibold
                            className="hidden md:block"
                            color="white"
                          >
                            Manage subscription
                          </Text>
                          <Text
                            lg
                            semibold
                            className="block md:hidden"
                            color="white"
                          >
                            Manage
                          </Text>
                          <div className="flex items-center justify-center h-5 w-5">
                            <Icon name="caret" color="white" />
                          </div>
                        </div>
                      )}
                    >
                      {team?.isInGracePeriod && (
                        <Tooltip
                          triggerRef={cancelSubscriptionRef}
                          offset={{ y: -80, x: 30 }}
                          timeout={500}
                          width={207}
                        >
                          <p>
                            Your subscription will be automatically canceled in{" "}
                            {daysUntilGracePeriodEnds} {dayLabel}.
                          </p>
                        </Tooltip>
                      )}
                      <DropdownItem
                        icon="edit"
                        onClick={
                          team?.isPaymentFailing
                            ? handleRecoverPayment
                            : handleManageSubscription
                        }
                        textProps={{ semibold: true }}
                      >
                        {team?.isPaymentFailing
                          ? recoverPaymentLabel
                          : "Edit subscription"}
                      </DropdownItem>
                      <DropdownItem
                        containerRef={cancelSubscriptionRef}
                        disabled={
                          team?.isPaymentFailing ||
                          (sub?.cancelAtPeriodEnd && !!sub?.cancelAt)
                        }
                        icon="cancel"
                        color="red"
                        onClick={handleCancelSubscription}
                        textProps={{
                          semibold: true,
                        }}
                      >
                        Cancel subscription
                      </DropdownItem>
                    </Dropdown>
                  )}

                  {!sub && (
                    <Button disabled className="btn-manage-sub">
                      <span className="desktop-version">
                        Manage Subscription
                      </span>
                      <span className="mob-version">Manage</span>
                    </Button>
                  )}
                </div>
                {shouldRenderSubscriptionExpirationWarning && (
                  <div className="flex gap-1 items-center mt-1">
                    <Icon name="warning-triangle" />
                    <Text color="red" className="text-15" semibold>
                      Your{" "}
                      {TEAMS_SUBSCRIPTION_PLANS_DATA.labels[
                        team?.subscription?.plan
                      ] || "Teams"}{" "}
                      subscription will expire on{" "}
                      {format(new Date(sub.cancelAt), "MMM d, yyyy")}
                    </Text>
                  </div>
                )}
                {shouldRenderFreeTrialWarning && (
                  <Text paragraph className="text-15 mt-1 text-red" semibold>
                    Your free trial ends on{" "}
                    {format(new Date(sub.freeTrialEnd), "MMM d, yyyy")}
                  </Text>
                )}
                {team?.isInGracePeriod && (
                  <div className="flex gap-1 items-center mt-1">
                    <Icon name="warning-triangle" />
                    <p className="font-medium text-15 text-red leading-[21px]">
                      Update your payment information by{" "}
                      {team?.longExpiredDateText} to continue your subscription.
                    </p>
                  </div>
                )}
                {team?.isExpired && (
                  <div className="flex gap-1 items-center mt-1">
                    <Icon name="warning-triangle" />
                    <p className="font-medium text-15 text-red leading-[21px]">
                      Your subscription expired on {team?.expiredDateText}
                    </p>
                  </div>
                )}
                <div className="subscription-info pt-5 flex">
                  {data
                    ? data.map(
                        ({ id, title, value, renderTitle, renderValue }) => (
                          <div
                            key={id}
                            className="subscription-info-item flex flex-col flex-grow"
                          >
                            {renderTitle ? (
                              renderTitle()
                            ) : (
                              <Text className="text-gray-dark mb-2">
                                {title}
                              </Text>
                            )}
                            {renderValue ? (
                              renderValue()
                            ) : (
                              <h6 className="text-black">{value}</h6>
                            )}
                          </div>
                        )
                      )
                    : null}
                </div>
              </div>
              <div className="content bg-white flex-grow">
                {!invoices || invoices.length === 0 ? (
                  <div className="flex w-full h-full items-center justify-center">
                    <div
                      className="flex flex-col items-center"
                      style={{ maxWidth: 350 }}
                    >
                      <ImgNoPlan />
                      <Text
                        paragraph
                        lg
                        color="black/70"
                        className="my-8 w-full text-center"
                      >
                        {pendingSub?.stripeSubCode
                          ? "Need to select a different plan? Choose between Teams Lite, Teams, or Teams Pro."
                          : "Pick a plan that works best for you between Teams Pro, Teams, and Teams Lite"}
                      </Text>
                      <Button onClick={() => startGetAnyPlanFlow()}>
                        {pendingSub?.stripeSubCode
                          ? "Update plan"
                          : "Choose a plan"}
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div>
                    <h3 className="p-5 border-b border-border-2">Invoices</h3>
                    <InvoicesList invoices={invoices} />
                  </div>
                )}
              </div>
            </>
          )}
        </PageLayout.Main>
        <PageLayout.Sidebar>
          <QuickTips />
        </PageLayout.Sidebar>
      </PageLayout>

      {/* Select first or upgrade subscription plan flow */}
      {renderGetAnyPlanFlow()}
    </>
  );
}

function InvoicesList({ invoices }) {
  if (!invoices) return null;
  return (
    <>
      {invoices.map((inv) => {
        return (
          <div
            key={inv.id}
            className="teams-invoice-list flex items-center justify-between py-3 px-5 border-b border-border-2"
          >
            <Text className="date-range">
              <span className="start-date whitespace-nowrap">{`${format(
                new Date(inv.period_start * 1000),
                "MMM dd, yyyy"
              )}`}</span>
              <span> - </span>
              <span className="end-date whitespace-nowrap">{`${format(
                new Date(inv.period_end * 1000),
                "MMM dd, yyyy"
              )}`}</span>
            </Text>
            <Button
              ghost
              icon="download"
              onClick={() => (window.location.href = inv.invoice_pdf)}
            >
              Download
            </Button>
          </div>
        );
      })}
    </>
  );
}
