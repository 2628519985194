import { useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import useElement from "src/lib/layers/useElement";

import Button from "src/components/elements/Button";
import { FlashTypes } from "src/components/elements/Flash";
import Input from "src/components/elements/Input";
import PasswordInput from "src/components/elements/PasswordInput";
import Text from "src/components/elements/Text";

import { ELEMENT_ID as UNSAVED_CHANGES_PROMPT_MODAL_ID } from "src/components/modals/UnsavedChangesPrompt";

import useFlash from "src/hooks/useFlash";
import usePrompt from "src/hooks/usePrompt";

import { setAccessToken, setRefreshToken } from "src/services/storage";
import {
  trackUsernameEditCompleted,
  trackUsernameEditStarted,
  trackUsernameEditStepCompleted,
} from "src/services/tracking";
import { EditUsernameStepName } from "src/services/tracking/avo/Avo";
import { isValidEmail } from "src/services/utils";

import { EDIT_USERNAME } from "src/graphql/mutations";

export default EditUsername;

function EditUsername({ subscriptionType, user, refreshUser }) {
  const [email, setEmail] = useState(user.email);
  const [password, setPassword] = useState("");
  const [showPasswordInput, setShowPasswordInput] = useState(false);
  const [emailError, setEmailError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const history = useHistory();
  const location = useLocation();
  const [flash, Flash] = useFlash();
  const [editUsername, { loading }] = useMutation(EDIT_USERNAME, {
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (location.state?.isVerification) {
      flash(<Text>Your email address was successfully verified!</Text>, {
        type: FlashTypes.SAVED,
      });
      window.history.replaceState({ isVerification: false }, "");
    }
  }, []);

  const onDiscard = () => {
    if (unblockRef) {
      unblockRef.current();
    }

    unsavedChangesModal.deactivate();
    history.push(currentPath.current);
  };
  const onKeepEditing = () => {
    unsavedChangesModal.deactivate();
  };

  const unsavedChangesModal = useElement(UNSAVED_CHANGES_PROMPT_MODAL_ID, {
    props: {
      message: "Are you sure you want to quit changing your email address?",
      onCancel: onDiscard,
      onOk: onKeepEditing,
      okLabel: "Keep editing",
      cancelLabel: "Discard changes",
    },
  });
  const { unblockRef, currentPath, isDirty, setDirty } = usePrompt({
    history,
    modalElement: unsavedChangesModal,
  });

  const onChangeEmail = () => {
    setShowPasswordInput(true);
    trackUsernameEditStepCompleted?.({
      subscriptionId: subscriptionType,
      editUsernameStepName: EditUsernameStepName.ADD_EMAIL,
    });
  };

  const onEditUsername = async () => {
    const { data } = await editUsername({
      variables: {
        username: user.email,
        newUsername: email,
        password,
      },
    });
    const error = data?.editUsername?.detail;
    if (error) {
      // BE doesn't return error only when password is wrong.
      // As user doesn't have to type the current username (only new username is typed)
      // We can be sure that he won't have a wrong username, only a password.
      if (error === "Incorrect username or password") {
        setPasswordError(
          "This password doesn’t match our records. Please try again."
        );
      } else {
        setEmailError(error);
      }
      return;
    }

    if (unblockRef) {
      unblockRef?.current?.();
    }
    setAccessToken(data.editUsername.access_token);
    setRefreshToken(data.editUsername.refresh_token);
    trackUsernameEditCompleted?.({
      subscriptionId: subscriptionType,
    });

    flash(<Text>Your email address was successfully updated!</Text>, {
      type: FlashTypes.SAVED,
      onHide: refreshUser,
      hideAfterMs: 1500,
    });
  };

  return (
    <div data-testid="contact-info">
      <h6 className="mb-6">Contact Info</h6>
      <Input
        value={email}
        onFocus={() =>
          trackUsernameEditStarted({
            subscriptionId: subscriptionType,
          })
        }
        onChange={(e) => {
          const newEmail = e.target.value;
          if (!isValidEmail(newEmail)) {
            setEmailError("Please enter a valid email address");
          } else {
            if (emailError) {
              setEmailError(null);
            }
          }

          setEmail(newEmail);
          if (!isDirty) {
            setDirty(true);
          }
        }}
        className="w-[340px]"
        label="Email"
        valid={!emailError}
      />
      {emailError && (
        <Text md regular className="mt-4 inline-flex" color="red">
          {emailError}
        </Text>
      )}

      {!showPasswordInput && (
        <Button
          disabled={email === user.email || !!emailError}
          onClick={onChangeEmail}
          className="font-medium mt-7"
        >
          Change email
        </Button>
      )}

      {showPasswordInput && (
        <>
          <PasswordInput
            onFocus={() =>
              trackUsernameEditStepCompleted?.({
                subscriptionId: subscriptionType,
                editUsernameStepName: EditUsernameStepName.ADD_PASSWORD,
              })
            }
            data-testid="password-input"
            value={password}
            valid={!passwordError}
            errorMessage={passwordError}
            onChange={(e) => {
              if (!isDirty) {
                setDirty(true);
              }
              if (passwordError) {
                setPasswordError(null);
              }
              setPassword(e);
            }}
            className="w-[340px] mt-6"
            label="Your password"
            secondaryLabel="Before we change your email, let's verify it's you."
          />
          <div className="flex gap-2 font-medium mt-7">
            <Button
              disabled={!password || !!passwordError}
              loading={loading}
              onClick={onEditUsername}
            >
              Submit
            </Button>
            <Button ghost onClick={() => setShowPasswordInput(false)}>
              Cancel
            </Button>
          </div>
        </>
      )}
      {Flash}
    </div>
  );
}
