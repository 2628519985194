/* eslint-disable spellcheck/spell-checker */
import { CANCELLATION_STEPS } from "src/components/context/TeamCancellationContext";

import { TABS } from "src/components/blocks/drives/Tabs";

import { DRIVES_FILTERS } from "src/components/modals/DrivesFilters";

import { REMINDER_RECURRENCES } from "src/components/pages/DriverDashboard/settings/automatic-reports/AutomaticReports";

import { PURPOSE_CATEGORY } from "src/models/purpose";
import {
  TEAMS_SUBSCRIPTION_PLANS,
  TEAM_SUBSCRIPTION_TYPES,
} from "src/models/team-subscription";

import { PAYMENT_METHOD, SUBSCRIPTION_TYPE } from "../billing";
import {
  DRIVE_REVIEW_STATUS,
  DRIVE_REVIEW_STATUS_ACTION,
  DRIVE_TYPES,
} from "../teams";
import { VEHICLE_TYPES } from "../utils";
import avo, { Avo } from "./avo";

export * as braze from "@braze/web-sdk";

export function init(user) {
  avo.identify(user);
}

// ----------------

// export function trackTeamDeleteStart() {
//   return avo.track("teamDeleteStart");
// }

// export function trackTeamDeleteConfirm() {
//   return avo.track("teamDeleteConfirm");
// }

// export function trackUserInviteStart() {
//   return avo.track("inviteUserStart");
// }

// export function trackUserInviteExists(email) {
//   return avo.track("inviteUserExists", {
//     email: email.toLowerCase(),
//   });
// }

// export function trackUserInviteSubscription(count) {
//   return avo.track("inviteSubscription", {
//     count: count,
//   });
// }

// export function trackUserInviteCheckout(subscriptionType) {
//   return avo.track("inviteCheckout", {
//     subscriptionType,
//   });
// }

// export function trackUserInviteSend() {
//   return avo.track("inviteSend");
// }

// export function trackUserRemoveStart() {
//   return avo.track("userRemoveStart");
// }

// export function trackUserRemoveConfirm() {
//   return avo.track("userRemoveConfirm");
// }

export const eventPages = {
  ...Avo.Page,
};

const trackWithRateLimit = (eventName, track, limitInMilliseconds) => {
  const eventKey = eventName + "-lastExecuted";
  const lastExecuted = localStorage.getItem(eventKey);
  const now = Date.now();

  if (!lastExecuted || now - Number(lastExecuted) > limitInMilliseconds) {
    localStorage.setItem(eventKey, now.toString());
    track();
  } else {
    console.log(`Rate limit in effect for ${eventName}. Skip tracking.`);
  }
};

// --- Teams Funnel: Signup

export const TEAMS_SIGNUP_SRC_QUERY_PARAM = "teamsSignupSrc";
export function buildTeamsSignupSrcQueryParam(src) {
  return `${TEAMS_SIGNUP_SRC_QUERY_PARAM}=${encodeURIComponent(src)}`;
}
export const TeamsSignupSource = { ...Avo.TeamsSignupSource };
export function trackTeamsSignupStarted() {
  const { search } = window.location;
  const qs = new URLSearchParams(search);
  let teamsSignupSource = decodeURIComponent(
    qs.get(TEAMS_SIGNUP_SRC_QUERY_PARAM)
  );

  if (!Object.values(TeamsSignupSource).includes(teamsSignupSource)) {
    teamsSignupSource = Avo.TeamsSignupSource.DASHBOARD;
  }
  return avo.track("teamsSignupStarted", {
    teamsSignupSource,
  });
}

export function trackTeamsSignupStepCompleted({
  stepNum,
  skipped,
  existingSaSubscription,
}) {
  const steps = {
    1: Avo.TeamsSignupStepName.NAME_TEAM,
    2: Avo.TeamsSignupStepName.SELECT_FEATURES,
    3: Avo.TeamsSignupStepName.CHOOSE_PLAN,
    4: Avo.TeamsSignupStepName.INVITE_USERS,
  };

  return avo.track("teamsSignupStepCompleted", {
    teamsSignupStepName: steps[stepNum],
    teamsSignupStepNumber: stepNum,
    teamsSignupStepInteraction: skipped
      ? Avo.TeamsSignupStepInteraction.SKIPPED
      : Avo.TeamsSignupStepInteraction.COMPLETED,
    existingSaSubscription,
    freeTrial: false,
  });
}

export const TeamsSignupStepName = { ...Avo.TeamsSignupStepName };
export function trackTeamsSignupStepCompletedV2({
  stepNum,
  stepName,
  skipped,
  existingSaSubscription,
  industry,
  companySize,
}) {
  return avo.track("teamsSignupStepCompleted", {
    teamsSignupStepName: stepName,
    teamsSignupStepNumber: stepNum,
    teamsSignupStepInteraction: skipped
      ? Avo.TeamsSignupStepInteraction.SKIPPED
      : Avo.TeamsSignupStepInteraction.COMPLETED,
    existingSaSubscription,
    industry,
    companySize,
    freeTrial: false,
  });
}

export function trackTeamsSignupStepCompletedV3({
  freeTrial,
  skipped,
  existingSaSubscription,
  industry,
  companySize,
}) {
  return avo.track("teamsSignupStepCompleted", {
    teamsSignupStepName: Avo.TeamsSignupStepName.CREATE_TEAM,
    teamsSignupStepNumber: 1,
    teamsSignupStepInteraction: skipped
      ? Avo.TeamsSignupStepInteraction.SKIPPED
      : Avo.TeamsSignupStepInteraction.COMPLETED,
    existingSaSubscription,
    industry,
    companySize,
    freeTrial: !!freeTrial,
  });
}

// --- Teams Funnel: Upgrade
const plansData = {
  [TEAMS_SUBSCRIPTION_PLANS.TEAMS_LITE]: {
    id: 9,
    name: Avo.TeamsPlanName.TEAMS_LITE,
  },
  [TEAMS_SUBSCRIPTION_PLANS.TEAMS]: { id: 10, name: Avo.TeamsPlanName.TEAMS },
  [TEAMS_SUBSCRIPTION_PLANS.TEAMS_PRO]: {
    id: 11,
    name: Avo.TeamsPlanName.TEAMS_PRO,
  },
};

export const teamsUpgradeSources = {
  ...Avo.TeamsUpgradeSource,
};
export const teamsUpgradeFeatures = {
  ...Avo.TeamsUpgradeFeature,
};

export function trackTeamsUpgradeStarted({ src, feature, orgId, orgGroupId }) {
  return avo.track("teamsUpgradeStarted", {
    teamsUpgradeSource: src,
    organizationId: orgId.toString(),
    organizationGroupId: orgGroupId.toString(),
    teamsUpgradeFeature: feature,
  });
}

export function trackTeamsUpgradePlanSelected({ prevPlan, newPlan }) {
  const params = {
    teamsPlanSelectionId: plansData[newPlan].id,
    teamsPlanSelectionName: plansData[newPlan].name,
  };
  // if team had the subscription plan before upgrade we send it
  // otherwise we don't (like in case of the first purchase)
  if (prevPlan) {
    params.teamsPlanId = plansData[prevPlan].id;
    params.teamsPlanName = plansData[prevPlan].name;
  }
  return avo.track("teamsUpgradePlanSelected", params);
}

export function trackTeamsUpgradeCompleted({
  prevPlan,
  newPlan,
  orgId,
  orgGroupId,
  driversCount,
  seatsAddedCount,
  freeTrial = false,
}) {
  const params = {
    teamsPlanSelectionId: plansData[newPlan].id,
    teamsPlanSelectionName: plansData[newPlan].name,
    organizationId: orgId.toString(),
    organizationGroupId: orgGroupId.toString(),
    teamsDriverCount: driversCount || 0,
    teamsSeatsPurchaseCount: seatsAddedCount || 0,
    freeTrial,
  };
  // if team had the subscription plan before upgrade we send it
  // otherwise we don't (like in case of the first purchase)
  if (prevPlan) {
    params.teamsPlanId = plansData[prevPlan].id;
    params.teamsPlanName = plansData[prevPlan].name;
  }
  return avo.track("teamsUpgradeCompleted", params);
}

export function trackTeamsSignupCompleted({
  plan,
  seatsAddedCount,
  freeTrial = false,
}) {
  const params = {
    teamsSeatsPurchaseCount: seatsAddedCount || 0,
    freeTrial,
  };
  if (plan) {
    params.teamsPlanId = plansData[plan].id;
    params.teamsPlanName = plansData[plan].name;
  }
  return avo.track("teamsSignupCompleted", params);
}

// --- Teams Funnel: Invite User

export const teamsInviteSources = {
  ...Avo.TeamsInviteSource,
};

export const teamsInviteFailureReasons = {
  ...Avo.InviteFailureReason,
};

export const teamsUserSelectionActions = {
  ...Avo.UserSelectionAction,
};

export const teamsUserSelectionTypes = {
  ...Avo.UserSelectionType,
};

export const teamsResendInviteTypes = {
  ...Avo.ResendType,
};

export function trackTeamsInviteStarted({ src }) {
  return avo.track("teamsInviteStarted", {
    teamsInviteSource: src,
  });
}

export function trackTeamsInviteEmailAdded({ email }) {
  return avo.track("teamsInviteEmailAdded", { email });
}

export function trackTeamsInviteCompleted({ seatsAdded, inviteCount }) {
  return avo.track("teamsInviteCompleted", {
    teamsSeatsPurchaseCount: seatsAdded,
    inviteCount,
  });
}

export function trackTeamsInviteFailed({ reason }) {
  return avo.track("teamsInviteFailed", { inviteFailureReason: reason });
}

export function trackUserSelected({
  orgId,
  orgGroupId,
  userSelectionAction,
  userSelectionType,
  page,
}) {
  return avo.track("userSelected", {
    organizationId: orgId.toString(),
    organizationGroupId: orgGroupId.toString(),
    userSelectionAction,
    userSelectionType,
    page,
  });
}

export function trackTeamsInviteResent({
  orgId,
  orgGroupId,
  resendType,
  resentUserCount,
}) {
  return avo.track("teamsInviteResent", {
    organizationId: orgId.toString(),
    organizationGroupId: orgGroupId.toString(),
    resendType,
    resentUserCount,
  });
}

export function trackTeamsInviteRevoked({
  orgId,
  orgGroupId,
  revokedUserCount,
}) {
  return avo.track("teamsInviteRevoked", {
    organizationId: orgId.toString(),
    organizationGroupId: orgGroupId.toString(),
    revokedUserCount,
  });
}

export const getExistingStandaloneSubscription = (userData = {}) => {
  const { subscriptionData } = userData;
  const { paymentService, status, type } = subscriptionData || {};

  const saSubscription =
    status === "ACTIVE" &&
    Avo.ExistingSASubscription[`${paymentService}_${type}`];

  return saSubscription || Avo.ExistingSASubscription.NONE;
};

export const teamUserRoles = {
  ...Avo.TeamUserRole,
};

export function getTeamUserRole({ isDriver = false, isAdmin = false } = {}) {
  if (isDriver && isAdmin) return teamUserRoles.ADMIN_DRIVER;
  if (isDriver) return teamUserRoles.DRIVER;
  if (isAdmin) return teamUserRoles.ADMIN;
}

export function trackTeamsInviteAccepted({
  orgId,
  orgGroupId,
  acceptedFromEmailLink,
  existingSaSubscription,
  teamName,
  plan,
  teamUserRole,
}) {
  const planData = plansData[plan];

  return avo.track("teamsInviteAccepted", {
    organizationId: orgId.toString(),
    organizationGroupId: orgGroupId.toString(),
    acceptedFromEmailLink: !!acceptedFromEmailLink,
    teamName,
    teamsPlanName: planData?.name,
    teamsPlanId: planData?.id,
    existingSaSubscription,
    teamUserRole,
  });
}

// --- Teams Funnel: Delete team

export function trackTeamsDeletionStarted() {
  return avo.track("teamsDeletionStarted", {});
}

export function trackTeamsDeletionCompleted() {
  return avo.track("teamsDeletionCompleted", {});
}

// --- Teams Funnel: Misc

export const teamsSettings = {
  ...Avo.TeamSetting,
};

export function trackTeamsSettingUpdated({ setting }) {
  return avo.track("teamsSettingUpdated", {
    teamSetting: setting,
  });
}

export function trackHelpSelected() {
  return avo.track("helpSelected", {});
}

export function trackTeamsRemoveUserStarted({ email }) {
  return avo.track("teamsRemoveUserStarted", { email });
}

export function trackTeamsRemoveUserCompleted({ email }) {
  return avo.track("teamsRemoveUserCompleted", { email });
}

export const teamsUserUpdateSources = {
  ...Avo.TeamsUserUpdate,
};

export function trackTeamsUpdateUserStarted({ email, src }) {
  return avo.track("teamsUpdateUserStarted", { email, teamsUserUpdate: src });
}

export function trackTeamsUpdateUserCompleted({ email, src }) {
  return avo.track("teamsUpdateUserCompleted", { email, teamsUserUpdate: src });
}

// --- Teams Funnel: Submitted Report Usage

export const reportDownloadFormats = {
  ...Avo.DownloadFormat,
};

export const reportDownloadSources = {
  ...Avo.ReportDownloadSource,
};

export const reportDetailsDriveFilters = {
  ...Avo.ReportDetailsDriveFilter,
};

export const reportDetailsSearchFilters = {
  ...Avo.ReportDetailsSearchFilter,
};

export const teamsReportsSubmissionFilters = {
  ...Avo.TeamsReportsSubmissionFilter,
};

export const teamsReportsDriversFilters = {
  ...Avo.TeamsReportsDriversFilter,
};

export const reportSelectionTypes = {
  ...Avo.DriverReportSelectionType,
};

export const reportEmailSources = {
  ...Avo.ReportEmailSource,
};

export const reportOpenSources = {
  ...Avo.ReportOpenSource,
};

export const consolidatedReportTimeframes = {
  ...Avo.ConsolidatedReportTimeframe,
};

export const consolidatedReportFormats = {
  ...Avo.ConsolidatedReportFormat,
};

export function trackDriverReportDetailsFiltered({
  orgId,
  orgGroupId,
  subscriptionId,
  filter,
  searchApplied,
}) {
  return avo.track("driverReportDetailsFiltered", {
    organizationId: orgId.toString(),
    organizationGroupId: orgGroupId.toString(),
    subscriptionId,
    reportDetailsDriveFilter: filter,
    reportDetailsSearchFilter: searchApplied,
  });
}

export function trackDriverReportSelected({
  orgId,
  orgGroupId,
  subscriptionId,
  selectionType,
}) {
  return avo.track("driverReportSelected", {
    organizationId: orgId.toString(),
    organizationGroupId: orgGroupId.toString(),
    subscriptionId,
    driverReportSelectionType: selectionType,
  });
}

export function trackDriverReportDownloaded({
  orgId,
  orgGroupId,
  subscriptionId,
  format,
  source,
  numberOfReports,
}) {
  return avo.track("driverReportDownloaded", {
    organizationId: orgId.toString(),
    organizationGroupId: orgGroupId.toString(),
    subscriptionId,
    downloadFormat: format,
    numberOfReports,
    reportDownloadSource: source,
  });
}

export function trackTeamsReportsFiltered({
  orgId,
  orgGroupId,
  subscriptionId,
  submissionFilter,
  drivesFilter,
  monthFilter,
}) {
  return avo.track("teamsReportsFiltered", {
    organizationId: orgId.toString(),
    organizationGroupId: orgGroupId.toString(),
    subscriptionId,
    teamsReportsSubmissionFilter: submissionFilter,
    teamsReportsDriversFilter: drivesFilter,
    teamsReportsMonthOfDrivesFilter: monthFilter,
  });
}

export function trackDriverReportEmailStarted({ orgId, orgGroupId, source }) {
  return avo.track("driverReportEmailStarted", {
    organizationId: orgId.toString(),
    organizationGroupId: orgGroupId.toString(),
    reportEmailSource: source,
  });
}

export function trackDriverReportEmailCompleted({ email, source }) {
  return avo.track("driverReportEmailCompleted", {
    email,
    reportEmailSource: source,
  });
}

export function trackDriverReportOpened({
  orgId,
  orgGroupId,
  subscriptionId,
  source,
}) {
  return avo.track("driverReportOpened", {
    organizationId: orgId.toString(),
    organizationGroupId: orgGroupId.toString(),
    subscriptionId,
    reportOpenSource: source,
  });
}

export function trackDriverReportDriveSelected({
  orgId,
  orgGroupId,
  subscriptionId,
}) {
  return avo.track("driverReportDriveSelected", {
    organizationId: orgId.toString(),
    organizationGroupId: orgGroupId.toString(),
    subscriptionId,
  });
}

export function trackTeamsConsolidatedReportStarted({
  orgId,
  orgGroupId,
  subscriptionId,
}) {
  return avo.track("teamsConsolidatedReportStarted", {
    organizationId: orgId.toString(),
    organizationGroupId: orgGroupId.toString(),
    subscriptionId,
  });
}

export function trackTeamsConsolidatedReportCompleted({
  orgId,
  orgGroupId,
  subscriptionId,
  timeFrame,
  format,
}) {
  return avo.track("teamsConsolidatedReportCompleted", {
    organizationId: orgId.toString(),
    organizationGroupId: orgGroupId.toString(),
    subscriptionId,
    consolidatedReportTimeframe: timeFrame,
    consolidatedReportFormat: format,
  });
}

// --- START: Users Import // Users Export

export function trackBulkUploadStarted({ orgId, orgGroupId, plan }) {
  return avo.track("bulkUploadStarted", {
    organizationId: orgId.toString(),
    organizationGroupId: orgGroupId.toString(),
    teamsPlanId: plansData[plan]?.id,
    teamsPlanName: plansData[plan]?.name,
  });
}

export function trackBulkUploadStepCompleted({
  orgId,
  orgGroupId,
  bulkUploadStepNumber,
  bulkUploadStepName,
  plan,
}) {
  return avo.track("bulkUploadStepCompleted", {
    organizationId: orgId.toString(),
    organizationGroupId: orgGroupId.toString(),
    teamsPlanId: plansData[plan]?.id,
    teamsPlanName: plansData[plan]?.name,
    bulkUploadStepNumber,
    bulkUploadStepName,
  });
}

export function trackBulkUploadFailed({ orgId, orgGroupId, plan }) {
  return avo.track("bulkUploadFailed", {
    organizationId: orgId.toString(),
    organizationGroupId: orgGroupId.toString(),
    teamsPlanId: plansData[plan]?.id,
    teamsPlanName: plansData[plan]?.name,
  });
}

export function trackBulkUploadCompleted({
  orgId,
  orgGroupId,
  bulkUploadUserCount,
  plan,
}) {
  return avo.track("bulkUploadCompleted", {
    organizationId: orgId.toString(),
    organizationGroupId: orgGroupId.toString(),
    teamsPlanId: plansData[plan]?.id,
    teamsPlanName: plansData[plan]?.name,
    bulkUploadUserCount,
  });
}

export function trackTeamsUserListDownloaded({ orgId, orgGroupId, plan }) {
  return avo.track("teamsUserListDownloaded", {
    organizationId: orgId.toString(),
    organizationGroupId: orgGroupId.toString(),
    teamsPlanId: plansData[plan]?.id,
    teamsPlanName: plansData[plan]?.name,
  });
}

// --- END: Users Import // Users Export

// --- START: Custom Rates

export const customRatesUpdateTypes = {
  ...Avo.CustomRatesUpdateType,
};

export const customRatesSelectionTypes = {
  ...Avo.CustomRatesSelection,
};

export const customRatesEffectiveDateTypes = {
  ...Avo.CustomRatesEffectiveDate,
};

export function trackTeamsCustomRatesUpdateStarted({ updateType, selection }) {
  return avo.track("teamsCustomRatesUpdateStarted", {
    customRatesUpdateType: updateType,
    customRatesSelection: selection,
  });
}

export function trackTeamsCustomRatesUpdateCompleted({
  orgId,
  orgGroupId,
  updateType,
  selection,
  effectiveDate,
}) {
  return avo.track("teamsCustomRatesUpdateCompleted", {
    organizationId: orgId.toString(),
    organizationGroupId: orgGroupId.toString(),
    customRatesUpdateType: updateType,
    customRatesSelection: selection,
    customRatesEffectiveDate: effectiveDate,
  });
}

// --- END: Custom Rates

// --- Signup Events
function getTrackingAttributesFromUrl() {
  const usp = new URLSearchParams(window.location.search);
  const adCategory = (usp.get("ad_category") || "").toLowerCase();
  const utmSource = (usp.get("utm_source") || "").toLowerCase();
  const utmCampaign = (usp.get("utm_campaign") || "").toLowerCase();
  const utmContent = (usp.get("utm_content") || "").toLowerCase();
  return {
    url: window.location.href,
    adCategory,
    utmSource,
    utmCampaign,
    utmContent,
  };
}

export const SignupOriginProduct = { ...Avo.SignupOriginProduct };
export const SignupOriginBillingCycle = { ...Avo.SignupOriginBillingCycle };
export const SignupOriginSubscription = { ...Avo.SignupOriginSubscription };
export function getSignupOriginPropsFromUrl() {
  const teamsSubMap = {
    ["lite"]: SignupOriginSubscription.LITE,
    ["standard"]: SignupOriginSubscription.STANDARD,
    ["pro"]: SignupOriginSubscription.PRO,
  };

  const saSubMap = {
    ["free"]: SignupOriginSubscription.FREE,
    ["unlimited"]: SignupOriginSubscription.UNLIMITED,
  };
  const usp = new URLSearchParams(window.location.search);
  let product = usp.get("product");
  let sub = usp.get("sub");
  let type = usp.get("recurring");

  product =
    product?.toLowerCase() === "teams"
      ? SignupOriginProduct.TEAMS
      : SignupOriginProduct.STANDALONE;

  type =
    type?.toLowerCase() === "mo"
      ? SignupOriginBillingCycle.MONTHLY
      : SignupOriginBillingCycle.ANNUAL;

  switch (product) {
    case SignupOriginProduct.TEAMS:
      sub = teamsSubMap[sub] || teamsSubMap["lite"];
      break;
    case SignupOriginProduct.STANDALONE:
    default:
      sub = saSubMap[sub] || saSubMap["free"];
      break;
  }

  return {
    signupOriginProduct: product,
    signupOriginSubscription: sub,
    signupOriginBillingCycle: type,
  };
}

export const SignupSources = { ...Avo.SignupSource };
export function trackSignupStarted() {
  const { url, adCategory, utmSource, utmCampaign, utmContent } =
    getTrackingAttributesFromUrl();
  const usp = new URLSearchParams(window.location.search);
  const props = {
    url,
    adCategory,
    utmSource,
    utmCampaign,
    utmContent,
    ...getSignupOriginPropsFromUrl(),
  };
  if (usp.get("src")) {
    props.signupSource = usp.get("src");
  }
  avo.track("signupStarted", props);
}

export function trackSignupEmailAdded({ email, isSuggested }) {
  avo.track("signupEmailAdded", {
    email,
    emailAuthenticationMethod: Avo.EmailAuthenticationMethod.MANUAL,
    emailValidationMethod: isSuggested
      ? Avo.EmailValidationMethod.SENDGRID
      : Avo.EmailValidationMethod.MANUAL,
    ...getTrackingAttributesFromUrl(),
  });
}

export function trackSignupCompleted({ userId }) {
  avo.track("signupCompleted", {
    ...getTrackingAttributesFromUrl(),
    ...getSignupOriginPropsFromUrl(),
    userId_: userId,
  });
}

export const SignupFailure = { ...Avo.SignupFailure };
export function trackSignupFailed({ email, signupFailure }) {
  avo.track("signupFailed", {
    email,
    signupFailure,
  });
}

// --- Login Events
export const LoginSources = { ...Avo.LoginSource };
export function trackLoginStarted({ source } = {}) {
  const props = {};
  if (source) {
    props.loginSource = source;
  }
  avo.track("loginStarted", props);
}

export function trackLoginEmailAdded({ email }) {
  avo.track("loginEmailAdded", { email });
}

export function trackLoginCompleted(props) {
  avo.track("loginCompleted", {
    userId_: props.userId,
    loginSessionPreference: props?.multiSession
      ? Avo.LoginSessionPreference.MULTI_SESSION
      : Avo.LoginSessionPreference.SINGLE_SESSION,
  });
}

export function trackLoginFailed({ email, isEmailIncorrect }) {
  avo.track("loginFailed", {
    email,
    loginFailure: isEmailIncorrect
      ? Avo.LoginFailure.EMAIL_INCORRECT
      : Avo.LoginFailure.PASSWORD_INCORRECT,
  });
}

export function trackLogout() {
  avo.track("accountSignedOut");
}

// --- Password Reset Events
export function trackPasswordResetStarted({ email }) {
  avo.track("passwordResetStarted", { email });
}

export function trackPasswordResetRequested({ email }) {
  avo.track("passwordResetRequested", { email });
}

export function trackPasswordResetCompleted({ email }) {
  avo.track("passwordResetCompleted", { email });
}

export function trackPasswordResetFailed({
  email,
  pwdLength,
  pwdDigits,
  pwdUppercase,
  pwdLowercase,
  pwdSpecialChar,
}) {
  let reasons = [];
  if (typeof pwdLength !== "undefined" && !pwdLength) {
    reasons.push(Avo.PasswordResetFailure.LENGTH);
  }
  if (typeof pwdDigits !== "undefined" && !pwdDigits) {
    reasons.push(Avo.PasswordResetFailure.DIGIT);
  }
  if (typeof pwdUppercase !== "undefined" && !pwdUppercase) {
    reasons.push(Avo.PasswordResetFailure.UPPERCASE);
  }
  if (typeof pwdLowercase !== "undefined" && !pwdLowercase) {
    reasons.push(Avo.PasswordResetFailure.LOWERCASE);
  }
  if (typeof pwdSpecialChar !== "undefined" && !pwdSpecialChar) {
    reasons.push(Avo.PasswordResetFailure.SPECIAL_CHARACTER);
  }
  avo.track("passwordResetFailed", {
    email,
    passwordResetFailure: reasons,
  });
}

// --- Get Started Events
export function trackTextDownloadLink() {
  avo.track("overlayItemInteracted", {
    overlayCategory: "Welcome",
    overlayDescription: "Text to Download",
    overlayType: "Popup",
    overlayCta: "Text me a download link",
    overlayInteraction: "Completed CTA",
  });
}

// --- Sharing
export const ShareMileiqSources = {
  ...Avo.ShareSource,
};
export function trackShareMileiq(src) {
  avo.track("shareMileiqSelected", {
    shareSource: src,
  });
}

// --- Blog
export const BlogSource = { ...Avo.BlogSource };
export function trackBlogSelected({ src }) {
  avo.track("blogSelected", {
    blogSource: src,
  });
}

// --- Drive Flows
export const Pages = { ...Avo.Page };
export const DriveSelectionMethod = { ...Avo.DriveSelectionMethod };

export function trackDriveSelected({ page = Avo.Page.DRIVES, method, count }) {
  avo.track("driveSelected", {
    page,
    driveSelectionMethod: method,
    driveCount: count,
  });
}

export function trackDrivesFiltered({ page = Avo.Page.DRIVES, filters }) {
  const {
    DriveDateFilter: ddf,
    DriveClassificationFilter: dcf,
    DriveReportedFilter: drf,
  } = Avo;

  let dateFilter = ddf.CUSTOM_RANGE;
  let classificationFilter = dcf.ALL;
  let advancedFilterCount = 0;

  switch (filters?.dateRange?.type) {
    case "month":
      dateFilter = ddf.MONTH;
      break;
    case "this-month":
      dateFilter = ddf.THIS_MONTH;
      break;
    case "last-month":
      dateFilter = ddf.LAST_MONTH;
      break;
    case "last-three-month":
      dateFilter = ddf.LAST_3_MONTHS;
      break;
    case "year-to-date":
      dateFilter = ddf.YEAR_TO_DATE;
      break;
    case "last-year":
      dateFilter = ddf.LAST_YEAR;
      break;
    case "custom":
    default:
      dateFilter = ddf.CUSTOM_RANGE;
      break;
  }

  switch (filters?.tab) {
    case TABS.UNCLASSIFIED:
      classificationFilter = dcf.UNCLASSIFIED;
      break;
    case TABS.BUSINESS:
      classificationFilter = dcf.BUSINESS;
      break;
    case TABS.PERSONAL:
      classificationFilter = dcf.PERSONAL;
      break;
    case TABS.ALL:
    default:
      classificationFilter = dcf.ALL;
      break;
  }

  advancedFilterCount =
    (filters?.other?.length || 0) +
    (filters?.purposes?.length || 0) +
    (filters?.vehicles?.length || 0);

  avo.track("drivesFiltered", {
    page,
    driveDateFilter: dateFilter,
    driveReportedFilter: filters.showReported ? drf.INCLUDE : drf.EXCLUDE,
    driveClassificationFilter: classificationFilter,
    driveAdvancedFilterCount: advancedFilterCount,
    driveSearch: filters.search
      ? Avo.DriveSearch.APPLIED
      : Avo.DriveSearch.UNAPPLIED,
  });
}

export function trackDrivesAdvanceFiltered({ filters, userPurposes }) {
  const {
    FilterDriveFrequentDrives,
    FilterDriveWorkHours,
    FilterDriveManuallyClassified,
    FilterDriveWithoutNotes,
    DriveSearch,
  } = Avo;
  const { other, purposes, vehicles } = filters;
  const {
    FREQUENT_DRIVES,
    WORK_HOURS,
    MANUALLY_CLASSIFIED,
    WITHOUT_NOTES,
    VEHICLE_UNSPECIFIED,
  } = DRIVES_FILTERS;
  const filterDriveFrequentDrives = other.includes(FREQUENT_DRIVES)
    ? FilterDriveFrequentDrives.SELECTED
    : FilterDriveFrequentDrives.UNSELECTED;
  const filterDriveWorkHours = other.includes(WORK_HOURS)
    ? FilterDriveWorkHours.SELECTED
    : FilterDriveWorkHours.UNSELECTED;
  const filterDriveManuallyClassified = other.includes(MANUALLY_CLASSIFIED)
    ? FilterDriveManuallyClassified.SELECTED
    : FilterDriveManuallyClassified.UNSELECTED;
  const filterDriveWithoutNotes = other.includes(WITHOUT_NOTES)
    ? FilterDriveWithoutNotes.SELECTED
    : FilterDriveWithoutNotes.UNSELECTED;
  const vehicleCount = vehicles.filter((v) => v != VEHICLE_UNSPECIFIED).length;
  const driveSearch = filters.search
    ? DriveSearch.APPLIED
    : DriveSearch.UNAPPLIED;
  let businessPurposeCount = 0;
  let personalPurposeCount = 0;
  userPurposes
    .filter((p) => purposes.includes(p.id))
    .forEach((p) => {
      if (p.isBusiness) businessPurposeCount++;
      else if (p.isPersonal) personalPurposeCount++;
    });
  avo.track("drivesAdvanceFiltered", {
    page: Pages.DRIVES,
    filterDriveFrequentDrives,
    filterDriveWorkHours,
    filterDriveManuallyClassified,
    filterDriveWithoutNotes,
    businessPurposeCount,
    personalPurposeCount,
    vehicleCount,
    driveSearch,
  });
}

export const DriveEditDetail = { ...Avo.DriveDetail };
export function trackDriveEdited({ count, detail }) {
  avo.track("driveEdited", {
    page: Avo.Page.DRIVE_DETAILS,
    driveCount: count,
    driveDetail: detail,
  });
}

function getClassificationType(type) {
  return type === PURPOSE_CATEGORY.BUSINESS
    ? Avo.DriveClassificationType.BUSINESS
    : Avo.DriveClassificationType.PERSONAL;
}
function getExistingClassificationTypes(existingTypes) {
  return [...new Set(existingTypes)].map((t) => {
    switch (t) {
      case PURPOSE_CATEGORY.BUSINESS:
        return Avo.ExistingDriveClassificationType.BUSINESS;
      case PURPOSE_CATEGORY.PERSONAL:
        return Avo.ExistingDriveClassificationType.PERSONAL;
      case PURPOSE_CATEGORY.UNCLASSIFIED:
      default:
        return Avo.ExistingDriveClassificationType.UNCLASSIFIED;
    }
  });
}
export function trackDriveClassifyStarted({
  page,
  count,
  type,
  existingTypes,
}) {
  const driveClassificationType = getClassificationType(type);
  const existingDriveClassificationType =
    getExistingClassificationTypes(existingTypes);

  avo.track("driveClassifyStarted", {
    page,
    driveClassificationType,
    driveCount: count,
    existingDriveClassificationType,
  });
}

export function trackDriveClassifyCompleted({
  page,
  count,
  type,
  existingTypes,
  purpose,
}) {
  const driveClassificationType = getClassificationType(type);
  const existingDriveClassificationType =
    getExistingClassificationTypes(existingTypes);

  const { DriveClassificationPurpose: dcp } = Avo;
  let driveClassificationPurpose = dcp.CUSTOM;
  switch (purpose) {
    case "":
      driveClassificationPurpose =
        type === PURPOSE_CATEGORY.BUSINESS ? dcp.BUSINESS : dcp.PERSONAL;
      break;
    case "Between Offices":
      driveClassificationPurpose = dcp.BETWEEN_OFFICES;
      break;
    case "Customer Visit":
      driveClassificationPurpose = dcp.CUSTOMER_VISIT;
      break;
    case "Meeting":
      driveClassificationPurpose = dcp.MEETING;
      break;
    case "Errand/Supplies":
      driveClassificationPurpose = dcp.ERRAND_SUPPLIES;
      break;
    case "Temporary Site":
      driveClassificationPurpose = dcp.TEMPORARY_SITE;
      break;
    case "Airport/Travel":
      driveClassificationPurpose = dcp.AIRPORT_TRAVEL;
      break;
    case "Commute":
      driveClassificationPurpose = dcp.COMMUTE;
      break;
    case "Medical":
      driveClassificationPurpose = dcp.MEDICAL;
      break;
    case "Charity":
      driveClassificationPurpose = dcp.CHARITY;
      break;
    case "Moving":
      driveClassificationPurpose = dcp.MOVING;
      break;
    case "Business + Commute":
      driveClassificationPurpose = dcp.BUSINESS_PLUS_COMMUTE;
      break;
  }

  avo.track("driveClassifyCompleted", {
    page,
    driveClassificationType,
    driveCount: count,
    existingDriveClassificationType,
    driveClassificationPurpose,
  });
}

export function trackDriveDeleteStarted({ page, count }) {
  avo.track("driveDeleteStarted", {
    page,
    driveCount: count,
  });
}

export function trackDriveDeleteCompleted({ page, count }) {
  avo.track("driveDeleteCompleted", {
    page,
    driveCount: count,
  });
}

export function trackDriveJoinStarted() {
  avo.track("driveJoinStarted", {
    page: Pages.DRIVE_DETAILS,
  });
}

export function trackDriveJoinCompleted() {
  avo.track("driveJoinCompleted", {
    page: Pages.DRIVE_DETAILS,
  });
}

export function trackDriveDuplicateStarted() {
  avo.track("driveDuplicateStarted", {
    page: Pages.DRIVE_DETAILS,
  });
}

export function trackDriveDuplicateCompleted() {
  avo.track("driveDuplicateCompleted", {
    page: Pages.DRIVE_DETAILS,
  });
}

export function trackNamedLocationCreated() {
  avo.track("namedLocationCreated", {
    page: Pages.DRIVE_DETAILS,
  });
}

export function trackNamedLocationEdited(isRenamed) {
  avo.track("namedLocationEdited", {
    page: Pages.DRIVE_DETAILS,
    namedLocationEdit: isRenamed
      ? Avo.NamedLocationEdit.RENAME
      : Avo.NamedLocationEdit.CREATE_NEW_LOCATION,
  });
}

export function trackDriveAddStarted() {
  avo.track("driveAddStarted", {
    page: Pages.DRIVES,
  });
}

export function trackDriveAddCompleted({ category }) {
  const driveClassificationType =
    category === PURPOSE_CATEGORY.BUSINESS
      ? Avo.DriveClassificationType.BUSINESS
      : Avo.DriveClassificationType.PERSONAL;
  avo.track("driveAddCompleted", {
    page: Pages.DRIVES,
    driveClassificationType,
  });
}

export function trackDriveMapEnlarged() {
  avo.track("driveMapEnlarged");
}

// --- Reports Flow
export function trackReportsFiltered({
  filters,
  subscriptionId,
  orgId,
  orgGroupId,
}) {
  let reportsDateFilter, reportsDriveFilter, reportsVehicleFilter;

  const {
    ReportsDateFilter: rdf,
    ReportsDriveFilter: rdrf,
    ReportsVehicleFilter: rvf,
  } = Avo;

  switch (filters?.dateRange?.type) {
    case "month":
      reportsDateFilter = rdf.MONTH;
      break;
    case "this-month":
      reportsDateFilter = rdf.THIS_MONTH;
      break;
    case "last-month":
      reportsDateFilter = rdf.LAST_MONTH;
      break;
    case "last-three-month":
      reportsDateFilter = rdf.LAST_3_MONTHS;
      break;
    case "year-to-date":
      reportsDateFilter = rdf.YEAR_TO_DATE;
      break;
    case "last-year":
      reportsDateFilter = rdf.LAST_YEAR;
      break;
    case "custom":
    default:
      reportsDateFilter = rdf.CUSTOM_RANGE;
      break;
  }

  switch (filters?.category) {
    case PURPOSE_CATEGORY.BUSINESS:
      reportsDriveFilter = rdrf.BUSINESS_DRIVES;
      break;
    case PURPOSE_CATEGORY.PERSONAL:
      reportsDriveFilter = rdrf.PERSONAL_DRIVES;
      break;
    default:
      reportsDriveFilter = rdrf.ALL_DRIVES;
      break;
  }

  reportsVehicleFilter =
    filters?.vehicles?.length > 0 ? rvf.SELECT_VEHICLES : rvf.ALL_VEHICLES;

  avo.track("reportsFiltered", {
    reportsDateFilter,
    reportsDriveFilter,
    reportsVehicleFilter,
    organizationGroupId: orgGroupId?.toString(),
    organizationId: orgId?.toString(),
    subscriptionId,
  });
}

export function trackReportCreationStarted({
  page,
  businessDrivesCount,
  personalDrivesCount,
  subscriptionId,
  orgId,
  orgGroupId,
}) {
  avo.track("reportCreationStarted", {
    reportBusinessDrives: businessDrivesCount,
    reportPersonalDrives: personalDrivesCount,
    organizationId: orgId?.toString(),
    organizationGroupId: orgGroupId?.toString(),
    subscriptionId,
    page,
  });
}

export function trackReportCreationCompleted({
  page,
  businessDrivesCount,
  personalDrivesCount,
  subscriptionId,
  orgId,
  orgGroupId,
  recipientType,
  markAsReported,
  contactRelationship,
}) {
  let reportRecipientType;
  switch (recipientType) {
    case "admin":
      reportRecipientType = Avo.ReportRecipientType.TEAM_ADMIN;
      break;
    case "concur":
      reportRecipientType = Avo.ReportRecipientType.CONCUR;
      break;
    case "contact":
      reportRecipientType = Avo.ReportRecipientType.CONTACT;
      break;
    case "myself":
      reportRecipientType = Avo.ReportRecipientType.MYSELF;
      break;
    case "email":
    default:
      reportRecipientType = Avo.ReportRecipientType.EMAIL;
      break;
  }
  avo.track("reportCreationCompleted", {
    reportBusinessDrives: businessDrivesCount,
    reportPersonalDrives: personalDrivesCount,
    reportUnclassifiedDrives: 0,
    organizationId: orgId?.toString(),
    organizationGroupId: orgGroupId?.toString(),
    reportMarkDrives: markAsReported
      ? Avo.ReportMarkDrives.REPORTED
      : Avo.ReportMarkDrives.UNREPORTED,
    reportRecipientType,
    contactRelationship,
    subscriptionId,
    page,
  });
}

export const ReportCreationFailure = { ...Avo.ReportCreationFailure };
export function trackReportCreationFailed({
  page,
  subscriptionId,
  orgId,
  orgGroupId,
  reportCreationFailure,
}) {
  avo.track("reportCreationFailed", {
    organizationId: orgId?.toString(),
    organizationGroupId: orgGroupId?.toString(),
    reportCreationFailure,
    subscriptionId,
    page,
  });
}

export const DownloadFormats = { ...Avo.DownloadFormat };
export function trackReportDownloaded({
  page = Pages.REPORTS,
  subscriptionId,
  orgId,
  orgGroupId,
  downloadFormat,
}) {
  avo.track("reportDownloaded", {
    organizationId: orgId?.toString(),
    organizationGroupId: orgGroupId?.toString(),
    downloadFormat,
    subscriptionId,
    page,
  });
}
export function trackReportDeleted({ subscriptionId, orgId, orgGroupId }) {
  avo.track("reportDeleted", {
    organizationId: orgId?.toString(),
    organizationGroupId: orgGroupId?.toString(),
    subscriptionId,
  });
}

// -- Custom Purposes Flow
export const CustomPurposeSource = { ...Avo.CustomPurposeSource };
function getDriveClassificationType(category) {
  return category === PURPOSE_CATEGORY.BUSINESS
    ? Avo.DriveClassificationType.BUSINESS
    : Avo.DriveClassificationType.PERSONAL;
}
export function trackCustomPurposeAddStarted({ category, src }) {
  const driveClassificationType = getDriveClassificationType(category);
  avo.track("customPurposeAddStarted", {
    driveClassificationType,
    customPurposeSource: src,
  });
}
export function trackCustomPurposeAddCompleted({ category, src }) {
  const driveClassificationType = getDriveClassificationType(category);
  avo.track("customPurposeAddCompleted", {
    driveClassificationType,
    customPurposeSource: src,
  });
}
export const CustomPurposeUpdate = { ...Avo.CustomPurposeUpdate };
export function trackCustomPurposeUpdated({ category, update }) {
  const driveClassificationType = getDriveClassificationType(category);
  avo.track("customPurposeUpdated", {
    driveClassificationType,
    customPurposeUpdate: update,
  });
}

// -- Vehicles Flow
function getVehicleTypeForTracking(type) {
  let vehicleType;
  switch (type) {
    case VEHICLE_TYPES.BICYCLE:
      vehicleType = Avo.VehicleType.BIKE;
      break;
    case VEHICLE_TYPES.MOTORCYCLE:
      vehicleType = Avo.VehicleType.MOTORCYCLE;
      break;
    case VEHICLE_TYPES.AUTOMOBILE:
    default:
      vehicleType = Avo.VehicleType.CAR;
      break;
  }
  return vehicleType;
}

export function trackVehicleAddStarted({ page /*, type */ }) {
  // const vehicleType = getVehicleTypeForTracking(type);
  avo.track("vehicleAddStarted", {
    page,
    // vehicleType, // TODO: figure out with data team why prop was removed from avo
  });
}
export function trackVehicleAddCompleted({ page, type }) {
  const vehicleType = getVehicleTypeForTracking(type);
  avo.track("vehicleAddCompleted", {
    page,
    vehicleType,
    addVehicleMethod: Avo.AddVehicleMethod.MANUAL,
  });
}
export function trackVehicleUpdated({
  isDetailsChanged,
  isVisibilityChanged,
  isPriorityChanged,
  isVisible,
  isPrimary,
}) {
  const {
    VehicleDetailsUpdate: vdu,
    VehiclePriorityUpdate: vpu,
    VehicleVisibilityUpdate: vvu,
    VehiclePriority: vp,
    VehicleVisibility: vv,
  } = Avo;
  avo.track("vehicleUpdated", {
    vehicleDetailsUpdate: isDetailsChanged ? vdu.UPDATED : vdu.UNCHANGED,
    vehicleVisibilityUpdate: isVisibilityChanged ? vvu.UPDATED : vvu.UNCHANGED,
    vehiclePriorityUpdate: isPriorityChanged ? vpu.UPDATED : vpu.UNCHANGED,
    vehicleVisibility: isVisible ? vv.VIEWABLE : vv.HIDDEN,
    vehiclePriority: isPrimary ? vp.PRIMARY : vp.NON_MINUS_PRIMARY,
  });
}

// -- Auto Classification Flow
export const AutoClassifySource = { ...Avo.work };
export const WorkHoursUpdateSource = { ...Avo.WorkHoursUpdateSource };
export const AutoClassificationPrefs =
  Avo.AutoClassifyDuringWorkHoursPreference;
const schedulePrefs = Avo.WorkHoursSchedule;

export function trackWorkHoursUpdateStarted({
  src,
  autoClassifyDuringWorkHoursPref,
  autoClassifyOutsideWorkHoursPref,
}) {
  avo.track("workHoursUpdateStarted", {
    workHoursUpdateSource: src,
    autoClassifyDuringWorkHoursPreference: autoClassifyDuringWorkHoursPref
      ? AutoClassificationPrefs.ENABLED
      : AutoClassificationPrefs.DISABLED,
    autoClassifyOutsideWorkHoursPreference: autoClassifyOutsideWorkHoursPref
      ? AutoClassificationPrefs.ENABLED
      : AutoClassificationPrefs.DISABLED,
  });
}

export function trackWorkHoursUpdateCompleted({
  autoClassifyDuringWorkHoursPref,
  autoClassifyOutsideWorkHoursPref,
  isComplex,
}) {
  avo.track("workHoursUpdateCompleted", {
    autoClassifyDuringWorkHoursPreference: autoClassifyDuringWorkHoursPref
      ? AutoClassificationPrefs.ENABLED
      : AutoClassificationPrefs.DISABLED,
    autoClassifyOutsideWorkHoursPreference: autoClassifyOutsideWorkHoursPref
      ? AutoClassificationPrefs.ENABLED
      : AutoClassificationPrefs.DISABLED,
    workHoursSchedule: isComplex ? schedulePrefs.COMPLEX : schedulePrefs.SIMPLE,
  });
}
export const WorkHoursFailure = { ...Avo.WorkHourFailure };
export function trackWorkHoursUpdateFailed({ reason }) {
  avo.track("workHoursUpdateFailed", {
    workHourFailure: reason,
  });
}

export function trackFrequentDrivesPreferenceChanged({ isEnabled }) {
  const prefs = Avo.FrequentDrivePreference;
  avo.track("frequentDrivesPreferenceChanged", {
    frequentDrivePreference: isEnabled ? prefs.ENABLED : prefs.DISABLED,
    page: Avo.Page.SETTINGS,
  });
}
export const FrequentDrivePromptAction = { ...Avo.FrequentDrivePromptAction };
export function trackFrequentDrivesPrompted({
  page = Avo.Page.DRIVE_DETAILS,
  promptAction,
  shouldIncludeUnclassidied,
}) {
  const drivesActions = Avo.FrequentUnclassifiedDrivesAction;
  avo.track("frequentDrivePrompted", {
    page,
    frequentDrivePromptAction: promptAction,
    frequentUnclassifiedDrivesAction: shouldIncludeUnclassidied
      ? drivesActions.SELECTED
      : drivesActions.UNSELECTED,
  });
}
export const FrequentDriveEditAction = { ...Avo.FrequentDriveEditAction };
export function trackFrequentDriveEdited({
  page = Avo.Page.DRIVE_DETAILS,
  action,
}) {
  avo.track("frequentDriveEdited", {
    page,
    frequentDriveEditAction: action,
  });
}

// -- Subsctiption Flow
export const UpgradeSource = { ...Avo.UpgradeSource };
function getPageFromPathname() {
  let page;
  const path = window?.location?.pathname;
  switch (true) {
    case path.startsWith("/teams/users"):
      page = Pages.USERS;
      break;
    case path.startsWith("/teams/reports"):
      page = Pages.REPORTS;
      break;
    case path.startsWith("/teams/custom-rates"):
      page = Pages.CUSTOM_RATES;
      break;
    case path.startsWith("/teams/locations"):
      page = Pages.TEAM_LOCATIONS;
      break;
    case path.startsWith("/teams/subscription"):
      page = Pages.SUBSCRIPTION;
      break;
    case path.startsWith("/teams/settings"):
      page = Pages.SETTINGS;
      break;
    case path.startsWith("/user/reports"):
      page = Pages.REPORTS;
      break;
    case path.startsWith("/user/settings"):
      page = Pages.SETTINGS;
      break;
    case path.startsWith("/user/subscription"):
      page = Pages.SUBSCRIPTION;
      break;
    case path.startsWith("/user/get-started"):
      page = Pages.GET_STARTED;
      break;
    case path.startsWith("/user/teams"):
      page = Pages.TEAMS;
      break;
    case path.startsWith("/user/drives"):
    default:
      page = Pages.DRIVES;
      break;
  }
  return page;
}

function getAvoPaymentService(service) {
  let paymentService;
  switch (service) {
    case PAYMENT_METHOD.APPLE:
      paymentService = Avo.PaymentService.APPLE;
      break;
    case PAYMENT_METHOD.APPLE_SANDBOX:
      paymentService = Avo.PaymentService.APPLEMINUS_SANDBO_X;
      break;
    case PAYMENT_METHOD.GOOGLE:
      paymentService = Avo.PaymentService.GOOGLE;
      break;
    case PAYMENT_METHOD.STRIPE:
    default:
      paymentService = Avo.PaymentService.STRIPE;
      break;
  }
  return paymentService;
}

function getAvoSubscriptionType(type) {
  return type == SUBSCRIPTION_TYPE.MONTHLY
    ? Avo.SubscriptionType.MONTHLY
    : Avo.SubscriptionType.ANNUAL;
}

export function trackUpgradeStarted({ subType, page, src }) {
  const props = {
    upgradeSource: src,
    page: page || getPageFromPathname(),
  };
  if (subType) {
    props.subscriptionType = getAvoSubscriptionType(subType);
  }
  avo.track("upgradeStarted", props);
}
export function trackUpgradeCompleted({ subType, src }) {
  const props = {
    upgradeSource: src,
  };
  if (subType) {
    props.subscriptionType = getAvoSubscriptionType(subType);
  }
  avo.track("upgradeCompleted", props);
}
export function trackSubscriptionUpdateStarted({ subType, service }) {
  avo.track("subscriptionUpdateStarted", {
    subscriptionType: getAvoSubscriptionType(subType),
    paymentService: getAvoPaymentService(service),
  });
}
export function trackSubscriptionUpdateCompleted({ subType, service }) {
  avo.track("subscriptionUpdateCompleted", {
    subscriptionType: getAvoSubscriptionType(subType),
    paymentService: getAvoPaymentService(service),
  });
}
export function trackSubscriptionCancelStarted({ subType, service }) {
  avo.track("subscriptionCancelStarted", {
    subscriptionType: getAvoSubscriptionType(subType),
    paymentService: getAvoPaymentService(service),
  });
}
export function trackSubscriptionCancelReasonSelected({
  subType,
  service,
  reason,
  feedback,
}) {
  avo.track("subscriptionCancelReasonSelected", {
    subscriptionType: getAvoSubscriptionType(subType),
    paymentService: getAvoPaymentService(service),
    subscriptionCancelReason: reason,
    subscriptionCancelReasonFeedback: feedback,
  });
}
export function trackSubscriptionCancelCompleted({
  subType,
  service,
  reason,
  feedback,
}) {
  avo.track("subscriptionCancelCompleted", {
    subscriptionType: getAvoSubscriptionType(subType),
    paymentService: getAvoPaymentService(service),
    subscriptionCancelReason: reason,
    subscriptionCancelReasonFeedback: feedback,
  });
}

// -- Rates Flows
export const DistanceUnit = { ...Avo.DistanceUnit };
export function trackDistanceUnitUpdated({ distanceUnit }) {
  avo.track("distanceUnitUpdated", {
    distanceUnit,
  });
}
export function trackDistanceRateUpdateStarted() {
  avo.track("distanceRateUpdateStarted");
}
export function trackDistanceRateUpdateCompleted() {
  avo.track("distanceRateUpdateCompleted");
}

// -- Integrations Flow
export const Integration = { ...Avo.Integration };
export function trackIntegrationEnableStarted({
  subscriptionId,
  integration,
  orgId,
  orgGroupId,
}) {
  avo.track("integrationEnableStarted", {
    subscriptionId,
    integration,
    organizationId: orgId?.toString(),
    organizationGroupId: orgGroupId?.toString(),
  });
}
export function trackIntegrationEnableCompleted({
  subscriptionId,
  integration,
  orgId,
  orgGroupId,
}) {
  avo.track("integrationEnableCompleted", {
    subscriptionId,
    integration,
    organizationId: orgId?.toString(),
    organizationGroupId: orgGroupId?.toString(),
  });
}
export function trackIntegrationEditStarted({
  subscriptionId,
  integration,
  orgId,
  orgGroupId,
}) {
  avo.track("integrationEditStarted", {
    subscriptionId,
    integration,
    organizationId: orgId?.toString(),
    organizationGroupId: orgGroupId?.toString(),
  });
}
export function trackIntegrationEditCompleted({
  subscriptionId,
  integration,
  orgId,
  orgGroupId,
}) {
  avo.track("integrationEditCompleted", {
    subscriptionId,
    integration,
    organizationId: orgId?.toString(),
    organizationGroupId: orgGroupId?.toString(),
  });
}
export function trackIntegrationEditFailed({
  subscriptionId,
  integration,
  integrationFailure,
  orgId,
  orgGroupId,
}) {
  avo.track("integrationEditFailed", {
    subscriptionId,
    integration,
    integrationFailure,
    organizationId: orgId?.toString(),
    organizationGroupId: orgGroupId?.toString(),
  });
}
export const IntegrationStatus = { ...Avo.IntegrationStatus };
export function trackIntegrationChecked({
  subscriptionId,
  integration,
  integrationStatus,
  orgId,
  orgGroupId,
}) {
  avo.track("integrationChecked", {
    subscriptionId,
    integration,
    integrationStatus,
    organizationId: orgId?.toString(),
    organizationGroupId: orgGroupId?.toString(),
  });
}
export function trackIntegrationRemoved({
  subscriptionId,
  integration,
  orgId,
  orgGroupId,
}) {
  avo.track("integrationRemoved", {
    subscriptionId,
    integration,
    organizationId: orgId?.toString(),
    organizationGroupId: orgGroupId?.toString(),
  });
}

// - Email preferences
export function trackEmailSettingUpdated({
  page,
  weeklyEnabled,
  monthlyEnabled,
  otherEnabled,
  promoEnabled,
  unsubAllSelected,
}) {
  const data = { page };
  if (typeof weeklyEnabled !== "undefined") {
    data.weeklyEmailPreferenceChange = weeklyEnabled
      ? Avo.WeeklyEmailPreferenceChange.ENABLED
      : Avo.WeeklyEmailPreferenceChange.DISABLED;
  }
  if (typeof monthlyEnabled !== "undefined") {
    data.monthlyEmailPreferenceChange = monthlyEnabled
      ? Avo.MonthlyEmailPreferenceChange.ENABLED
      : Avo.MonthlyEmailPreferenceChange.DISABLED;
  }
  if (typeof otherEnabled !== "undefined") {
    data.otherEmailPreferenceChange = otherEnabled
      ? Avo.OtherEmailPreferenceChange.ENABLED
      : Avo.OtherEmailPreferenceChange.DISABLED;
  }
  if (typeof promoEnabled !== "undefined") {
    data.promotionalEmailPreferenceChange = promoEnabled
      ? Avo.PromotionalEmailPreferenceChange.ENABLED
      : Avo.PromotionalEmailPreferenceChange.DISABLED;
  }
  data.emailPreferenceChangeMethod = unsubAllSelected
    ? Avo.EmailPreferenceChangeMethod.UNSUBSCRIBE_ALL
    : Avo.EmailPreferenceChangeMethod.SELECT;
  avo.track("emailSettingUpdated", data);
}

export const Intent = { ...Avo.Intent };

export function trackProductPreferenceSubmitted(intent) {
  const { utmSource, utmCampaign, utmContent } = getTrackingAttributesFromUrl();
  avo.track("productPreferenceSubmitted", {
    intent,
    utmSource,
    utmCampaign,
    utmContent,
  });
}

export function trackProductPersonalPreferenceSelected() {
  const { utmSource, utmCampaign, utmContent } = getTrackingAttributesFromUrl();
  avo.track("productPreferenceSelected", {
    intent: Avo.Intent.USE_PERSONAL,
    utmSource,
    utmCampaign,
    utmContent,
  });
}

export function trackProductCreateTeamPreferenceSelected() {
  const { utmSource, utmCampaign, utmContent } = getTrackingAttributesFromUrl();
  avo.track("productPreferenceSelected", {
    intent: Avo.Intent.CREATE_TEAM,
    utmSource,
    utmCampaign,
    utmContent,
  });
}

export function trackProductJoinTeamPreferenceSelected() {
  const { utmSource, utmCampaign, utmContent } = getTrackingAttributesFromUrl();
  avo.track("productPreferenceSelected", {
    intent: Avo.Intent.JOIN_TEAM,
    utmSource,
    utmCampaign,
    utmContent,
  });
}

export function trackInviteAdminStarted() {
  avo.track("inviteAdminStarted");
}

export function trackInviteAdminStepNameEntered({ name }) {
  avo.track("inviteAdminStepCompleted", {
    inviteAdminStep: 1,
    inviteAdminStepName: Avo.InviteAdminStepName.YOUR_NAME_ENTERED,
    name,
  });
}

export function trackInviteAdminStepEmailEntered({ email }) {
  avo.track("inviteAdminStepCompleted", {
    inviteAdminStep: 2,
    inviteAdminStepName: Avo.InviteAdminStepName.ADMIN_EMAIL_ENTERED,
    email,
  });
}

export function trackInviteAdminCompleted({ name, email }) {
  avo.track("inviteAdminCompleted", {
    name,
    email,
  });
}

export function trackFeatureDetailSelected({ featureName, teamsPlanName }) {
  avo.track("featureDetailSelected", {
    featureName,
    teamsPlanName: Avo.TeamsPlanName[teamsPlanName],
  });
}

export function trackOverlayInteractedHelpCompleteOrder({ dismiss }) {
  avo.track("overlayItemInteracted", {
    overlayCategory: Avo.OverlayCategory.TEAMS_ONBOARDING,
    overlayDescription: Avo.OverlayDescription.OFFER_HELP_TO_COMPLETING_ORDER,
    overlayType: Avo.OverlayType.POPUP,
    overlayCta: Avo.OverlayCTA.SCHEDULE_A_CALL,
    overlayInteraction:
      Avo.OverlayInteraction[dismiss ? "DISMISSED" : "COMPLETED_CTA"],
  });
}

// EXPERIMENT VIEWED

export function trackExperimentViewed({
  experimentName,
  experimentId,
  variationName,
  variationId,
}) {
  avo.track("experimentViewed", {
    experimentName,
    experimentId,
    variationName,
    variationId,
  });
}

export function trackUsernameEditStarted({ subscriptionId }) {
  avo.track("usernameEditStarted", {
    subscriptionId,
  });
}

export function trackUsernameEditStepCompleted({
  editUsernameStepName,
  subscriptionId,
}) {
  avo.track("usernameEditStepCompleted", {
    subscriptionId,
    editUsernameStepName,
  });
}

export function trackUsernameEditCompleted({ subscriptionId }) {
  avo.track("usernameEditCompleted", {
    subscriptionId,
  });
}

// TEAM LOCATION

export const AddTeamLocationStepNames = { ...Avo.SharedNamedLocationStepName };
export const TeamLocationFailureReasons = { ...Avo.SharedNamedLocationFailure };

export function trackAddTeamLocationStarted({ orgId, orgGroupId, page }) {
  avo.track("addSharedNamedLocationStarted", {
    organizationId: orgId.toString(),
    organizationGroupId: orgGroupId.toString(),
    page,
  });
}

export function trackAddTeamLocationStep({ orgId, orgGroupId, page, step }) {
  avo.track("addSharedNamedLocationStep", {
    organizationId: orgId.toString(),
    organizationGroupId: orgGroupId.toString(),
    page,
    sharedNamedLocationStepName: step,
  });
}

export function trackAddTeamLocationCompleted({ orgId, orgGroupId, page }) {
  avo.track("addSharedNamedLocationCompleted", {
    organizationId: orgId.toString(),
    organizationGroupId: orgGroupId.toString(),
    page,
  });
}

export function trackTeamLocationFailed({ orgId, orgGroupId, page, reason }) {
  avo.track("sharedNamedLocationFailed", {
    organizationId: orgId.toString(),
    organizationGroupId: orgGroupId.toString(),
    page,
    sharedNamedLocationFailure: reason,
  });
}

export function trackDeleteTeamLocationStarted({ orgId, orgGroupId, page }) {
  avo.track("deleteSharedNamedLocationStarted", {
    organizationId: orgId.toString(),
    organizationGroupId: orgGroupId.toString(),
    page,
  });
}

export function trackDeleteTeamLocationCompleted({ orgId, orgGroupId, page }) {
  avo.track("deleteSharedNamedLocationCompleted", {
    organizationId: orgId.toString(),
    organizationGroupId: orgGroupId.toString(),
    page,
  });
}

export function trackRenameTeamLocationStarted({ orgId, orgGroupId, page }) {
  avo.track("renameSharedNamedLocationStarted", {
    organizationId: orgId.toString(),
    organizationGroupId: orgGroupId.toString(),
    page,
  });
}

export function trackRenameTeamLocationCompleted({ orgId, orgGroupId, page }) {
  avo.track("renameSharedNamedLocationCompleted", {
    organizationId: orgId.toString(),
    organizationGroupId: orgGroupId.toString(),
    page,
  });
}

// -- Search

export function trackSearchStarted({ subscriptionId }) {
  avo.track("searchStarted", {
    page: Pages.DRIVES,
    subscriptionId,
  });
}

export const SearchSelectionType = { ...Avo.SearchSelectionType };
export function trackSearchCompleted({ subscriptionId, type }) {
  avo.track("searchCompleted", {
    subscriptionId,
    page: Pages.DRIVES,
    searchSelectionType: type,
  });
}

// DASHBOARD SWITCH

export const DashboardSwitchFrom = {
  ...Avo.DashboardSwitchFrom,
};

export const DashboardSwitchedTo = {
  ...Avo.DashboardSwitchedTo,
};

export function trackDashboardSwitched({ from, to }) {
  avo.track("dashboardSwitched", {
    page: getPageFromPathname(),
    dashboardSwitchFrom: from,
    dashboardSwitchedTo: to,
  });
}

// TEAMS SUBSCRIPTION CANCELATION

const plansIntervalData = {
  [TEAM_SUBSCRIPTION_TYPES.MONTH]: Avo.TeamsPlanInterval.MONTHLY,
  [TEAM_SUBSCRIPTION_TYPES.YEAR]: Avo.TeamsPlanInterval.ANNUAL,
  [TEAM_SUBSCRIPTION_TYPES.DAY]: Avo.TeamsPlanInterval.DAILY,
};

const cancellationStepData = {
  [CANCELLATION_STEPS.CANCELLATION_REASON]:
    Avo.TeamsSubscriptionCancellationStep.CANCELLATION_REASON,
  [CANCELLATION_STEPS.CLAIM_DISCOUNT]:
    Avo.TeamsSubscriptionCancellationStep.CLAIM_DISCOUNT,
  [CANCELLATION_STEPS.DOWNGRADE_SUBSCRIPTION]:
    Avo.TeamsSubscriptionCancellationStep.DOWNGRADE_SUBSCRIPTION,
  [CANCELLATION_STEPS.TEAM_ALL_TIME_STATS]:
    Avo.TeamsSubscriptionCancellationStep.TEAM_ALL_TIME_STATS,
};

export const CancellationAbandonedMethods = {
  ...Avo.CancellationAbandonedMethod,
};

export function trackTeamsSubscriptionCancelationStarted({
  teamsPlanId,
  teamsDriverCount,
  teamId,
  subType,
  eligibleToDiscounts,
  freeTrial = false,
}) {
  avo.track("teamsSubscriptionCancelationStarted", {
    teamsPlanId: plansData[teamsPlanId]?.id,
    teamsPlanName: plansData[teamsPlanId]?.name,
    teamsPlanInterval: plansIntervalData[subType],
    teamsDriverCount,
    teamId,
    freeTrial,
    eligibleToDiscounts,
  });
}

export function trackTeamsSubscriptionCancelationCompleted({
  teamsPlanId,
  teamsDriverCount,
  teamId,
  cancelationReason,
  subType,
  eligibleToDiscounts,
  cancellationStep,
  freeTrial = false,
}) {
  avo.track("teamsSubscriptionCancelationCompleted", {
    teamsPlanId: plansData[teamsPlanId]?.id,
    teamsPlanName: plansData[teamsPlanId]?.name,
    teamsPlanInterval: plansIntervalData[subType],
    teamsDriverCount,
    teamId,
    cancelationReason,
    freeTrial,
    eligibleToDiscounts,
    teamsSubscriptionCancellationStep: cancellationStepData[cancellationStep],
  });
}

export function trackTeamsSubscriptionCancellationAbandoned({
  teamsPlanId,
  teamsDriverCount,
  teamId,
  subType,
  eligibleToDiscounts,
  cancellationStep,
  method,
  freeTrial = false,
}) {
  avo.track("teamsSubscriptionCancellationAbandoned", {
    teamsPlanId: plansData[teamsPlanId]?.id,
    teamsPlanName: plansData[teamsPlanId]?.name,
    teamsPlanInterval: plansIntervalData[subType],
    teamsDriverCount,
    teamId,
    freeTrial,
    eligibleToDiscounts,
    teamsSubscriptionCancellationStep: cancellationStepData[cancellationStep],
    cancellationAbandonedMethod: method,
  });
}

// UNDOs
export const UserAction = { ...Avo.UserAction };
export function trackActionUndone({ userAction, subscriptionId }) {
  avo.track("actionUndone", {
    page: Avo.Page.DRIVES,
    userAction,
    subscriptionId,
  });
}

// App Download request
export const AppDownloadRequestMethod = { ...Avo.AppDownloadRequestMethod };
export function trackAppDownloadRequested({ method }) {
  avo.track("appDownloadRequested", {
    page: Avo.Page.WELCOME_DOWNLOAD,
    appDownloadRequestMethod: method,
  });
}

export function trackAppDownloadRequestFailed() {
  avo.track("appDownloadRequestFailed");
}

// Free Trial
export const OverlayInteractions = { ...Avo.OverlayInteraction };

export function trackTeamsFreeTrialSelected({ teamName, industry, coupon }) {
  avo.track("teamsFreeTrialSelected", {
    teamName,
    industry,
    discountCouponCode: coupon,
  });
}

export function trackTeamsUpgradeAbandoned({
  orgId,
  orgGroupId,
  currentPlan,
  seatsPurchased,
  selectedPlan,
  driverCount,
  freeTrial,
}) {
  avo.track("teamsUpgradeAbandoned", {
    organizationId: orgId.toString(),
    organizationGroupId: orgGroupId.toString(),
    teamsPlanId: plansData[currentPlan]?.id,
    teamsPlanName: plansData[currentPlan]?.name,
    teamsSeatsPurchaseCount: seatsPurchased,
    teamsPlanSelectionId: plansData[selectedPlan]?.id,
    teamsPlanSelectionName: plansData[selectedPlan]?.name,
    teamsDriverCount: driverCount,
    freeTrial,
  });
}

export function trackTeamsFreeTrialRejected({ teamName, industry, coupon }) {
  avo.track("teamsFreeTrialRejected", {
    teamName,
    industry,
    discountCouponCode: coupon,
  });
}

export function trackOverlayInteractedFreeTrial({ overlayInteraction }) {
  avo.track("overlayItemInteracted", {
    overlayCategory: Avo.OverlayCategory.FREE_TRIAL,
    overlayType: Avo.OverlayType.POPUP,
    overlayInteraction,
  });
}

// Report Reminder
export const ReportReminderRepeatsScheduleSettingTypes = {
  ...Avo.ReportReminderRepeatsScheduleSettingType,
};

export function trackTeamsReportRemindersEnabled({ freeTrial }) {
  avo.track("reportRemindersEnabled", {
    freeTrial,
  });
}

export function trackTeamsReportRemindersDisabled({ freeTrial }) {
  avo.track("reportRemindersDisabled", {
    freeTrial,
  });
}

export function trackTeamsReportRemindersUpdated({
  scheduleSetting,
  firstDate,
  scheduleSettingType,
  freeTrial,
}) {
  avo.track("reportRemindersScheduleUpdated", {
    reportReminderRepeatsScheduleSetting: scheduleSetting,
    reportReminderRepeatsScheduleSettingType: scheduleSettingType,
    firstReportReminderDateSetting: firstDate,
    freeTrial,
  });
}

// Automatic Report Submission

function mapAutomaticReportRecurrenceToAvo(recurrence) {
  switch (recurrence) {
    case REMINDER_RECURRENCES.DAILY:
      return Avo.AutomaticReportsRecurrence.DAILY;
    case REMINDER_RECURRENCES.WEEKLY:
      return Avo.AutomaticReportsRecurrence.WEEKLY;
    case REMINDER_RECURRENCES.MONTHLY:
      return Avo.AutomaticReportsRecurrence.MONTHLY;
  }
}

export function trackAutomaticReportSubmissionEnabled({ recurrence }) {
  avo.track("automaticReportSubmissionEnabled", {
    automaticReportsRecurrence: mapAutomaticReportRecurrenceToAvo(recurrence),
  });
}

export function trackAutomaticReportSubmissionUpdated({ recurrence }) {
  avo.track("automaticReportSubmissionUpdated", {
    automaticReportsRecurrence: mapAutomaticReportRecurrenceToAvo(recurrence),
  });
}

export function trackAutomaticReportSubmissionDisabled() {
  avo.track("automaticReportSubmissionDisabled");
}

// Discounts on Cancellation

export function trackTeamsDiscountClaimed({
  orgId,
  orgGroupId,
  currentSubscriptionPlan,
  currentSubscriptionType,
  driverCount,
}) {
  avo.track("teamsDiscountClaimed", {
    organizationId: orgId.toString(),
    organizationGroupId: orgGroupId.toString(),
    teamsPlanId: plansData[currentSubscriptionPlan]?.id,
    teamsPlanName: plansData[currentSubscriptionPlan]?.name,
    teamsPlanInterval: plansIntervalData[currentSubscriptionType],
    teamsDriverCount: driverCount,
  });
}

export function trackTeamsAllTimeStatsViewed({
  orgId,
  orgGroupId,
  currentSubscriptionPlan,
  currentSubscriptionType,
  driverCount,
  eligibleToDiscounts,
}) {
  avo.track("teamsAllTimeStatsViewed", {
    organizationId: orgId.toString(),
    organizationGroupId: orgGroupId.toString(),
    teamsPlanId: plansData[currentSubscriptionPlan]?.id,
    teamsPlanName: plansData[currentSubscriptionPlan]?.name,
    teamsPlanInterval: plansIntervalData[currentSubscriptionType],
    teamsDriverCount: driverCount,
    eligibleToDiscounts,
  });
}

// Teams Downgrade

export const TeamsDowngradeSources = { ...Avo.TeamsDowngradeSource };

export function trackTeamsDowngradeStarted({
  orgId,
  orgGroupId,
  currentSubscriptionPlan,
  currentSubscriptionType,
  driverCount,
  freeTrial,
  eligibleToDiscounts,
  source,
}) {
  avo.track("teamsDowngradeStarted", {
    organizationId: orgId.toString(),
    organizationGroupId: orgGroupId.toString(),
    teamsPlanId: plansData[currentSubscriptionPlan]?.id,
    teamsPlanName: plansData[currentSubscriptionPlan]?.name,
    teamsPlanInterval: plansIntervalData[currentSubscriptionType],
    teamsDriverCount: driverCount,
    freeTrial,
    eligibleToDiscounts,
    teamsDowngradeSource: source,
  });
}

export function trackTeamsDowngradeCompleted({
  orgId,
  orgGroupId,
  currentSubscriptionPlan,
  currentSubscriptionType,
  selectedPlan,
  selectedType,
  driverCount,
  freeTrial,
  eligibleToDiscounts,
}) {
  avo.track("teamsDowngradeCompleted", {
    organizationId: orgId.toString(),
    organizationGroupId: orgGroupId.toString(),
    teamsPlanId: plansData[currentSubscriptionPlan]?.id,
    teamsPlanName: plansData[currentSubscriptionPlan]?.name,
    teamsPlanInterval: plansIntervalData[currentSubscriptionType],
    teamsPlanSelectionId: plansData[selectedPlan]?.id,
    teamsPlanSelectionName: plansData[selectedPlan]?.name,
    teamsPlanSelectionInterval: plansIntervalData[selectedType],
    teamsDriverCount: driverCount,
    freeTrial,
    eligibleToDiscounts,
  });
}

// Web Page Viewed

export const WebPages = { ...Avo.WebPage };

export function trackWebPageViewed({ currentPage, previousPage }) {
  avo.track("webPageViewed", {
    webPage: currentPage,
    previousWebPage: previousPage,
  });
}
// Teams Overview

export function trackTeamsOverviewMetricsFailed({
  orgId,
  orgGroupId,
  subPlan,
  subType,
}) {
  avo.track("teamsOverviewMetricsFailed", {
    organizationId: orgId.toString(),
    organizationGroupId: orgGroupId.toString(),
    teamsPlanId: plansData[subPlan]?.id,
    teamsPlanName: plansData[subPlan]?.name,
    teamsPlanInterval: plansIntervalData[subType],
  });
}

// Profile names

export function trackProfileNameAddStarted({ subscriptionId }) {
  avo.track("profileNameAddStarted", {
    subscriptionId,
  });
}

export function trackProfileNameAddCompleted({ subscriptionId }) {
  avo.track("profileNameAddCompleted", {
    subscriptionId,
  });
}

export function trackProfileNameUpdateStarted({ subscriptionId }) {
  avo.track("profileNameUpdateStarted", {
    subscriptionId,
  });
}

export function trackProfileNameUpdateCompleted({ subscriptionId }) {
  avo.track("profileNameUpdateCompleted", {
    subscriptionId,
  });
}

export function trackreportCreationStepCompleted({
  orgId,
  orgGroupId,
  subscriptionId,
  page,
}) {
  avo.track("reportCreationStepCompleted", {
    subscriptionId,
    organizationId: orgId?.toString(),
    organizationGroupId: orgGroupId?.toString(),
    page: page ?? Avo.Page.CREATE_REPORT,
    reportCreationStepName: Avo.ReportCreationStepName.REPORT_DETAILS_COMPLETED,
  });
}

export function trackContactAddStarted({
  orgId,
  orgGroupId,
  subscriptionId,
  page,
}) {
  let avoPage;
  switch (page) {
    case "Settings":
      avoPage = Avo.Page.SETTINGS;
      break;
    case "Share Report":
      avoPage = Avo.Page.SHARE_REPORT;
      break;
  }
  avo.track("contactAddStarted", {
    organizationId: orgId?.toString(),
    organizationGroupId: orgGroupId?.toString(),
    subscriptionId: subscriptionId,
    page: avoPage,
  });
}

export function trackContactAddCompleted({
  orgId,
  orgGroupId,
  subscriptionId,
  page,
  contactRelationship,
}) {
  let avoPage;
  switch (page) {
    case "Settings":
      avoPage = Avo.Page.SETTINGS;
      break;
    case "Share Report":
      avoPage = Avo.Page.SHARE_REPORT;
      break;
  }
  avo.track("contactAddCompleted", {
    organizationId: orgId?.toString(),
    organizationGroupId: orgGroupId?.toString(),
    subscriptionId: subscriptionId,
    page: avoPage,
    contactRelationship: contactRelationship,
  });
}

// Teams Dunning

export const TeamsUpdatePaymentSources = { ...Avo.TeamsUpdatePaymentSource };
export const ModalAbandonMethods = { ...Avo.ModalAbandonMethod };
export const TeamsPaymentFailedModalSources = {
  ...Avo.TeamsPaymentFailedModalSource,
};
export const TeamsResubscribeModalSources = {
  ...Avo.TeamsResubscribeModalSource,
};

export function trackTeamsUpdatePaymentStarted({
  orgId,
  orgGroupId,
  subPlan,
  subType,
  teamsDriverCount,
  subscriptionExpired,
  daysUntilGracePeriodEnds,
  teamsUpdatePaymentSource,
}) {
  avo.track("teamsUpdatePaymentStarted", {
    organizationId: orgId.toString(),
    organizationGroupId: orgGroupId.toString(),
    teamsPlanId: plansData[subPlan]?.id,
    teamsPlanName: plansData[subPlan]?.name,
    teamsPlanInterval: plansIntervalData[subType],
    teamsDriverCount,
    subscriptionExpired,
    daysUntilGracePeriodEnds,
    teamsUpdatePaymentSource,
  });
}

export function trackTeamsUpdatePaymentCompleted({
  orgId,
  orgGroupId,
  subPlan,
  subType,
  paymentAmountDue,
  teamsDriverCount,
  subscriptionExpired,
  daysUntilGracePeriodEnds,
}) {
  avo.track("teamsUpdatePaymentCompleted", {
    organizationId: orgId.toString(),
    organizationGroupId: orgGroupId.toString(),
    teamsPlanId: plansData[subPlan]?.id,
    teamsPlanName: plansData[subPlan]?.name,
    teamsPlanInterval: plansIntervalData[subType],
    paymentAmountDue,
    teamsDriverCount,
    subscriptionExpired,
    daysUntilGracePeriodEnds,
  });
}

export function trackTeamsUpdatePaymentFailed({
  orgId,
  orgGroupId,
  subPlan,
  subType,
  teamsDriverCount,
  subscriptionExpired,
  daysUntilGracePeriodEnds,
  teamsUpdatePaymentFailReason,
}) {
  avo.track("teamsUpdatePaymentFailed", {
    organizationId: orgId.toString(),
    organizationGroupId: orgGroupId.toString(),
    teamsPlanId: plansData[subPlan]?.id,
    teamsPlanName: plansData[subPlan]?.name,
    teamsPlanInterval: plansIntervalData[subType],
    teamsDriverCount,
    subscriptionExpired,
    daysUntilGracePeriodEnds,
    teamsUpdatePaymentFailReason,
  });
}

export function trackTeamsUpdatePaymentAbandoned({
  orgId,
  orgGroupId,
  subPlan,
  subType,
  teamsDriverCount,
  subscriptionExpired,
  daysUntilGracePeriodEnds,
  modalAbandonMethod,
}) {
  avo.track("teamsUpdatePaymentAbandoned", {
    organizationId: orgId.toString(),
    organizationGroupId: orgGroupId.toString(),
    teamsPlanId: plansData[subPlan]?.id,
    teamsPlanName: plansData[subPlan]?.name,
    teamsPlanInterval: plansIntervalData[subType],
    teamsDriverCount,
    subscriptionExpired,
    daysUntilGracePeriodEnds,
    modalAbandonMethod,
  });
}

export function trackTeamsDunningWarningModalStarted({
  orgId,
  orgGroupId,
  subPlan,
  subType,
  teamsDriverCount,
  subscriptionExpired,
  daysUntilGracePeriodEnds,
  webPage,
}) {
  avo.track("teamsDunningWarningModalStarted", {
    organizationId: orgId.toString(),
    organizationGroupId: orgGroupId.toString(),
    teamsPlanId: plansData[subPlan]?.id,
    teamsPlanName: plansData[subPlan]?.name,
    teamsPlanInterval: plansIntervalData[subType],
    teamsDriverCount,
    subscriptionExpired,
    daysUntilGracePeriodEnds,
    webPage,
  });
}

export function trackTeamsDunningWarningModalAbandoned({
  orgId,
  orgGroupId,
  subPlan,
  subType,
  teamsDriverCount,
  subscriptionExpired,
  daysUntilGracePeriodEnds,
  modalAbandonMethod,
  webPage,
}) {
  avo.track("teamsDunningWarningModalAbandoned", {
    organizationId: orgId.toString(),
    organizationGroupId: orgGroupId.toString(),
    teamsPlanId: plansData[subPlan]?.id,
    teamsPlanName: plansData[subPlan]?.name,
    teamsPlanInterval: plansIntervalData[subType],
    teamsDriverCount,
    subscriptionExpired,
    daysUntilGracePeriodEnds,
    modalAbandonMethod,
    webPage,
  });
}

export function trackTeamsResubscribeModalStarted({
  orgId,
  orgGroupId,
  subPlan,
  subType,
  teamsDriverCount,
  teamsResubscribeModalSource,
  webPage,
}) {
  avo.track("teamsResubscribeModalStarted", {
    organizationId: orgId.toString(),
    organizationGroupId: orgGroupId.toString(),
    teamsPlanId: plansData[subPlan]?.id,
    teamsPlanName: plansData[subPlan]?.name,
    teamsPlanInterval: plansIntervalData[subType],
    teamsDriverCount,
    teamsResubscribeModalSource,
    webPage,
  });
}

export function trackTeamsResubscribeModalAbandoned({
  orgId,
  orgGroupId,
  subPlan,
  subType,
  teamsDriverCount,
  modalAbandonMethod,
  webPage,
}) {
  avo.track("teamsResubscribeModalAbandoned", {
    organizationId: orgId.toString(),
    organizationGroupId: orgGroupId.toString(),
    teamsPlanId: plansData[subPlan]?.id,
    teamsPlanName: plansData[subPlan]?.name,
    teamsPlanInterval: plansIntervalData[subType],
    teamsDriverCount,
    modalAbandonMethod,
    webPage,
  });
}

// Teams Drives

export const TeamDrivesDriversFilters = { ...Avo.TeamDrivesDriversFilter };

export function trackTeamsDrivesListOpened({
  orgId,
  orgGroupId,
  subPlan,
  isAllDrivers,
  webPage,
}) {
  avo.track("teamsDrivesListOpened", {
    organizationId: orgId.toString(),
    organizationGroupId: orgGroupId.toString(),
    teamsPlanId: plansData[subPlan]?.id,
    teamsPlanName: plansData[subPlan]?.name,
    allDrivers: isAllDrivers,
    webPage,
  });
}

export function trackTeamsDrivesListFiltered({
  orgId,
  orgGroupId,
  subPlan,
  dateFilter,
  statusFilter,
  driveTypeFilter,
  teamDrivesDriversFilter,
  teamDrivesPurposeFilter,
  webPage,
}) {
  let reviewStatusFilter;
  if (statusFilter === DRIVE_REVIEW_STATUS.ANY.toString()) {
    reviewStatusFilter = Avo.ReviewStatusFilter.ALL;
  } else if (statusFilter === DRIVE_REVIEW_STATUS.ACCEPTED.toString()) {
    reviewStatusFilter = Avo.ReviewStatusFilter.APPROVED;
  } else if (statusFilter === DRIVE_REVIEW_STATUS.REJECTED.toString()) {
    reviewStatusFilter = Avo.ReviewStatusFilter.REJECTED;
  } else if (statusFilter === DRIVE_REVIEW_STATUS.PENDING.toString()) {
    reviewStatusFilter = Avo.ReviewStatusFilter.NO_STATUS;
  }

  let reviewDriveTypeFilter;
  if (driveTypeFilter === DRIVE_TYPES.ANY) {
    reviewDriveTypeFilter = Avo.ReviewDriveTypeFilter.ALL;
  } else if (driveTypeFilter === DRIVE_TYPES.MANUALLY_ADDED) {
    reviewDriveTypeFilter = Avo.ReviewDriveTypeFilter.MANUAL;
  } else if (driveTypeFilter === DRIVE_TYPES.AUTOMATICALLY_DETECTED) {
    reviewDriveTypeFilter = Avo.ReviewDriveTypeFilter.AUTOMATIC;
  }

  avo.track("teamsDrivesListFiltered", {
    organizationId: orgId.toString(),
    organizationGroupId: orgGroupId.toString(),
    teamsPlanId: plansData[subPlan]?.id,
    teamsPlanName: plansData[subPlan]?.name,
    teamDrivesDateFilter: dateFilter,
    reviewStatusFilter,
    reviewDriveTypeFilter,
    teamDrivesDriversFilter,
    teamDrivesPurposeFilter,
    webPage,
  });
}

export function trackTeamsDrivesExportStarted({ orgId, orgGroupId, subPlan }) {
  avo.track("teamsDrivesExportStarted", {
    organizationId: orgId.toString(),
    organizationGroupId: orgGroupId.toString(),
    teamsPlanId: plansData[subPlan]?.id,
    teamsPlanName: plansData[subPlan]?.name,
  });
}

export function trackTeamsDrivesExportCompleted({
  orgId,
  orgGroupId,
  subPlan,
  driverCount,
  downloadFormat,
}) {
  avo.track("teamsDrivesExportCompleted", {
    organizationId: orgId.toString(),
    organizationGroupId: orgGroupId.toString(),
    teamsPlanId: plansData[subPlan]?.id,
    teamsPlanName: plansData[subPlan]?.name,
    downloadFormat,
    exportedDriverCount: driverCount,
  });
}

export function trackTeamsDrivesSummaryFiltered({
  orgId,
  orgGroupId,
  subPlan,
  dateFilter,
  driversFilter,
}) {
  avo.track("teamsDrivesSummaryFiltered", {
    organizationId: orgId.toString(),
    organizationGroupId: orgGroupId.toString(),
    teamsPlanId: plansData[subPlan]?.id,
    teamsPlanName: plansData[subPlan]?.name,
    teamDrivesDateFilter: dateFilter,
    teamDrivesDriversFilter: driversFilter,
  });
}

export function trackTeamsDriveSelected({
  orgId,
  orgGroupId,
  subPlan,
  webPage,
}) {
  avo.track("teamsDriveSelected", {
    organizationId: orgId.toString(),
    organizationGroupId: orgGroupId.toString(),
    teamsPlanId: plansData[subPlan]?.id,
    teamsPlanName: plansData[subPlan]?.name,
    webPage: webPage,
  });
}

export function trackTeamsDriveReviewCtaSelected({
  orgId,
  orgGroupId,
  subPlan,
  driveReviewCta,
}) {
  avo.track("teamsDriveReviewCtaSelected", {
    organizationId: orgId.toString(),
    organizationGroupId: orgGroupId.toString(),
    teamsPlanId: plansData[subPlan]?.id,
    teamsPlanName: plansData[subPlan]?.name,
    driveReviewCta,
  });
}

export function trackTeamsDriveReviewAllSelected({
  orgId,
  orgGroupId,
  subPlan,
  driveCount,
}) {
  avo.track("teamsDriveReviewAllSelected", {
    organizationId: orgId.toString(),
    organizationGroupId: orgGroupId.toString(),
    teamsPlanId: plansData[subPlan]?.id,
    teamsPlanName: plansData[subPlan]?.name,
    driveCount,
  });
}

function mapDriveReviewStatusActionToReviewAction(driveReviewStatusAction) {
  if (driveReviewStatusAction === DRIVE_REVIEW_STATUS_ACTION.ACCEPT)
    return Avo.ReviewAction.ACCEPT;
  if (driveReviewStatusAction === DRIVE_REVIEW_STATUS_ACTION.REJECT)
    return Avo.ReviewAction.REJECT;
  if (driveReviewStatusAction === DRIVE_REVIEW_STATUS_ACTION.RESET)
    return Avo.ReviewAction.RESET;
}

export function trackTeamsDriveReviewStarted({
  orgId,
  orgGroupId,
  subPlan,
  webPage,
}) {
  avo.track("teamsDriveReviewStarted", {
    organizationId: orgId.toString(),
    organizationGroupId: orgGroupId.toString(),
    teamsPlanId: plansData[subPlan]?.id,
    teamsPlanName: plansData[subPlan]?.name,
    webPage,
  });
}

export function trackTeamsDriveReviewCompleted({
  orgId,
  orgGroupId,
  subPlan,
  driveCount,
  reviewStatusAction,
  webPage,
}) {
  avo.track("teamsDriveReviewCompleted", {
    organizationId: orgId.toString(),
    organizationGroupId: orgGroupId.toString(),
    teamsPlanId: plansData[subPlan]?.id,
    teamsPlanName: plansData[subPlan]?.name,
    driveCount,
    reviewAction: mapDriveReviewStatusActionToReviewAction(reviewStatusAction),
    webPage,
  });
}

// same as teamsDriveReviewCompleted but for braze and rate limited
export function trackTeamsDriveReviewCompletedBraze({
  orgId,
  orgGroupId,
  subPlan,
  driveCount,
  reviewStatusAction,
  webPage,
}) {
  trackWithRateLimit(
    "teamsDriveReviewCompletedBraze",
    () => {
      avo.track("teamsDriveReviewCompletedBraze", {
        organizationId: orgId.toString(),
        organizationGroupId: orgGroupId.toString(),
        teamsPlanId: plansData[subPlan]?.id,
        teamsPlanName: plansData[subPlan]?.name,
        driveCount,
        reviewAction:
          mapDriveReviewStatusActionToReviewAction(reviewStatusAction),
        webPage,
      });
    },
    3600000 // rate limit to once every hour
  );
}

export function trackTeamsDriveReviewFailed({
  orgId,
  orgGroupId,
  subPlan,
  reviewStatusAction,
  webPage,
}) {
  avo.track("teamsDriveReviewFailed", {
    organizationId: orgId.toString(),
    organizationGroupId: orgGroupId.toString(),
    teamsPlanId: plansData[subPlan]?.id,
    teamsPlanName: plansData[subPlan]?.name,
    reviewAction: mapDriveReviewStatusActionToReviewAction(reviewStatusAction),
    webPage,
  });
}

export function trackExcludeCommuteEnabled({
  userId,
  subPlan,
  freeTrial,
  country,
  orgId,
}) {
  avo.track("excludeCommuteEnabled", {
    userId_: userId,
    teamsPlanId: plansData[subPlan]?.id,
    freeTrial,
    country,
    organizationId: orgId.toString(),
    commuteMileageEnabled: true,
  });
}

export function trackExcludeCommuteDisabled({
  userId,
  subPlan,
  freeTrial,
  country,
  orgId,
}) {
  avo.track("excludeCommuteDisabled", {
    userId_: userId,
    teamsPlanId: plansData[subPlan]?.id,
    freeTrial,
    country,
    organizationId: orgId.toString(),
    commuteMileageEnabled: false,
  });
}

export function trackExcludeCommuteSettingsUpdateStarted({
  subPlan,
  freeTrial,
  country,
  orgId,
}) {
  avo.track("excludeCommuteSettingsUpdateStarted", {
    teamsPlanId: plansData[subPlan]?.id,
    freeTrial,
    country,
    organizationId: orgId.toString(),
  });
}

export function trackExcludeCommuteSettingsUpdateCompleted({
  subPlan,
  freeTrial,
  country,
  orgId,
}) {
  avo.track("excludeCommuteSettingsUpdateCompleted", {
    teamsPlanId: plansData[subPlan]?.id,
    freeTrial,
    country,
    organizationId: orgId.toString(),
  });
}

export function trackExcludeCommuteSettingsUpdateFailed({
  subPlan,
  freeTrial,
  country,
  orgId,
}) {
  avo.track("excludeCommuteSettingsUpdateFailed", {
    teamsPlanId: plansData[subPlan]?.id,
    freeTrial,
    country,
    organizationId: orgId.toString(),
  });
}

export function trackTeamsDriveCommentStarted({
  orgId,
  orgGroupId,
  subPlan,
  webPage,
}) {
  avo.track("teamsDriveCommentStarted", {
    organizationId: orgId.toString(),
    organizationGroupId: orgGroupId.toString(),
    teamsPlanId: plansData[subPlan]?.id,
    teamsPlanName: plansData[subPlan]?.name,
    webPage,
  });
}

export function trackTeamsDriveCommentCompleted({
  orgId,
  orgGroupId,
  subPlan,
  webPage,
}) {
  avo.track("teamsDriveCommentCompleted", {
    organizationId: orgId.toString(),
    organizationGroupId: orgGroupId.toString(),
    teamsPlanId: plansData[subPlan]?.id,
    teamsPlanName: plansData[subPlan]?.name,
    webPage,
  });
}

export function trackTeamsDriveCommentFailed({
  orgId,
  orgGroupId,
  subPlan,
  webPage,
}) {
  avo.track("teamsDriveCommentFailed", {
    organizationId: orgId.toString(),
    organizationGroupId: orgGroupId.toString(),
    teamsPlanId: plansData[subPlan]?.id,
    teamsPlanName: plansData[subPlan]?.name,
    webPage,
  });
}

// Teams Automatic Review
export function trackAutomaticReviewEnabled({
  userId,
  orgId,
  orgGroupId,
  subPlan,
  country,
  freeTrial,
}) {
  avo.track("automaticReviewEnabled", {
    userId_: userId,
    organizationId: orgId.toString(),
    organizationGroupId: orgGroupId.toString(),
    teamsPlanId: plansData[subPlan]?.id,
    teamsPlanName: plansData[subPlan]?.name,
    country,
    freeTrial,
    automaticReviewEnabled: true,
  });
}

export function trackAutomaticReviewDisabled({
  userId,
  orgId,
  orgGroupId,
  subPlan,
  country,
  freeTrial,
}) {
  avo.track("automaticReviewDisabled", {
    userId_: userId,
    organizationId: orgId.toString(),
    organizationGroupId: orgGroupId.toString(),
    teamsPlanId: plansData[subPlan]?.id,
    teamsPlanName: plansData[subPlan]?.name,
    country,
    freeTrial,
    automaticReviewEnabled: false,
  });
}
