import React from "react";
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
  useHistory,
} from "react-router-dom";

import LayersProvider from "src/lib/layers/LayersProvider";

import ApolloContextProvider, {
  isPublicRoute,
} from "src/components/context/ApolloContext";
import {
  USER_NETWORK_STATES,
  UserDataProvider,
  UserProvider,
  useUser,
} from "src/components/context/UserContext";

import Loader from "src/components/elements/Loader";

import DriverDashboardPages from "src/components/pages/DriverDashboard/DriverDashboardPages";
import TeamsDashboardPages from "src/components/pages/TeamsDashboard/TeamsDashboardPages";
import ZendeskSSOLogin from "src/components/pages/Zendesk/ZendeskSSOLogin";

import { EnvironmentProvider } from "src/hooks/useEnvironment";
import { FlagsProvider, useFlags } from "src/hooks/useFlags";
import useLoadGoogleMaps from "src/hooks/useLoadGoogleMaps";
import { usePageTracking } from "src/hooks/usePageTracking";
import useQueryParams from "src/hooks/useQueryParams";
import useTeams, { TEAMS_NETWORK_STATES } from "src/hooks/useTeams";

import { ChameleonProvider } from "src/vendors/chameleon";
import { ProfitwellProvider } from "src/vendors/profitwell";

import { ContactsProvider } from "./context/ContactsContext";
import { FlashProvider } from "./context/Flash";
import { NotificationsProvider } from "./context/NotificationsContext";
import { TeamCancellationProvider } from "./context/TeamCancellationContext";
import TeamContext from "./context/TeamContext";
import { TeamDunningProvider } from "./context/TeamDunningContext";
import { TeamLocationsProvider } from "./context/TeamLocationsContext";
import { AutoReviewProvider } from "./context/TeamsAutoReviewContext";
import { CommuteSettingsProvider } from "./context/TeamsCommuteSettingsContext";
import { ReportReminderProvider } from "./context/TeamsReportReminderContext";
import Page from "./elements/Page";
import { ForgotPasswordPage } from "./pages/Auth/ForgotPassword";
import { LoginPage } from "./pages/Auth/Login";
import { PartnerPage } from "./pages/Auth/Partner";
import QuickSignup from "./pages/Auth/QuickSignup";
import ContactLightweightDashboard from "./pages/ContactDashboard/ContactLightweightDashboard";
import EmailPreferences from "./pages/DriverDashboard/EmailPreferences";
import QRTargetPage from "./pages/DriverDashboard/QRTarget";
import WelcomeDownload from "./pages/DriverDashboard/WelcomeDownload";
import OnboardingPage from "./pages/Onboarding";
import VerifyEmail from "./pages/VerifyEmail";
import MobileCancelSubscription from "./pages/webview/MobileCancelSubscription";

export default function App() {
  return (
    <EnvironmentProvider>
      <FlagsProvider>
        <ProfitwellProvider>
          <FlashProvider>
            <ApolloContextProvider>
              <Router getUserConfirmation={() => {}}>
                <Switch>
                  <Route path="/public/unsubscribe/:userId">
                    <div className="w-screen min-h-screen flex bg-white">
                      <Page title="Email Preferences">
                        <EmailPreferences />
                      </Page>
                    </div>
                  </Route>
                  <Route path="/zendesk/sso">
                    <ZendeskSSOLogin />
                  </Route>
                  <Route path="/verify-email">
                    <VerifyEmail />
                  </Route>
                  <Route path="/webview/cancel-subscription-popup">
                    <Page title="Cancel Subscription">
                      <MobileCancelSubscription />
                    </Page>
                  </Route>
                  <Route path="/contact">
                    <ContactLightweightDashboard />
                  </Route>
                  <UserProvider>
                    <Pages />
                  </UserProvider>
                </Switch>
              </Router>
            </ApolloContextProvider>
          </FlashProvider>
        </ProfitwellProvider>
      </FlagsProvider>
    </EnvironmentProvider>
  );
}

function Pages() {
  usePageTracking();

  const history = useHistory();
  const searchParams = useQueryParams();
  const redirect = searchParams?.get?.("redirect") || "";
  if (redirect) {
    searchParams?.delete?.("redirect");

    if (searchParams?.get("accept-invite")) {
      searchParams?.set("goTo", "/user/teams");
    }

    history.push(`/${redirect}?${searchParams.toString()}`);
  }

  const { user, userNetworkState } = useUser();
  const { team, teamNetworkState, refreshTeam } = useTeams(user || {});

  const flags = useFlags();

  useLoadGoogleMaps();

  const isPrivateRoute = !isPublicRoute();
  const isUserDataPending = [
    USER_NETWORK_STATES.LOADING,
    USER_NETWORK_STATES.IDLE,
  ].includes(userNetworkState);
  const isTeamDataPending = [
    TEAMS_NETWORK_STATES.LOADING,
    TEAMS_NETWORK_STATES.IDLE,
  ].includes(teamNetworkState);

  if (
    isPrivateRoute &&
    (isUserDataPending || isTeamDataPending || !flags.ready)
  ) {
    return (
      <div className="w-screen h-screen">
        <Loader />
      </div>
    );
  }

  const isTeamAdmin = team?.isAdmin(user?.email);
  const isDriver = user?.isDriver;
  const isTeamAdminOnly = isTeamAdmin && !isDriver;

  return (
    <div className="w-screen max-w-full min-h-screen flex bg-white">
      <TeamContext.Provider value={{ team, refreshTeam }}>
        <Switch>
          <Route
            path="/login/resetPasswordWithToken*"
            render={({ location }) => (
              <Redirect to={`/forgot-password${location.search}`} />
            )}
          />
          <Route
            path="/login/resetPassword*"
            render={({ location }) => (
              <Redirect to={`/forgot-password${location.search}`} />
            )}
          />
          <Route
            path="/logout"
            render={({ location }) => (
              <Redirect to={`/login${location.search}`} />
            )}
          />
          <Route path="/signup">
            <Page title="MileIQ Sign Up" withoutPrefix={true}>
              {/* <SignupPage /> */}
              <QuickSignup />
            </Page>
          </Route>
          <Route path="/get-the-app">
            <Page title="Get the app" withoutPrefix={true}>
              <WelcomeDownload />
            </Page>
          </Route>
          <Route path="/qr">
            <Page title="QR Scanned" withoutPrefix={true}>
              <QRTargetPage />
            </Page>
          </Route>
          <Route path="/login">
            <Page title="Sign in to MileIQ dashboard" withoutPrefix={true}>
              <LoginPage />
            </Page>
          </Route>
          <Route path="/partner">
            <Page title="MileIQ Partner" withoutPrefix={true}>
              <PartnerPage />
            </Page>
          </Route>
          <Route path="/forgot-password">
            <Page title="MileIQ Forgot Password" withoutPrefix={true}>
              <ForgotPasswordPage />
            </Page>
          </Route>
          <UserDataProvider>
            <ChameleonProvider>
              <ContactsProvider>
                <NotificationsProvider>
                  <TeamLocationsProvider>
                    <TeamCancellationProvider>
                      <TeamDunningProvider>
                        <ReportReminderProvider>
                          <CommuteSettingsProvider>
                            <AutoReviewProvider>
                              <LayersProvider>
                                <Switch>
                                  <Route path="/onboarding">
                                    <Page
                                      title="MileIQ Onboaring"
                                      withoutPrefix={true}
                                    >
                                      <OnboardingPage />
                                    </Page>
                                  </Route>
                                  <Route path="/teams">
                                    <TeamsDashboardPages />
                                  </Route>
                                  <Route path="/user">
                                    <DriverDashboardPages />
                                  </Route>
                                  <Redirect
                                    to={isTeamAdminOnly ? "/teams" : "/user"}
                                  />
                                </Switch>
                              </LayersProvider>
                            </AutoReviewProvider>
                          </CommuteSettingsProvider>
                        </ReportReminderProvider>
                      </TeamDunningProvider>
                    </TeamCancellationProvider>
                  </TeamLocationsProvider>
                </NotificationsProvider>
              </ContactsProvider>
            </ChameleonProvider>
          </UserDataProvider>
        </Switch>
      </TeamContext.Provider>
    </div>
  );
}
