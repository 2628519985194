import React from "react";

import { registerElement } from "src/lib/layers/LayersProvider";

import Modal from "src/components/elements/Modal";

import ImgCarWink from "public/assets/img/car-wink.svg";

import Button from "../elements/Button";
import Text from "../elements/Text";

export const ELEMENT_ID = "WELCOME_TO_NEW_DASHBOARD_MODAL";
registerElement(ELEMENT_ID, WelcomeToNewDashboard);

export default WelcomeToNewDashboard;

function WelcomeToNewDashboard({ onClose }) {
  return (
    <Modal
      contentClassName="p-[0px]"
      closable={false}
      className="w-[520px] p-0"
      onClose={onClose}
    >
      <div className="p-4 pb-7 rounded-t-20 bg-[#C5E4FA] overflow-hidden">
        <Button
          secondary
          icon="close"
          onClick={onClose}
          className="ml-auto mix-blend-multiply"
        />
        <div className="ml-[-20px]">
          <ImgCarWink />
        </div>
      </div>
      <div className="p-7">
        <h4>Welcome to the new dashboard!</h4>
        <Text paragraph className="mt-3" color="black/70">
          We've worked really hard to make the dashboard more modern, fun, and
          easier to use — let us know what you think!
        </Text>
        <Button className="ml-auto mt-7" onClick={onClose}>
          Get started
        </Button>
      </div>
    </Modal>
  );
}
