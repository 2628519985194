import React from "react";

import { useUserData } from "src/components/context/UserContext";

import MobMenu from "src/components/elements/MobMenu";

import FreePlan from "src/components/blocks/subscription/driver/FreePlan";
import ManageSubscriptionControls from "src/components/blocks/subscription/driver/ManageSubscriptionControls";
import SubscriptionQuickTips from "src/components/blocks/subscription/driver/SubscriptionQuickTips";
import UnlimitedPlan from "src/components/blocks/subscription/driver/UnlimitedPlan";

import PageLayout from "src/components/PageLayout";

export default SubscriptionPage;

function SubscriptionPage() {
  const {
    userData: { isPremium, subscriptionData },
  } = useUserData();

  const renderSubpage = () => {
    const isFree = !isPremium || !subscriptionData.status;
    if (isFree) return <FreePlan />;

    return <UnlimitedPlan />;
  };

  return (
    <>
      <PageLayout className="page-driver-subscription">
        <PageLayout.Main className="flex flex-col">
          <div className="page-header relative">
            <div className="title-row flex items-center justify-between ">
              <div className="w-full flex items-center justify-between">
                <div className="flex items-center">
                  <MobMenu />
                  <h3>Subscription</h3>
                </div>
                {isPremium ? <ManageSubscriptionControls /> : null}
              </div>
            </div>
          </div>
          <div className="p-[20px] laptop:p-[15px]">
            <div className="w-[700px] laptop:w-[602px]">{renderSubpage()}</div>
          </div>
        </PageLayout.Main>
        <PageLayout.Sidebar>
          <SubscriptionQuickTips />
        </PageLayout.Sidebar>
      </PageLayout>
    </>
  );
}
