import { createContext, useContext } from "react";

const EnvironmentContext = createContext();

export const ENV = {
  APP_ENV: process.env.APP_ENV,

  // APIs ------------------------------------------------------
  GRAPHQL_API_URL: process.env.GRAPHQL_API_URL,
  TEAMS_API_URL: process.env.TEAMS_API_URL,
  REPORTS_API_URL: process.env.REPORTS_API_URL,
  API_URL: process.env.API_URL, // deprecated
  DASH_V1_URL: process.env.DASH_V1_URL, // deprecated

  // FLAG VARIABLES --------------------------------------------
  FLAGS: {
    LAUNCHDARKLY_ENABLED: process.env.LAUNCHDARKLY_ENABLED,
    BUGSNAG_ENABLED: process.env.BUGSNAG_ENABLED,
    MIXPANEL_ENABLED: process.env.MIXPANEL_ENABLED,
  },

  // 3RDPARTY KEYS ---------------------------------------------

  // LAUNCHDARKLY
  LAUNCHDARKLY_CLIENT_SIDE_ID: process.env.LAUNCHDARKLY_CLIENT_SIDE_ID,

  // BING
  BING_KEY: process.env.BING_KEY, // deprecated

  // BUGSNAG
  BUGSNAG_API_KEY_DASH_V2_WEB_APP: process.env.BUGSNAG_API_KEY_DASH_V2_WEB_APP,
  BUGSNAG_APP_VERSION: process.env.BUGSNAG_APP_VERSION,
  BUGSNAG_APP_RELEASE_STAGE: process.env.BUGSNAG_APP_RELEASE_STAGE,

  // MIXPANEL
  MIXPANEL_KEY: process.env.MIXPANEL_KEY,

  // BRAZE
  BRAZE_ENABLED: process.env.BRAZE_ENABLED,
  BRAZE_KEY: process.env.BRAZE_KEY,
  BRAZE_SDK_URL: process.env.BRAZE_SDK_URL,

  // FIREBASE
  FIREBASE_API_KEY: process.env.FIREBASE_API_KEY,
  FIREBASE_AUTH_DOMAIN: process.env.FIREBASE_AUTH_DOMAIN,
  FIREBASE_DATABASE_URL: process.env.FIREBASE_DATABASE_URL,
  FIREBASE_PROJECT_ID: process.env.FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET: process.env.FIREBASE_STORAGE_BUCKET,
  FIREBASE_MESSAGING_SENDER_ID: process.env.FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_APP_ID: process.env.FIREBASE_APP_ID,
  FIREBASE_MEASUREMENT_ID: process.env.FIREBASE_MEASUREMENT_ID,

  // GOOGLE MAPS
  GOOGLE_MAPS_KEY: process.env.GOOGLE_MAPS_KEY,
  GOOGLE_MAPS_ID: process.env.GOOGLE_MAPS_ID,
};

export const useEnvironment = () => {
  const context = useContext(EnvironmentContext);

  if (!context) {
    throw new Error("useEnvironment must be used within a EnvironmentProvider");
  }

  return context;
};

export const EnvironmentProvider = ({ children }) => {
  const value = ENV;

  return (
    <EnvironmentContext.Provider value={value}>
      {children}
    </EnvironmentContext.Provider>
  );
};
