import format from "date-fns/format";
import React, { useContext, useEffect, useState } from "react";

import TeamContext from "src/components/context/TeamContext";
import { useUserData } from "src/components/context/UserContext";

import Button from "src/components/elements/Button";
import Modal from "src/components/elements/Modal";
import Text from "src/components/elements/Text";

import {
  TEAMS_SUBSCRIPTION_PLANS_DATA,
  TEAM_SUBSCRIPTION_TYPES,
} from "src/models/team-subscription";

import {
  updateSubscription,
  updateSubscriptionPreview,
} from "src/services/billing";
import { setShowWelcomeSlides } from "src/services/storage";
import { trackTeamsUpgradeCompleted } from "src/services/tracking";
import { formatCurrency, plural, timeout } from "src/services/utils";

import FlyingCar from "public/assets/img/flying-car.svg";

export default UpgradeSubscriptionConfirm;

function UpgradeSubscriptionConfirm({
  currentSubPlan,
  newSubPlan,
  newSubType,
  onClose,
  onBack,
  onDone,
}) {
  const { team, refreshTeam } = useContext(TeamContext);
  const { userData, refreshSubscription } = useUserData();
  const [loading, setLoading] = useState(false);
  const [upgraded, setUpgraded] = useState(false);
  const [nextPaymentAt, setNextPaymentAt] = useState(null);
  const [proratedChargeToday, setProratedChargeToday] = useState(null);

  useEffect(() => {
    async function init() {
      setLoading(true);
      try {
        const res = await updateSubscriptionPreview(newSubPlan, newSubType);
        console.log(res);
        if (res) {
          setNextPaymentAt(res.next_payment_at || null);
          setProratedChargeToday(res.prorated_charge_today || null);
        }
      } catch (err) {
        console.log(err);
      }
      setLoading(false);
    }
    init();
  }, []);

  const handleUpgradeBtnClicked = async () => {
    setLoading(true);
    try {
      const prevSubId = userData.subscriptionType;
      await updateSubscription(newSubPlan, newSubType);
      await refreshTeam();

      // need to update sub data in user data context.
      // HACK: it may take some time to update subscription data,
      // because our BE waits for the updates callback from Stripe.
      let newUserData = await refreshSubscription();
      let maxRetries = 3;
      while (maxRetries > 0 && newUserData?.subscriptionType == prevSubId) {
        maxRetries--;
        await timeout(2000);
        newUserData = await refreshSubscription();
      }

      trackTeamsUpgradeCompleted({
        prevPlan: currentSubPlan,
        newPlan: newSubPlan,
        orgGroupId: team.orgGroupId,
        orgId: team.orgId,
        freeTrial: team.subscription?.isFreeTrialActive,
        driversCount: team.subscription?.numberOfSeats || 0,
      });

      setUpgraded(true);
      setLoading(false);

      setShowWelcomeSlides(currentSubPlan, newSubPlan);

      // show Subscription Upgraded msg for some time
      // and then call onDone which should dismiss the modal
      setTimeout(() => {
        if (typeof onDone === "function") {
          return onDone();
        }
      }, 2000);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const currentPlanLabel = TEAMS_SUBSCRIPTION_PLANS_DATA.labels[currentSubPlan];
  const newPlanLabel = TEAMS_SUBSCRIPTION_PLANS_DATA.labels[newSubPlan];

  const canDismiss = !loading && !upgraded;

  const numDrivers = team.subscription.numberOfSeats;
  const newPrice = TEAMS_SUBSCRIPTION_PLANS_DATA.prices[newSubPlan][newSubType];
  const newPeriod =
    newSubType === TEAM_SUBSCRIPTION_TYPES.MONTH ? "month" : "year";
  const formulaTxt = `${numDrivers} ${plural(
    numDrivers,
    "driver",
    "drivers"
  )} x $${newPrice} per ${newPeriod}`;

  return (
    <Modal
      onClose={canDismiss ? onClose : null}
      closable={canDismiss}
      onBack={canDismiss ? onBack : null}
      loading={loading}
      className="w-[500px]"
    >
      {upgraded ? (
        <div
          className="flex flex-col items-center justify-center"
          style={{ minHeight: "370px" }}
        >
          <h4 className="text-center mb-8">
            You have been upgraded
            <br />
            to {TEAMS_SUBSCRIPTION_PLANS_DATA.labels[newSubPlan]}!
          </h4>
          <FlyingCar />
        </div>
      ) : (
        <>
          <h3 className="text-center mb-4">Confirm upgrade</h3>
          <div className="flex items-center justify-center mb-8">
            <Text className="text-center">{`Upgrade from ${currentPlanLabel} to ${newPlanLabel}`}</Text>
          </div>
          <div className="py-3.5 mt-8 border-t border-border-1">
            <Text paragraph>{formulaTxt}</Text>
          </div>
          <div className="py-3.5 border-t border-border-1">
            <Text paragraph>
              Next billing date:{" "}
              {nextPaymentAt ? format(nextPaymentAt * 1000, "MMM d,yyyy") : "-"}
            </Text>
          </div>
          <div className="py-3.5 border-t border-border-1 flex justify-between">
            <Text bold>Prorated charge today</Text>
            <Text bold>
              {proratedChargeToday !== null
                ? formatCurrency({ value: proratedChargeToday / 100 })
                : "-"}
            </Text>
          </div>
          <div className="py-3.5 border-t border-border-1">
            <Text paragraph color="black/50">
              Prorated charge accounts for the amount you have already paid for
              your {currentPlanLabel} plan.
            </Text>
          </div>
          <Button lg className="w-full mt-8" onClick={handleUpgradeBtnClicked}>
            Upgrade to {newPlanLabel}
          </Button>
        </>
      )}
    </Modal>
  );
}
