import React, { useContext } from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

import TeamContext from "src/components/context/TeamContext";

import Page from "src/components/elements/Page";

import AutoClassificationPage from "src/components/pages/DriverDashboard/settings/auto-classification/AutoClassification";
import ContactsPage from "src/components/pages/DriverDashboard/settings/contacts/Contacts";
import CustomPurposesPage from "src/components/pages/DriverDashboard/settings/custom-purposes/CustomPurposes";
import IntegrationsPage from "src/components/pages/DriverDashboard/settings/integrations/Integrations";
import MileageRatesPage from "src/components/pages/DriverDashboard/settings/mileage-rates/MileageRates";
import NotificationsPage from "src/components/pages/DriverDashboard/settings/notifications/Notifications";
import ProfilePage from "src/components/pages/DriverDashboard/settings/profile/Profile";
import SharePage from "src/components/pages/DriverDashboard/settings/share/Share";
import VehicleAndOdometerPage from "src/components/pages/DriverDashboard/settings/vehicle-and-odometer/VehicleAndOdometer";

import { useFlags } from "src/hooks/useFlags";

import { SettingsSidenav } from "./SettingsSidenav";
import AutomaticReportsPage from "./automatic-reports/AutomaticReports";

export default SettingsPages;

function SettingsPages() {
  return (
    <div className="w-full h-full flex flex-col">
      <div className="w-full h-full flex bg-white">
        <SettingsRoutes />
      </div>
    </div>
  );
}

function SettingsRoutes() {
  const { path } = useRouteMatch();
  const {
    miqDashboardTeamsDriverAutomaticReportsWeb: isAutomaticReportsEnabled,
    miqDashboardReportsContactsWeb: isContactsEnabled,
  } = useFlags();
  const { team } = useContext(TeamContext);

  const isTeamsUser = !!team;

  return (
    <>
      <SettingsSidenav />
      <div className="w-full relative max-w-[810]">
        <Switch>
          <Route path={`${path}/profile`} exact>
            <Page title="Account Profile">
              <ProfilePage />
            </Page>
          </Route>
          <Route path={`${path}/notifications`} exact>
            <Page title="Email Notifications">
              <NotificationsPage />
            </Page>
          </Route>
          <Route path={`${path}/integrations`} exact>
            <Page title="Integrations">
              <IntegrationsPage />
            </Page>
          </Route>
          <Route path={`${path}/share`}>
            <Page title="Share">
              <SharePage />
            </Page>
          </Route>
          <Route path={`${path}/vehicle-and-odometer`}>
            <Page title="Vehicles and Odometer">
              <VehicleAndOdometerPage />
            </Page>
          </Route>
          <Route path={`${path}/mileage-rates`}>
            <Page title="Rates and Units">
              <MileageRatesPage />
            </Page>
          </Route>
          <Route path={`${path}/auto-classification`}>
            <Page title="Auto-Classification">
              <AutoClassificationPage />
            </Page>
          </Route>
          <Route path={`${path}/custom-purposes`}>
            <Page title="Custom Purposes">
              <CustomPurposesPage />
            </Page>
          </Route>
          <Route path={`${path}/contacts`}>
            <PageGuard
              isEnabled={isContactsEnabled && !isTeamsUser}
              redirectTo={"/user/settings/profile"}
            >
              <Page title="Contacts">
                <ContactsPage />
              </Page>
            </PageGuard>
          </Route>
          <Route path={`${path}/automatic-reports`}>
            <PageGuard
              isEnabled={isAutomaticReportsEnabled && isTeamsUser}
              redirectTo={"/user/settings/profile"}
            >
              <Page title="Automatic Reporting">
                <AutomaticReportsPage />
              </Page>
            </PageGuard>
          </Route>
          <Redirect to={`${path}/profile`} />
        </Switch>
      </div>
    </>
  );
}

function PageGuard({ isEnabled, redirectTo, children }) {
  return isEnabled ? children : <Redirect to={redirectTo} />;
}
