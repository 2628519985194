import { max, min } from "date-fns";
import React from "react";
import { useState } from "react";
import { useContext } from "react";

import { registerElement } from "src/lib/layers/LayersProvider";

import Button from "src/components/elements/Button";
import Icon from "src/components/elements/Icon";
import Modal from "src/components/elements/Modal";
import Text from "src/components/elements/Text";

import { useFlags } from "src/hooks/useFlags";

import {
  formatCurrency,
  getDriveValue,
  roundDistance,
  toUIDistance,
} from "src/services/utils";

import { UserDataContext } from "../context/UserContext";

export default DeleteDrivesModal;

export const ELEMENT_ID = "DELETE_DRIVES_MODAL";
registerElement(ELEMENT_ID, DeleteDrivesModal);

const DriveData = ({ name, date, dateFormatter }) => (
  <div className="leading-[21px] text-left flex flex-col break-all">
    <Text semibold>{name}</Text>
    <Text>{dateFormatter.format(new Date(date), "hh:mmaaa, MMM d")}</Text>
  </div>
);

const DriveDistanceAndAmount = ({ distance, value, currency, unit }) => (
  <>
    <Text>
      {toUIDistance(roundDistance(distance), unit)} {unit}
    </Text>{" "}
    <Text semibold>{`${formatCurrency({ value, currency })}`}</Text>
  </>
);

/**
 *
 * @param {{drives: Drive[] }}
 * @returns
 */
function DeleteDrivesModal({ drives = [], onClose, onDelete }) {
  const [loading, setLoading] = useState(false);
  const { userData } = useContext(UserDataContext);

  const deleteMutation = async () => {
    setLoading(true);
    await onDelete(drives);
    setLoading(false);

    onClose();
  };

  const getDriveDistance = () =>
    drives.reduce((acc, next) => acc + next.googleDistance, 0);

  const getDriveAmount = () =>
    drives.reduce(
      (acc, next) => acc + next.value + next.tollFees + next.parkingFees,
      0
    );

  const title =
    drives.length > 1
      ? `Delete these ${drives.length} drives?`
      : "Delete this drive?";

  const dateFormatter = drives[0].dateFormatter;

  const { miqDashboardDrivesDisplayFullAddressWeb: shouldDisplayFullAddress } =
    useFlags();

  return (
    <Modal
      closable
      show
      onClose={() => {
        if (!loading) onClose({ isCancel: true });
      }}
      className={`${
        drives.length <= 5 ? "delete-drives-modal" : "delete-huge-drives-modal"
      }`}
    >
      <div className="flex flex-col gap-[20px] laptop:gap-[15px]">
        <h4 className="text-center">{title}</h4>
        <section className="rounded border border-border-1">
          {drives.length <= 5 ? (
            drives.map((drive, i) => {
              return (
                <div key={drive.id}>
                  <div className="flex justify-between gap-4 p-4">
                    <div className="flex gap-4 w-2/6">
                      <Icon name="car" />
                      <DriveData
                        name={
                          shouldDisplayFullAddress
                            ? drive.startLocation.fullAddress
                            : drive.startLocation.displayName
                        }
                        date={drive.startDate}
                        dateFormatter={drive.dateFormatter}
                      />
                    </div>
                    <div className="w-2/6">
                      <div className="flex gap-4">
                        <Icon
                          name="large-arrow-right"
                          className="items-start mt-1"
                        />
                        <DriveData
                          name={
                            shouldDisplayFullAddress
                              ? drive.startLocation.fullAddress
                              : drive.startLocation.displayName
                          }
                          date={drive.endDate}
                          dateFormatter={drive.dateFormatter}
                        />
                      </div>
                    </div>
                    <div className="w-2/6 flex justify-end gap-[20px]">
                      <DriveDistanceAndAmount
                        unit={userData.distanceUnit}
                        currency={userData.currency}
                        distance={drive.googleDistance}
                        value={getDriveValue(drive)}
                      />
                    </div>
                  </div>
                  {drives.length - 1 !== i && <div className="divider" />}
                </div>
              );
            })
          ) : (
            <div className="flex justify-between p-4 pl-6">
              <div className="flex">
                <div className="leading-[21px] text-left flex gap-2">
                  <Icon name="multiple-drives" />
                  <div className="flex flex-col">
                    <Text semibold>{drives.length} drives</Text>
                    <Text>
                      {dateFormatter.format(
                        min(drives.map((drive) => new Date(drive.startDate))),
                        "MMM d"
                      )}
                      {" — "}
                      {dateFormatter.format(
                        max(drives.map((drive) => new Date(drive.endDate))),
                        "MMM d"
                      )}
                    </Text>
                  </div>
                </div>
              </div>
              <div className="flex text-right gap-4">
                <DriveDistanceAndAmount
                  distance={getDriveDistance()}
                  value={getDriveAmount()}
                />
              </div>
            </div>
          )}
        </section>
        <div className="flex justify-end gap-2">
          <Button
            className="font-medium"
            secondary
            onClick={() => onClose({ isCancel: true })}
            disabled={loading}
          >
            Cancel
          </Button>
          <Button
            loading={loading}
            className="font-medium"
            destructive
            onClick={deleteMutation}
          >
            Delete
          </Button>
        </div>
      </div>
    </Modal>
  );
}
