import { format, intervalToDuration } from "date-fns";

import { useFlags } from "src/hooks/useFlags";

const BASE_URL_MARKER_LOCATION = "https://map-assets.mileiq.com/markers";

const Markers = {
  GREEN: `${BASE_URL_MARKER_LOCATION}/start-location-ios-3x.png`,
  RED: `${BASE_URL_MARKER_LOCATION}/end-location-ios-3x.png`,
  BLUE: `${BASE_URL_MARKER_LOCATION}/stop-location-ios-3x.png`,
  ROUND_TRIP: `${BASE_URL_MARKER_LOCATION}/combined-start-end-1@@.png`,
};

const useMapUtilities = ({
  startLocation,
  endLocation,
  stopLocation,
  withRoutes,
  infoWindows,
  startDate,
  endDate,
  stopDate,
  stopDuration,
  centerOnMarkerClick,
}) => {
  let infoDetails = null;
  const markers = [];

  if (withRoutes && infoWindows) {
    infoDetails = {};
    if (startDate) {
      infoDetails.start = `Start: ${format(startDate, "hh:mm a")}`;
    }
    if (endDate) {
      infoDetails.end = `End: ${format(endDate, "hh:mm a")}`;
    }
    if (stopDate) {
      infoDetails.stop = `Stop: ${format(stopDate, "hh:mm a")}`;
      const duration = intervalToDuration({
        start: 0,
        end: stopDuration * 1000,
      });

      infoDetails.stopDuration = `Stop duration: ${duration.hours}H ${duration.minutes} MIN`;
    }
  }

  const drawRoutes = (polylines, map, bounds) => {
    const lineSymbol = {
      path: "M 0,-1 0,1",
      strokeOpacity: 1,
      strokeWeight: 5,
      scale: 3,
    };
    const icons = [
      [
        {
          icon: lineSymbol,
          offset: "0",
          repeat: "6px",
        },
      ],
      [
        {
          icon: lineSymbol,
          offset: "50%",
          repeat: "15px",
        },
      ],
    ];
    const linePolyline = icons[0];
    // const dashedPolyline = icons[1];

    const coordinates = [];
    polylines.forEach((p) => {
      const decodedPath = window.google.maps.geometry.encoding.decodePath(p);
      decodedPath.forEach((l) => {
        coordinates.push({
          lat: l.lat(),
          lng: l.lng(),
        });
      });
    });

    const BLUE_STROKE = "#206DD0";
    for (let i = 0; i < coordinates.length - 1; i++) {
      new window.google.maps.Polyline({
        path: [coordinates[i], coordinates[i + 1]],
        strokeOpacity: 0,
        icons: linePolyline,
        strokeColor: BLUE_STROKE,
        map: map,
      });
      const latLng = new window.google.maps.LatLng(
        coordinates[i],
        coordinates[i + 1]
      );
      bounds.extend(latLng);
    }

    fitMapBounds(map, bounds);
  };

  const fitMapBounds = (map, bounds, gap = 100) => {
    map.fitBounds(bounds, gap);
  };

  const addMarker = (map, latitude, longitude, info, marker) => {
    const icon = {
      url: marker,
      scaledSize: new window.google.maps.Size(50, 50),
    };
    const latLng = new window.google.maps.LatLng(latitude, longitude);
    const googleMarker = new window.google.maps.Marker({
      position: latLng,
      icon,
      map,
    });

    window.google.maps.event.addListener(googleMarker, "click", () => {
      if (withRoutes && infoWindows) {
        createInfoWindow(map, googleMarker, info);
      }
      if (centerOnMarkerClick) {
        map.setCenter(latLng);
      }
    });

    return { latLng, googleMarker };
  };

  const { miqDashboardDrivesDisplayFullAddressWeb: shouldDisplayFullAddress } =
    useFlags();

  const createInfoWindow = (map, marker, info) => {
    const getInfoContent = (info) => {
      let content = "";
      info?.detail?.forEach((detail) => (content += `<p>${detail}</p>`));
      return `
                <div class='info-window'>
                  <p class='title'>${info.displayLocation}</>
                  ${content}
                </div>
            `;
    };

    const infoWindow = new window.google.maps.InfoWindow({
      content: getInfoContent(info),
    });
    infoWindow.open({
      anchor: marker,
      map,
    });
  };

  const getLocationNameToDisplay = (location) => {
    let locationNameToDisplay = shouldDisplayFullAddress
      ? location.fullAddress
      : location.displayName;

    if (location.isNamed) {
      locationNameToDisplay = location.displayName;
    }

    return locationNameToDisplay;
  };

  const addStartMarker = (map) => {
    const { latitude: startLatitude, longitude: startLongitude } =
      startLocation;

    const locationNameToDisplay = getLocationNameToDisplay(startLocation);

    markers.push(
      addMarker(
        map,
        startLatitude,
        startLongitude,
        withRoutes && infoWindows
          ? {
              displayLocation: locationNameToDisplay,
              detail: [infoDetails.start],
            }
          : null,
        Markers.GREEN
      )
    );
  };

  const addEndMarker = (map) => {
    const { latitude: endLatitude, longitude: endLongitude } = endLocation;

    const locationNameToDisplay = getLocationNameToDisplay(endLocation);

    markers.push(
      addMarker(
        map,
        endLatitude,
        endLongitude,
        withRoutes && infoWindows
          ? {
              displayLocation: locationNameToDisplay,
              detail: [infoDetails.end],
            }
          : null,
        Markers.RED
      )
    );
  };

  const addStopMarker = (map) => {
    if (stopLocation) {
      const { latitude: stopLatitude, longitude: stopLongitude } = stopLocation;

      const locationNameToDisplay = getLocationNameToDisplay(stopLocation);

      markers.push(
        addMarker(
          map,
          stopLatitude,
          stopLongitude,
          infoWindows
            ? {
                displayLocation: locationNameToDisplay,
                detail: [infoDetails.stop, infoDetails.stopDuration],
              }
            : null,
          Markers.BLUE
        )
      );
    }
  };

  const addRoundTripMarkers = (map) => {
    const { latitude: startLatitude, longitude: startLongitude } =
      startLocation;

    const locationNameToDisplay = getLocationNameToDisplay(startLocation);

    markers.push(
      addMarker(
        map,
        startLatitude,
        startLongitude,
        withRoutes && infoWindows
          ? {
              displayLocation: locationNameToDisplay,
              detail: [infoDetails.start, infoDetails.end],
            }
          : null,
        Markers.ROUND_TRIP
      )
    );

    addStopMarker(map);
  };

  return {
    addStartMarker,
    fitMapBounds,
    addEndMarker,
    addStopMarker,
    addRoundTripMarkers,
    drawRoutes,
    markers,
  };
};

export default useMapUtilities;
