import "src/models/typings";

import { useMutation, useQuery } from "@apollo/client";
import endOfDay from "date-fns/endOfDay";
import endOfMonth from "date-fns/endOfMonth";
import format from "date-fns/format";
import startOfDay from "date-fns/startOfDay";
import startOfMonth from "date-fns/startOfMonth";
import subDays from "date-fns/subDays";
import React, { useContext, useEffect, useState } from "react";

import useElement from "src/lib/layers/useElement";

import DrivesContext from "src/components/context/DrivesContext";
import { UserDataContext } from "src/components/context/UserContext";

import Button from "src/components/elements/Button";
import { FlashTypes } from "src/components/elements/Flash";
import Icon from "src/components/elements/Icon";
import MobMenu from "src/components/elements/MobMenu";
import Text from "src/components/elements/Text";

import DateRangePicker, {
  PICKER_TYPE,
} from "src/components/blocks/dates/DateRangePicker";
import DrivesList from "src/components/blocks/drives/DrivesList";
import Search from "src/components/blocks/drives/Search";
import { TABS } from "src/components/blocks/drives/Tabs";
import DriveDetailsSidebar from "src/components/blocks/drives/details/DriveDetailsSidebar";

import { ELEMENT_ID as ADD_DRIVE_MODAL_ID } from "src/components/modals/AddDrive";
import { ELEMENT_ID as AUTOCLASSIFY_MODAL_ID } from "src/components/modals/AutoClassifyPrompt";
import { ELEMENT_ID as DELETE_DRIVES_MODAL_ID } from "src/components/modals/DeleteDrives";
import { ELEMENT_ID as FILTER_DRIVES_MODAL_ID } from "src/components/modals/DrivesFilters";
import { ELEMENT_ID as DUPLICATE_DRIVE_MODAL_ID } from "src/components/modals/DuplicateDrive";
import { ELEMENT_ID as JOIN_DRIVE_MODAL_ID } from "src/components/modals/JoinDrives";

import PageLayout from "src/components/PageLayout";

import { useFlags } from "src/hooks/useFlags";
import useFlash from "src/hooks/useFlash";

import Drive from "src/models/drive";
import Purpose from "src/models/purpose";

import { getRefetchParams } from "src/services/apollo-utils";
import {
  UserAction,
  trackActionUndone,
  trackDriveAddCompleted,
  trackDriveAddStarted,
  trackDriveDeleteCompleted,
  trackDriveDeleteStarted,
  trackDriveDuplicateCompleted,
  trackDriveDuplicateStarted,
  trackDriveJoinCompleted,
  trackDriveJoinStarted,
  trackDrivesAdvanceFiltered,
  trackDrivesFiltered,
  trackFrequentDrivesPrompted,
} from "src/services/tracking";
import {
  formatCurrency,
  plural,
  timeout,
  toUIDistance,
} from "src/services/utils";

import {
  DELETE_DRIVES_MUTATION,
  EDIT_DRIVES_MUTATION,
  JOIN_DRIVES,
  UNJOIN_DRIVE,
} from "src/graphql/mutations";
import { GET_DRIVES_SUMMARY_QUERY } from "src/graphql/queries";

export default DrivesPage;

const defaultFilters = {
  dateRange: {
    startDate: startOfMonth(new Date()),
    endDate: endOfMonth(new Date()),
  },
  other: [],
  purposes: [],
  vehicles: [],
  tab: TABS.ALL,
  showReported: true,
  search: null,
};

const defaultFiltersWithEndDateCapped = {
  ...defaultFilters,
  dateRange: { ...defaultFilters.dateRange, endDate: new Date() },
};

const SHOW_UNDO_MS = 15000;

function DrivesPage() {
  const { userData } = useContext(UserDataContext);

  const {
    miqDashboardDrivesDisplayFullAddressWeb: shouldDisplayFullAddress,
    miqExcludeFutureDrivesAllPlatforms: shouldExcludeFutureDrives,
  } = useFlags();

  const [filters, setFilters] = useState(
    shouldExcludeFutureDrives ? defaultFiltersWithEndDateCapped : defaultFilters
  );
  const addDriveModal = useElement(ADD_DRIVE_MODAL_ID, {
    props: {
      onClose: () => addDriveModal.deactivate(),
      onSubmit: (drive) => {
        addDriveModal.deactivate();
        trackDriveAddCompleted({ category: drive.purpose.category });
        flash(
          <Text>{`Your ${format(drive.startDate, "MMM d")} drive to ${
            drive.endLocation.displayName
          } was added`}</Text>,
          {
            type: FlashTypes.SAVED,
          }
        );
        setTimeout(() => checkShouldAutoclassify(drive), 500);
      },
    },
  });
  const duplicateDriveModal = useElement(DUPLICATE_DRIVE_MODAL_ID, {
    props: {
      onClose: () => duplicateDriveModal.deactivate(),
      onSubmit: () => {
        duplicateDriveModal.deactivate();
        trackDriveDuplicateCompleted();
        flash(<Text>Drive duplicated</Text>, {
          type: FlashTypes.SAVED,
        });
      },
    },
  });
  const delDriveModal = useElement(DELETE_DRIVES_MODAL_ID, {
    props: { onClose: () => delDriveModal.deactivate() },
  });
  const joinDriveModal = useElement(JOIN_DRIVE_MODAL_ID, {
    props: { onClose: () => joinDriveModal.deactivate() },
  });
  const filterDrivesModal = useElement(FILTER_DRIVES_MODAL_ID, {
    props: {
      onClose: () => filterDrivesModal.deactivate(),
      onSubmit: (newFilters) => {
        setFilters((filters) => {
          const f = { ...filters, ...newFilters };
          trackDrivesFiltered({ filters: f });
          trackDrivesAdvanceFiltered({
            filters: f,
            userPurposes: userData.purposes.map((p) => new Purpose(p)),
          });
          return f;
        });
        filterDrivesModal.deactivate();
      },
    },
  });

  const autoclassifyModal = useElement(AUTOCLASSIFY_MODAL_ID, {
    props: {
      onClose: ({ tracking: { promptAction, shouldIncludeUnclassidied } }) => {
        trackFrequentDrivesPrompted({
          promptAction,
          shouldIncludeUnclassidied,
        });
        autoclassifyModal.deactivate();
      },
    },
  });

  const [selectedDrives, setSelectedDrives] = useState([]);
  const [flash, Flash] = useFlash();

  const [deleteDrivesMutation, { loading: deletedDriveLoading }] = useMutation(
    DELETE_DRIVES_MUTATION,
    {
      notifyOnNetworkStatusChange: true,
      refetchQueries: ["getUserDrives", "getDrivesTotals", "getDrivesSummary"],
      onQueryUpdated: (q) => {
        q.refetch(getRefetchParams(q));
      },
    }
  );

  const [editDrivesMutFn] = useMutation(EDIT_DRIVES_MUTATION, {
    notifyOnNetworkStatusChange: true,
    refetchQueries: ["getUserDrives", "getDrivesTotals", "getDrivesSummary"],
    onQueryUpdated: (q) => {
      q.refetch(getRefetchParams(q));
    },
  });

  const [joinDrivesMutFn] = useMutation(JOIN_DRIVES, {
    notifyOnNetworkStatusChange: true,
    refetchQueries: ["getUserDrives", "getDrivesTotals", "getDrivesSummary"],
    onQueryUpdated: (q) => {
      q.refetch(getRefetchParams(q));
    },
  });

  const [unjoinDriveMutFn] = useMutation(UNJOIN_DRIVE, {
    notifyOnNetworkStatusChange: true,
    refetchQueries: ["getUserDrives", "getDrivesTotals", "getDrivesSummary"],
    onQueryUpdated: (q) => {
      q.refetch(getRefetchParams(q));
    },
  });

  const checkShouldAutoclassify = (drive) => {
    // check if common routes feature is enabled
    if (!userData.commonRoutesEnabled) return;

    // check if user disabled Frequent Drives in Settings
    if (userData.commonRoutesOptOut) return;

    if (drive.routeShouldAutoClassify) return;

    if (
      drive &&
      drive.routeClassificationStreak >= drive.routeClassificationsToPrompt
    ) {
      autoclassifyModal.activate({
        props: {
          drive: drive,
        },
      });
    }
  };

  /**
   *
   * @param {Drive} drive
   * @param {Purpose} purpose
   */
  const notifyPurposeChangeSuccess = (drive, purpose) => {
    flash(
      <Text>
        {`${drive.startLocation.getLabel(shouldDisplayFullAddress)} 
          to 
          ${drive.endLocation.getLabel(shouldDisplayFullAddress)} 
          classified as 
          ${purpose.label}`}
      </Text>,
      {
        type: FlashTypes.SAVED,
      }
    );
  };

  /**
   *
   * @param {Drive} drive
   * @param {Purpose} purpose
   */
  const handlePurposeSelected = async (drive, purpose) => {
    try {
      const res = await editDrivesMutFn({
        variables: {
          editDrivesInput: [
            {
              objectId: drive.id,
              category: purpose.category,
              purpose: purpose.id,
            },
          ],
        },
      });

      if (drive.isRoundTripStop) {
        await editDrivesMutFn({
          variables: {
            editDrivesInput: [
              {
                objectId: drive.secondId,
                category: purpose.category,
                purpose: purpose.id,
              },
            ],
          },
        });
      }

      const d = res?.data?.editDrives[0];
      checkShouldAutoclassify(new Drive(d));

      notifyPurposeChangeSuccess(drive, purpose);
    } catch (err) {
      console.log(err);
    }
  };

  /**
   *
   * @param {[Drive]} drives
   * @param {Purpose} purpose
   */
  const handlePurposeSelectedFromDriveDetails = async (
    drives = [],
    purpose
  ) => {
    if (!drives || drives.length === 0) return;
    if (drives.length > 1) {
      flash(<Text>{`${drives.length} drives classified`}</Text>, {
        type: FlashTypes.SAVED,
      });
    } else {
      const drive = drives[0];
      checkShouldAutoclassify(drive);

      notifyPurposeChangeSuccess(drive, purpose);
    }
  };

  const handleAddDriveClicked = () => {
    trackDriveAddStarted();
    addDriveModal.activate();
  };

  const handleCloseDriveDetailsSidebar = () => {
    setSelectedDrives([]);
  };

  const onConfirmDelete = async (drives, { page }) => {
    let objectIds = [];
    drives.forEach((drive) => {
      if (drive.isRoundTripStop) {
        objectIds = objectIds.concat([
          { objectId: drive.id },
          { objectId: drive.secondId },
        ]);
      } else {
        objectIds.push({
          objectId: drive.id,
        });
      }
    });

    const undoDrivesData = drives.map((d) => ({
      state: d.state,
      objectId: d.id,
    }));

    await deleteDrivesMutation({
      variables: {
        deleteDrivesInput: objectIds,
      },
    });

    trackDriveDeleteCompleted({
      page,
      count: drives.length,
    });

    const undo = async () => {
      try {
        await editDrivesMutFn({
          variables: {
            editDrivesInput: undoDrivesData,
          },
        });
        trackActionUndone({
          userAction: UserAction.DELETE_DRIVE,
          subscriptionId: userData.subscriptionType,
        });
        await timeout(1000); // hack: need to wait until drives list reloaded to update selection
        setSelectedDrives(drives);
        flash(<Text>Your last action was undone</Text>, {
          type: FlashTypes.SAVED,
        });
      } catch (err) {
        flash(<Text>Failed to undo your last action</Text>, {
          type: FlashTypes.ERROR,
        });
      }
    };

    flash(
      <div className="flex items-center gap-[15px]">
        <Text>{`${plural(drives.length, "Drive", "Drives")} deleted`}</Text>
        <Button onClick={undo}>Undo</Button>
      </div>,
      {
        type: FlashTypes.SAVED,
        hideAfterMs: SHOW_UNDO_MS,
      }
    );
  };

  /**
   *
   * @param {[Drive]|Drive} drives
   */
  // used to delete drive from list or from single/multiple view
  const handleDeleteDrives = (drives, { page }) => {
    const drivesToDelete = [].concat(drives);

    trackDriveDeleteStarted({
      page,
      count: drivesToDelete.length,
    });

    delDriveModal.activate({
      props: {
        drives: drivesToDelete,
        onClose: (props) => {
          delDriveModal.deactivate();
          if (!props?.isCancel) {
            setSelectedDrives([]);
          }
        },
        onDelete: (drives) => onConfirmDelete(drives, { page }),
      },
    });
  };

  /**
   *
   * @param {Drive[]} drives
   */
  const onJoin = async (drives) => {
    const driveIds = drives.map((drive) => drive.id);

    const res = await joinDrivesMutFn({
      variables: {
        driveIdsInput: {
          driveIds,
        },
      },
    });

    const newDriveId = res?.data?.joinDrives?.id;

    trackDriveJoinCompleted();

    const undo = async () => {
      try {
        await unjoinDriveMutFn({
          variables: {
            driveId: newDriveId,
          },
        });
        trackActionUndone({
          userAction: UserAction.JOIN_DRIVE,
          subscriptionId: userData.subscriptionType,
        });
        await timeout(1000); // hack: need to wait until drives list reloaded to update selection
        setSelectedDrives(drives);
        flash(<Text>Your last action was undone</Text>, {
          type: FlashTypes.SAVED,
        });
      } catch (err) {
        flash(<Text>Failed to undo your last action</Text>, {
          type: FlashTypes.ERROR,
        });
      }
    };
    flash(
      <div className="flex items-center gap-[20px]">
        <Text>Drives joined</Text>
        <Button onClick={undo}>Undo</Button>
      </div>,
      {
        type: FlashTypes.SAVED,
        hideAfterMs: SHOW_UNDO_MS,
      }
    );
  };

  /**
   *
   * @param {Drive[]} drives
   */
  const handleJoinDrives = (drives) => {
    trackDriveJoinStarted();
    joinDriveModal.activate({
      props: {
        drives,
        onClose: (props) => {
          joinDriveModal.deactivate();
          if (!props?.isCancel) setSelectedDrives([]);
        },
        onJoin,
      },
    });
  };

  /**
   *
   * @param {Drive} drive
   */
  const handleDuplicateDrive = (drive) => {
    trackDriveDuplicateStarted();
    duplicateDriveModal.activate({
      props: {
        drive,
      },
    });
  };

  const handleFilterBtnClicked = () => {
    filterDrivesModal.activate({
      props: {
        selectedFilters: filters,
      },
    });
  };

  const handleClearFilters = () => {
    setFilters({ ...filters, other: [], purposes: [], vehicles: [] });
  };

  const handleSelectSearchSuggestion = (suggestion) => {
    console.log("suggestions selected: ", suggestion);
    const now = new Date();
    setFilters({
      ...filters,
      search: suggestion
        ? {
            byNotes: suggestion.isNotes,
            byAnyMatch: suggestion.isAll,
            byLocation: !suggestion.isNotes && !suggestion.isAll,
            locationId: suggestion.locationId,
            query: suggestion.query,
            city: suggestion.city,
            hood: suggestion.hood,
            state: suggestion.state,
            country: suggestion.country,
          }
        : null,
      dateRange: suggestion
        ? {
            startDate: startOfDay(subDays(now, 365)),
            endDate: endOfDay(now),
          }
        : { ...defaultFilters.dateRange },
    });
  };

  return (
    <>
      <DrivesContext.Provider
        value={{
          onDelete: handleDeleteDrives,
          onJoin: handleJoinDrives,
          onDuplicate: handleDuplicateDrive,
          onPurposeSelectedFromList: handlePurposeSelected,
          onPurposeSelectedFromDriveDetails:
            handlePurposeSelectedFromDriveDetails,
          selectedDrives,
          updateSelectedDrives: (drives, opts) => {
            const newSelection = drives
              .map((d) => {
                if (d instanceof Drive) return d;
                return new Drive(d);
              })
              .filter((d) => {
                if (opts?.excludeReported) {
                  return !d.isReported;
                }
                return d;
              });
            setSelectedDrives(newSelection);
          },
        }}
      >
        <PageLayout className="page-user-drives">
          <PageLayout.Main className="flex flex-col">
            <div className="page-header relative">
              <div className="title-row flex items-center justify-between ">
                <div className="flex items-center">
                  <MobMenu />
                  <Search onSelectSuggestion={handleSelectSearchSuggestion} />
                </div>
                <Button icon="plus" onClick={handleAddDriveClicked}>
                  Add a drive
                </Button>
              </div>
              <div className="flex justify-between items-center mt-[15px]">
                <div className="flex items-center justify-start gap-[10px] laptop:gap-[7px]">
                  <DateRangePicker
                    defaultPickerType={PICKER_TYPE.MONTH}
                    onSelect={(dates, dateType, rangeType) => {
                      const now = new Date();
                      let [startDate, endDate] = dates;

                      if (shouldExcludeFutureDrives && endDate > now) {
                        endDate = now;
                      }

                      const dateRange = {
                        startDate,
                        endDate,
                        type: dateType === "range" ? rangeType : dateType,
                      };

                      setFilters((f) => {
                        const newFilters = { ...f, dateRange };
                        trackDrivesFiltered({ filters: newFilters });
                        return newFilters;
                      });
                    }}
                    initialValue={[
                      defaultFilters.dateRange.startDate,
                      defaultFilters.dateRange.endDate,
                    ]}
                    onClear={() => {
                      const now = new Date();
                      const dateRange = { ...defaultFilters.dateRange };

                      if (
                        shouldExcludeFutureDrives &&
                        dateRange.endDate > now
                      ) {
                        dateRange.endDate = now;
                      }

                      setFilters({ ...filters, dateRange });
                    }}
                  />
                  <DrivesFilterBtn
                    filters={filters}
                    onClick={handleFilterBtnClicked}
                    onClear={handleClearFilters}
                  />
                </div>
                {!deletedDriveLoading && <DrivesSummary filters={filters} />}
              </div>
            </div>
            <div className="flex-grow">
              <DrivesList filters={filters} />
            </div>
          </PageLayout.Main>
          {selectedDrives.length > 0 ? (
            <PageLayout.Sidebar
              asOverlay
              withShadow
              className="w-[320px] laptop:w-[260px]"
            >
              <DriveDetailsSidebar
                drives={selectedDrives}
                onClose={handleCloseDriveDetailsSidebar}
              />
            </PageLayout.Sidebar>
          ) : null}
        </PageLayout>
        {Flash}
      </DrivesContext.Provider>
    </>
  );
}

function DrivesSummary({ filters }) {
  const { data, loading, refetch } = useQuery(GET_DRIVES_SUMMARY_QUERY, {
    variables: {
      startDate: filters.dateRange.startDate,
      endDate: filters.dateRange.endDate,
      userMetric: true,
    },
  });
  const { userData } = useContext(UserDataContext);

  useEffect(() => {
    const { startDate, endDate } = filters.dateRange;
    refetch({ startDate, endDate });
  }, [filters.dateRange.startDate, filters.dateRange.endDate]);

  const { distance, unclassifiedValue, personalValue, businessValue } =
    data?.drivesSummary || {};

  const distanceFormatted = loading
    ? "... "
    : `${Math.round(toUIDistance(distance, userData.distanceUnit))} ${
        userData.distanceUnit
      }`;
  const unclassifiedFormatted = loading
    ? "... "
    : formatCurrency({
        value: unclassifiedValue,
        currency: userData.currency,
      });
  const businessFormatted = loading
    ? "... "
    : formatCurrency({ value: businessValue, currency: userData.currency });
  const personalFormatted = loading
    ? "... "
    : formatCurrency({ value: personalValue, currency: userData.currency });

  return (
    <div className="flex items-center gap-[40px]">
      <div className="flex flex-col items-end leading-[21px]">
        <Text bold>{distanceFormatted}</Text>
        <Text md color="black/70">
          Distance
        </Text>
      </div>
      <div className="flex flex-col items-end leading-[21px]">
        <Text bold>{unclassifiedFormatted}</Text>
        <Text md color="black/70">
          Unclassified
        </Text>
      </div>
      <div className="flex flex-col items-end leading-[21px]">
        <Text bold color="yellow">
          {personalFormatted}
        </Text>
        <Text md color="black/70">
          Personal
        </Text>
      </div>
      <div className="flex flex-col items-end leading-[21px]">
        <Text bold color="blue">
          {businessFormatted}
        </Text>
        <Text md color="black/70">
          Business
        </Text>
      </div>
    </div>
  );
}

function DrivesFilterBtn({ filters, onClick, onClear }) {
  const handleClearClicked = (e) => {
    e.stopPropagation();
    onClear();
  };

  const numSelected =
    (filters?.other?.length || 0) +
    (filters?.purposes?.length || 0) +
    (filters?.vehicles?.length || 0);

  return (
    <button
      className={`flex items-center rounded-14-12 h-40-35 px-4 ${
        numSelected > 0 ? "bg-blue-mediumLight" : "bg-white"
      } `}
      onClick={onClick}
    >
      <Icon name="sliders-horizontal" />
      <Text bold className="ml-2">
        Filters
      </Text>
      {numSelected > 0 && (
        <>
          <Text className="ml-2">{numSelected}</Text>
          <span className="ml-2 cursor-pointer" onClick={handleClearClicked}>
            <Icon name="close" className="scale-90" />
          </span>
        </>
      )}
    </button>
  );
}
