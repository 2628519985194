import React, { useEffect, useRef, useState } from "react";

import Button from "src/components/elements/Button";
import Icon from "src/components/elements/Icon";
import Modal from "src/components/elements/Modal";
import Text from "src/components/elements/Text";
import Tooltip from "src/components/elements/Tooltip";

import { useFlags } from "src/hooks/useFlags";

import {
  TEAMS_SUBSCRIPTION_PLANS,
  TEAMS_SUBSCRIPTION_PLANS_DATA,
  TEAM_SUBSCRIPTION_TYPES,
} from "src/models/team-subscription";

import {
  braze,
  teamsUpgradeSources,
  trackFeatureDetailSelected,
  trackTeamsUpgradePlanSelected,
  trackTeamsUpgradeStarted,
} from "src/services/tracking";
import { formatCurrency } from "src/services/utils";

import BadgeTeamsLite from "public/assets/img/badge-sub-lite.svg";
import BadgeTeamsPro from "public/assets/img/badge-sub-pro.svg";

export default SkuPicker;

function SkuPicker({
  team,
  type,
  onSubmit,
  onBack,
  checkoutInProgress = false,
}) {
  const [modal, setModal] = useState(null);
  const [newType, setNewType] = useState(type || TEAM_SUBSCRIPTION_TYPES.MONTH);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const { miqDashOnboardingDisableInviteUserStepWeb: disableInviteUserStep } =
    useFlags();

  const handleBackClicked = () => {
    onBack();
  };

  const handleChooseSub = (newPlan) => {
    if (!disableInviteUserStep) {
      trackTeamsUpgradePlanSelected({ newPlan });
    }

    setSelectedPlan(newPlan);

    onSubmit({ plan: newPlan, type: newType });
  };

  useEffect(() => {
    if (!disableInviteUserStep && team) {
      trackTeamsUpgradeStarted({
        src: teamsUpgradeSources.ONBOARDING,
        orgId: team.orgId,
        orgGroupId: team.orgGroupId,
      });
    }
  }, [team]);

  useEffect(() => {
    braze.logCustomEvent("Pricing Page Viewed");
  }, []);

  return (
    <div className="step-details min-h-[100vh] flex justify-center min-w-full">
      <div className="flex flex-col items-center px-3">
        <div className="my-[25px] w-full flex items-center justify-between">
          <Button
            text
            icon="arrow-right"
            iconClassName="transform rotate-180"
            color="black"
            className=""
            onClick={handleBackClicked}
            disabled={checkoutInProgress}
          >
            Back
          </Button>
        </div>
        <h2 className="text-center">There's a plan for every size and need!</h2>
        <p className="text-center mt-3 text-black/50">
          Choose the features that make sense for your company. All plans
          include Unlimited mileage tracking for each driver.
        </p>
        <div className="flex items-center justify-center my-8 mb-2 border-black/[.10] border-b-[1px]">
          <button
            className={`text-center text-md p-3 cursor-pointer ${
              newType === TEAM_SUBSCRIPTION_TYPES.YEAR ? "border-b-2" : ""
            } border-black`}
            onClick={() => setNewType(TEAM_SUBSCRIPTION_TYPES.YEAR)}
          >
            <span className="font-medium">Annual</span> (save 16%){" "}
          </button>
          <button
            className={`text-center text-md p-3 cursor-pointer ${
              newType === TEAM_SUBSCRIPTION_TYPES.MONTH ? "border-b-2" : ""
            } border-black`}
            onClick={() => setNewType(TEAM_SUBSCRIPTION_TYPES.MONTH)}
          >
            <span className="font-medium">Monthly</span>
          </button>
        </div>
        <div className="mt-6 subscription-plan-selector">
          {Object.keys(TEAMS_SUBSCRIPTION_PLANS).map((k) => (
            <OneOfManyPlan
              key={k}
              plan={k}
              onFeatureLearnMoreClick={setModal}
              subPlan={TEAMS_SUBSCRIPTION_PLANS.TEAMS_PRO}
              subType={newType}
              className="subscription-card"
              cta={
                <Button
                  className="w-full mt-6"
                  onClick={() => handleChooseSub(k)}
                  disabled={checkoutInProgress}
                  loading={k == selectedPlan}
                >
                  {`Choose ${TEAMS_SUBSCRIPTION_PLANS_DATA.shortLabels[k]}`}
                </Button>
              }
            />
          ))}
        </div>

        {modal && (
          <Modal
            closable
            show
            onClose={() => setModal(null)}
            className="w-[70%] max-w-[1000px] tablet:max-w-[540px]"
          >
            <div className="flex tablet:flex-col flex-row gap-8 justify-between">
              <div className="flex flex-col flex-1">
                <h3 className="mt-0">{modal?.title}</h3>
                <Text
                  paragraph
                  className="text-black/70 text-md mt-2 whitespace-pre-line"
                >
                  {modal?.description}
                </Text>
              </div>
              <div className="flex flex-1">
                <img src={modal?.illustration} className="w-full" />
              </div>
            </div>
          </Modal>
        )}
      </div>
    </div>
  );
}

export function OneOfManyPlan({
  plan,
  currentPlan,
  subType,
  subPlan,
  cta,
  onFeatureLearnMoreClick,
}) {
  const isCurrent = plan === currentPlan;
  const isActive = subPlan === plan;
  const price = formatCurrency({
    value: TEAMS_SUBSCRIPTION_PLANS_DATA.prices[plan][subType],
    currency: "$",
    fractionDigits: 0,
  });
  const subtitle = `Per user, per ${subType.toLowerCase()}`;
  let badge = null;
  if (isCurrent) {
    badge = "Current plan";
  } else if (plan === TEAMS_SUBSCRIPTION_PLANS.TEAMS_PRO) {
    badge = "Best value";
  }

  const title = (
    <div className="mt-2 flex items-center">
      <h5 className="mr-2 font-semibold">Teams</h5>
      {plan === TEAMS_SUBSCRIPTION_PLANS.TEAMS_LITE ? (
        <BadgeTeamsLite />
      ) : plan === TEAMS_SUBSCRIPTION_PLANS.TEAMS_PRO ? (
        <BadgeTeamsPro />
      ) : null}
    </div>
  );

  return (
    <div
      className={`subscription-plan-selector-item  ${
        isActive ? "active " : ""
      } ${isCurrent ? "disabled" : ""}`}
    >
      <div className="p-7 w-full h-full flex flex-col rounded">
        {title}
        <p className="text-[13px] text-black/70 mt-3 md:min-h-[110px]">
          {TEAMS_SUBSCRIPTION_PLANS_DATA.descriptions[plan]}
        </p>
        <h3 className="mt-5 font-bold">{price}</h3>
        <Text md color="black/70">
          {subtitle}
        </Text>
        {cta}
        <Text md color="black" bold className="mt-5">
          What's included
        </Text>
        <ul className="m-0 mt-5 w-full">
          {TEAMS_SUBSCRIPTION_PLANS_DATA.featuresv2.map((f) => {
            const isAvailable = f.activeInPlans.includes(plan);
            if (!isAvailable) {
              return null;
            }
            return (
              <FeatureHint
                key={f.title}
                plan={plan}
                feature={f}
                openModal={() => {
                  onFeatureLearnMoreClick(f);
                }}
              >
                <li key={f.title} className="flex items-center mb-1">
                  <Icon
                    name={isAvailable ? "check-mark" : "close"}
                    color={isAvailable && !isCurrent ? "blue" : "black/30"}
                    className="mr-2"
                  />
                  <Text
                    md
                    className="underline"
                    color={
                      isCurrent ? null : isAvailable ? "black" : "black/30"
                    }
                  >
                    {f.title}
                  </Text>
                </li>
              </FeatureHint>
            );
          })}
        </ul>
        {badge && (
          <Text
            sm
            className={`subscription-badge ${isCurrent ? "inactive" : ""}`}
          >
            {badge}
          </Text>
        )}
      </div>
    </div>
  );
}

function FeatureHint({ plan, feature, openModal, children }) {
  const tooltipRef = useRef();

  if (!feature?.hint) return null;

  function handleClick() {
    trackFeatureDetailSelected({
      featureName: feature.title,
      teamsPlanName: plan,
    });
    openModal();
  }

  return (
    <>
      <div className="cursor-pointer hidden md:flex" ref={tooltipRef}>
        {children}
      </div>
      <div className="cursor-pointer flex md:hidden" onClick={handleClick}>
        {children}
      </div>
      <Tooltip
        width={280}
        offset={{ y: -50, x: 150 }}
        triggerRef={tooltipRef}
        className="py-2.5 px-3 z-10"
      >
        <Text paragraph md>
          {feature.hint}
        </Text>
        <Text
          md
          semibold
          className="mt-1 cursor-pointer text-blue underline"
          onClick={handleClick}
        >
          Learn more
        </Text>
      </Tooltip>
    </>
  );
}
