import {
  useLDClient as useLaunchDarklyClient,
  useFlags as useLaunchDarklyFlags,
  withLDProvider,
} from "launchdarkly-react-client-sdk";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import { useUser } from "src/components/context/UserContext";

import { ENV } from "./useEnvironment";

export const FlagsContext = createContext();

// DEFAULT FLAGS
const FLAGS = {};

export const useFlags = () => {
  const context = useContext(FlagsContext);
  const client = useLaunchDarklyClient();
  const { user } = useUser();
  const [ready, setReady] = useState(false);

  if (!context) {
    throw new Error("useFlags must be used within a FlagsProvider");
  }

  const identify = useCallback(
    (_user) => {
      if (_user?.id) {
        client?.identify({
          kind: "user",
          key: _user.id,
          email: _user.email,
          anonymous: false,
        });
      }
    },
    [client]
  );

  useEffect(() => {
    identify(user);

    // Wait for the LaunchDarkly client to be ready or timeout after 5 seconds
    // This ensures that we don't wait indefinitely for the client to initialize
    if (client && !ready) {
      Promise.race([
        client.waitUntilReady(),
        new Promise((resolve) => setTimeout(resolve, 5000)),
      ]).then(() => {
        setReady(true);
      });
    }
  }, [client, user]);

  context.identify = identify;

  context.track = (key, data, metricValue) =>
    client.track(key, data, metricValue);
  context.flush = (onDone) => client.flush(onDone);
  context.ready = ready;

  return context;
};

const Provider = ({ children }) => {
  const client = useLaunchDarklyClient();
  const LaunchDarklyFlags = useLaunchDarklyFlags();

  const [flags, setFlags] = useState({
    ...FLAGS,
    ...ENV?.FLAGS,
    ...LaunchDarklyFlags,
  });

  const toggleFlagByName = (flagName) => {
    setFlags((prevFlags) => ({
      ...prevFlags,
      [flagName]: !prevFlags[flagName],
    }));
  };

  // exposing flags and context to the console for debugging purposes
  window.logLaunchDarklyFlags = () => console.log(flags);
  window.logLaunchDarklyContext = useCallback(() => {
    console.log(client.getContext());
  }, [client]);

  useEffect(() => {
    // Update flags when LaunchDarklyFlags change
    setFlags((prevFlags) => ({
      ...prevFlags,
      ...LaunchDarklyFlags,
    }));
  }, [LaunchDarklyFlags]);

  return (
    <FlagsContext.Provider value={{ ...flags, toggleFlagByName }}>
      {children}
    </FlagsContext.Provider>
  );
};

const isLDEnabled =
  ENV?.FLAGS?.LAUNCHDARKLY_ENABLED && ENV?.LAUNCHDARKLY_CLIENT_SIDE_ID;
export const FlagsProvider = isLDEnabled
  ? withLDProvider({
      clientSideID: ENV?.LAUNCHDARKLY_CLIENT_SIDE_ID,
    })(Provider)
  : Provider;
