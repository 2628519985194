import { useLazyQuery, useQuery } from "@apollo/client";
import { useContext } from "react";

import { UserDataContext } from "src/components/context/UserContext";

import Icon from "src/components/elements/Icon";
import Loader from "src/components/elements/Loader";
import Text from "src/components/elements/Text";

import { useFlags } from "src/hooks/useFlags";

import {
  SUBSCRIPTION_PLANS_DATA_BY_COUNTRY,
  SUBSCRIPTION_TYPE,
} from "src/services/billing";
import { setPendingUserSubscription } from "src/services/storage";
import {
  Pages,
  UpgradeSource,
  trackUpgradeStarted,
} from "src/services/tracking";
import { COUNTRIES_DATA, formatCurrency } from "src/services/utils";

import {
  CREATE_DRIVER_CHECKOUT_SESSION,
  GET_USER_MONTH_STATS,
} from "src/graphql/queries";

import ImgCar from "public/assets/img/car-on-money.svg";

export default FreePlan;

const MAX_FREE_DRIVES_PER_MONTH = 40;

function FreePlan() {
  const umsQuery = useQuery(GET_USER_MONTH_STATS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  if (umsQuery.loading) return <Loader />;

  const drivesLeft = Math.max(
    0,
    MAX_FREE_DRIVES_PER_MONTH - umsQuery.data.userMonthStats.totalDrives
  );

  return (
    <div className="flex flex-col gap-[20px]">
      <Text bold>Your subscription</Text>
      <DrivesLeft drivesLeft={drivesLeft} />
      <Upgrade />
    </div>
  );
}

function DrivesLeft({ drivesLeft }) {
  const { userData } = useContext(UserDataContext);

  const label = `${
    drivesLeft > 0 && drivesLeft < 10 ? "Fewer than 10" : drivesLeft
  } drives left this month`;

  const { taxAuthority } = COUNTRIES_DATA[userData.country];

  return (
    <div className="border border-border-1 rounded p-[10px] pb-[20px]">
      <div
        className={`h-[40px] flex items-center px-3 ${
          drivesLeft > 0 ? "bg-black" : "bg-red"
        } rounded-10`}
      >
        <span className="scale-75">
          <Icon name="alert" color="white" />
        </span>
        <Text md color="white" className="ml-2">
          {label}
        </Text>
      </div>
      <div className="pl-3">
        <h6 className="mt-4">Limited plan (free)</h6>
        <Text paragraph color="black/70" className="mt-2">
          Your limited plan only tracks 40 free drives. Don't miss any drives!
          Upgrade to our Unlimited plan TODAY to stay {taxAuthority} compliant.
        </Text>
      </div>
    </div>
  );
}

export function Upgrade() {
  const { miqDashboardPricesWeb } = useFlags();
  const { userData } = useContext(UserDataContext);
  const [checkoutQueryFn, checkoutQueryState] = useLazyQuery(
    CREATE_DRIVER_CHECKOUT_SESSION,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
    }
  );

  const featureFlagPricesForUserCountry = miqDashboardPricesWeb?.filter(
    (price) => price?.country === userData.country
  );

  const handlePickSubscription = (type) => {
    if (checkoutQueryState.loading) return;

    const stripePriceID = featureFlagPricesForUserCountry?.find(
      (price) => price?.type === type
    )?.stripePriceID;

    checkoutQueryFn({
      variables: {
        subscriptionType: type,
        stripePriceID,
      },
      onCompleted: (data) => {
        if (data?.createDriverCheckoutSession?.url) {
          setPendingUserSubscription({ isNew: true });
          trackUpgradeStarted({
            page: Pages.SUBSCRIPTION,
            src: UpgradeSource.SUBSCRIPTION_PAGE,
          });
          window.location.href = data.createDriverCheckoutSession.url;
          return;
        }
      },
    });
  };

  let annualPrice =
    SUBSCRIPTION_PLANS_DATA_BY_COUNTRY[userData.country].prices[
      SUBSCRIPTION_TYPE.ANNUAL
    ];
  let monthlyPrice =
    SUBSCRIPTION_PLANS_DATA_BY_COUNTRY[userData.country].prices[
      SUBSCRIPTION_TYPE.MONTHLY
    ];

  // replace prices by the ones from the feature flag
  featureFlagPricesForUserCountry?.forEach(({ type, value }) => {
    if (type === SUBSCRIPTION_TYPE.MONTHLY) {
      monthlyPrice = value;
    } else if (type === SUBSCRIPTION_TYPE.ANNUAL) {
      annualPrice = value;
    }
  });

  const savings = Math.round(100 * (1 - annualPrice / 12 / monthlyPrice));
  const annualPriceFmt = formatCurrency({
    value: annualPrice,
    currency: userData.currency,
  });
  const monthlyPriceFmt = formatCurrency({
    value: monthlyPrice,
    currency: userData.currency,
  });

  return (
    <div className="bg-orange-2 rounded p-5">
      <div className="flex gap-3">
        <div className="max-w-[300px]">
          <h6>Upgrade to an Unlimited plan</h6>
          <ul className="mt-[15px] flex flex-col gap-3">
            <li className="flex gap-[7px] items-start">
              <Icon name="autorenew" />
              <Text paragraph color="black/70" className="text-[13px]">
                <Text color="black" bold>
                  Works automatically:
                </Text>{" "}
                Say goodbye to the headache of manually logging every trip.
              </Text>
            </li>
            <li className="flex gap-[7px] items-start">
              <Icon name="clock-eight" />
              <Text paragraph color="black/70" className="text-[13px]">
                <Text color="black" bold>
                  Time savings:
                </Text>{" "}
                A simple swipe is all it takes, letting you focus on your
                business.
              </Text>
            </li>
            <li className="flex gap-[7px] items-start">
              <Icon name="dollar" />
              <Text paragraph color="black/70" className="text-[13px]">
                <Text color="black" bold>
                  Claim your full deduction:
                </Text>{" "}
                The average MileIQ user deducts $6,500 per year.
              </Text>
            </li>
          </ul>
        </div>
        <div className="flex-shrink-0 flex-grow flex justify-center items-center">
          <ImgCar />
        </div>
      </div>

      <div
        className={`flex gap-3 mt-[30px] ${
          checkoutQueryState.loading ? "[&_button]:cursor-progress" : ""
        }`}
        data-testid="subscription-upgrade-plans"
      >
        <button
          onClick={() => handlePickSubscription(SUBSCRIPTION_TYPE.ANNUAL)}
          className="relative w-full flex items-center justify-between bg-yellow rounded p-5"
        >
          <span className="flex flex-col gap-[4px]">
            <Text bold md>
              Annual
            </Text>
            <Text md>
              {annualPriceFmt} (Save {savings}%)
            </Text>
          </span>
          <span className="h-[35px] pl-[10px] pr-[5px] bg-white flex items-center rounded-10 gap-2">
            <Text bold md>
              Select
            </Text>
            <Icon name="arrow-right" />
          </span>
          <span className="absolute -top-[12px] left-[15px] bg-green h-[25px] flex items-center px-[10px] rounded-full">
            <Text md color="white">
              Best value
            </Text>
          </span>
        </button>
        <button
          onClick={() => handlePickSubscription(SUBSCRIPTION_TYPE.MONTHLY)}
          className="w-full flex items-center justify-between bg-white rounded p-5"
        >
          <span className="flex flex-col gap-[4px]">
            <Text bold md>
              Monthly
            </Text>
            <Text md>{monthlyPriceFmt}/month</Text>
          </span>
          <span className="h-[35px] pl-[10px] pr-[5px] bg-white border-2 border-border-2 flex items-center rounded-10 gap-2">
            <Text bold md>
              Select
            </Text>
            <Icon name="arrow-right" />
          </span>
        </button>
      </div>
    </div>
  );
}
