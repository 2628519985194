import Base from "src/models/base";

export default class DriveLocation extends Base {
  latitude = null;
  longitude = null;
  city = null;
  country = null;
  hood = null;
  name = null;
  state = null;
  postalCode = null;
  namedLocationDetails = null;

  constructor(data) {
    super("location_model");
    if (data) {
      this.latitude = data.latitude || null;
      this.longitude = data.longitude || null;
      this.city = data.city || null;
      this.country = data.country || null;
      this.hood = data.hood || null;
      this.name = data.name || null;
      this.displayName = data.displayName || null;
      this.fullAddress = data.fullAddress || null;
      this.state = data.state || null;
      this.postalCode = data.postalCode || null;
      this.isNamed = !!data.isNamed;
      if (data.isNamed && data.namedLocationDetails) {
        this.namedLocationDetails = { ...data.namedLocationDetails };
      } else {
        this.namedLocationDetails = null;
      }
    }
  }

  get lat() {
    return this.latitude;
  }

  set lat(v) {
    this.latitude = v;
  }

  get lng() {
    return this.longitude;
  }

  set lng(v) {
    this.longitude = v;
  }

  getLabel(shouldDisplayFullAddress) {
    if (this.isNamed) {
      return this.displayName;
    }

    if (shouldDisplayFullAddress && this.fullAddress) {
      return this.fullAddress;
    }

    return this.displayName;
  }
}
