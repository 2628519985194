import { useMutation, useQuery } from "@apollo/client";
import { format, getYear } from "date-fns";
import React, { useContext, useEffect, useRef, useState } from "react";

import { registerElement } from "src/lib/layers/LayersProvider";

import Button from "src/components/elements/Button";
import Icon from "src/components/elements/Icon";
import Input from "src/components/elements/Input";
import Modal from "src/components/elements/Modal";
import Switcher from "src/components/elements/Switcher";
import Text from "src/components/elements/Text";
import Tooltip from "src/components/elements/Tooltip";

import { useFlags } from "src/hooks/useFlags";

import { TEAMS_SUBSCRIPTION_PLANS_DATA } from "src/models/team-subscription";

import { canDriverSubmitReports } from "src/services/teams";
import { trackExperimentViewed } from "src/services/tracking";
import {
  COUNTRIES,
  getTimeZoneDiffInHours,
  isValidEmail,
} from "src/services/utils";

import {
  ADD_CUSTOM_REPORT,
  SUBMIT_REPORT_TO_TEAM,
} from "src/graphql/mutations";
import { GET_RATES } from "src/graphql/queries";

import ImgCarWithDog from "public/assets/img/car-with-dog-2.svg";

import CurrencyInput from "../blocks/inputs/CurrencyInput";
import ConcurReport from "../blocks/reports/ConcurReport";
import TeamContext from "../context/TeamContext";
import UserContext, { UserDataContext, useUser } from "../context/UserContext";

export default ReportActions;

export const ELEMENT_ID = "REPORT_ACTIONS";
registerElement(ELEMENT_ID, ReportActions);

function ReportActions({
  onClose,
  reportData,
  hasBusinessDrives,
  onReportSent,
}) {
  const { team } = useContext(TeamContext);
  const { user } = useUser();
  const canSubmit = canDriverSubmitReports(team, user);
  const [activeTab, setActiveTab] = useState(
    canSubmit && hasBusinessDrives ? "admin" : "custom"
  );
  const [concurIdentitySuccess, setConcurIdentitySuccess] = useState(false);
  const [initialService, setInitialService] = useState("email");

  useEffect(() => {
    if (!team)
      trackExperimentViewed({
        experimentId: 15,
        experimentName: "Contacts",
        variationId: 1,
        variationName: "Control",
      });
  }, [team]);

  return (
    <Modal
      closable={!concurIdentitySuccess}
      onClose={onClose}
      className="w-[420px] laptop:w-[360px]"
    >
      {concurIdentitySuccess ? (
        <div className="flex flex-col items-center pt-8 pb-28 gap-14">
          <h4>Concur Integration Enabled</h4>
          <ImgCarWithDog />
        </div>
      ) : (
        <>
          {team && canSubmit && (
            <div className="border-b border-solid border-border-1 w-full flex mb-[20px]">
              <div
                className={`w-1/2 text-center ${
                  activeTab === "admin" ? "border-b-[3px] border-blue" : ""
                }`}
              >
                <Button
                  onClick={() => setActiveTab("admin")}
                  ghost
                  className="w-full font-semibold"
                >
                  Submit to Admin
                </Button>
              </div>
              <div
                className={`w-1/2 text-center ${
                  activeTab === "custom" ? "border-b-[3px] border-blue" : ""
                }`}
              >
                <Button
                  onClick={() => setActiveTab("custom")}
                  ghost
                  className="w-full font-semibold"
                >
                  Other Options
                </Button>
              </div>
            </div>
          )}
          {activeTab === "admin" && team && (
            <SendToAdmin
              hasBusinessDrives={hasBusinessDrives}
              reportData={reportData}
              team={team}
              onReportSent={onReportSent}
            />
          )}

          {activeTab === "custom" && (
            <SendToCustom
              initialService={initialService}
              reportData={reportData}
              team={team}
              onReportSent={onReportSent}
              onIdentitySet={() => {
                setConcurIdentitySuccess(true);
                setInitialService("concur");

                setTimeout(() => {
                  setConcurIdentitySuccess(false);
                }, 2500);
              }}
            />
          )}
        </>
      )}
    </Modal>
  );
}

const SendToAdmin = ({ reportData, team, onReportSent, hasBusinessDrives }) => {
  const [loading, setLoading] = useState(false);
  const [submitReportToTeam] = useMutation(SUBMIT_REPORT_TO_TEAM, {
    notifyOnNetworkStatusChange: true,
  });

  let data = {};
  if (!reportData.driveIds) {
    data = {
      dateFrom: format(reportData.dateFrom, "yyyy-MM-dd"),
      dateTo: format(reportData.dateTo, "yyyy-MM-dd"),
    };
  } else {
    data = {
      driveIds: reportData.driveIds,
    };
  }
  const submitReport = async () => {
    setLoading(true);
    submitReportToTeam({
      variables: {
        data: {
          teamId: team?.id,
          ...data,
        },
      },
    }).then(() => {
      setLoading(false);
      onReportSent(true, "Report has been sent to your admin", {
        recipientType: "admin",
      });
    });
  };

  const SubmitButton = (props) => (
    <div className="w-full flex gap-1">
      <Button
        {...props}
        disabled={team?.isExpired}
        tooltip={
          team?.isExpired && {
            text: `Want to submit reports directly? Ask your admin to resubscribe to ${
              TEAMS_SUBSCRIPTION_PLANS_DATA.labels[team?.subscription?.plan]
            }.`,
            textProps: {
              nowrap: false,
            },
            className: "w-[207px]",
            offset: { x: 100, y: -55 },
            alignX: "center",
            timeout: 500,
          }
        }
        lg
        primary
        loading={loading}
        onClick={() => submitReport()}
        className="flex w-full font-medium"
      >
        {loading ? "Loading..." : "Submit my report"}
      </Button>
    </div>
  );

  if (!hasBusinessDrives) {
    return (
      <>
        <div className="bg-red-pastel p-3 rounded mb-[20px]">
          <div className="flex gap-6">
            <Icon name="red-exclamation-circle" />
            <Text paragraph>
              Only business drives can be reported to your Team. You currently
              have <strong>no business drives</strong> selected.
            </Text>
          </div>
        </div>
        <SubmitButton disabled />
      </>
    );
  }

  return (
    <div
      data-testid="send-to-admin"
      className="flex flex-col items-center justify-center gap-[20px] laptop:gap-[15px]"
    >
      <h4 className="text-center">Submit drive report to your admin</h4>
      <Text paragraph className="text-center">
        This will submit your classified business drives to{" "}
        <strong>{team.name}</strong> ({team?.owner?.email}). Personal and
        unclassified drives are automatically excluded. Please note that
        submitted drives cannot be recalled.
      </Text>
      <SubmitButton />
    </div>
  );
};

const SendToCustom = ({
  reportData,
  onReportSent,
  onIdentitySet,
  initialService,
}) => {
  const { userData } = useContext(UserDataContext);
  const { user } = useContext(UserContext);
  const ratesQuery = useQuery(GET_RATES, {
    notifyOnNetworkStatusChange: true,
  });

  const {
    miqDashConcurDriverCwsIntegrationWeb,
    miqDashboardTeamsProfileNames: isProfileNamesEnabled,
  } = useFlags();
  const [selectedService, setSelectedService] = useState(initialService);
  const [currentBusinessRate, setCurrentBusinessRate] = useState(null);
  const timeZoneDiffInHours = getTimeZoneDiffInHours();
  const [addCustomReport, { loading }] = useMutation(ADD_CUSTOM_REPORT, {
    notifyOnNetworkStatusChange: true,
  });
  const [data, setData] = useState({
    shouldReport: true,
  });
  const [reportError, setReportError] = useState(null);
  const iconRef = useRef(null);

  useEffect(() => {
    if (reportError) {
      setReportError(null);
    }
  }, [data]);

  useEffect(() => {
    let rateObj = null;
    if (userData.country === COUNTRIES.US) {
      rateObj = ratesQuery?.data?.rates.find(
        (rate) => rate.year === getYear(new Date())
      );
    }

    if (rateObj) setCurrentBusinessRate(JSON.parse(rateObj.dataJSON));
  }, [ratesQuery.data?.rates]);

  const onSubmit = (additionalData = {}) => {
    const { reimbursementRates, cc, ...otherData } = data;
    let customData = {};
    if (reportData.driveIds) {
      customData = {
        driveIds: reportData.driveIds,
        firstTimeZoneDiffInHours: timeZoneDiffInHours,
        lastTimeZoneDiffInHours: timeZoneDiffInHours,
      };
    } else {
      customData = {
        startTimestamp: reportData.dateFrom.toISOString(),
        endTimestamp: reportData.dateTo.toISOString(),
        vehicleIds: reportData.vehicleIds,
        categoryIds: reportData.categoryId,
        firstTimeZoneDiffInHours: getTimeZoneDiffInHours(reportData.dateFrom),
        lastTimeZoneDiffInHours: getTimeZoneDiffInHours(reportData.dateTo),
      };
    }

    setReportError(null);

    addCustomReport({
      variables: {
        data: {
          ...otherData,
          ...customData,
          ...additionalData,
          reimbursementRates: {
            business: +reimbursementRates?.business || null,
          },
          submitter: data?.submitter?.trim(),
          cc,
          useMetric: userData.distanceUnit === "km" ? "t" : "f",
        },
      },
    }).then((res) => {
      const success = res?.data?.addCustomReport?.ok;
      if (!success) {
        setReportError(
          "Failed to send custom report, please try again later or contact support."
        );
      }

      const isConcurReport = additionalData?.expenseService === "concur";
      const email = cc || user.email;
      onReportSent(
        success,
        `Report has been sent to ${isConcurReport ? "Concur" : email}`,
        {
          recipientType: isConcurReport ? "concur" : "email",
          markAsReported: data.shouldReport,
        }
      );
    });
  };
  return (
    <div
      data-testid="send-to-custom"
      className="flex flex-col items-center justify-center"
    >
      <div className="w-full flex flex-col gap-[20px] laptop:gap-[15px]">
        <h4 className="text-center">Create report</h4>
        <div className="flex gap-3">
          <Button
            lg
            ghost
            onClick={() => setSelectedService("email")}
            className={`border font-medium ${
              selectedService === "email"
                ? "bg-blue/5 border-blue"
                : "bg-white border-border-1"
            }`}
            icon="mail"
          >
            Email
          </Button>
          {miqDashConcurDriverCwsIntegrationWeb === "show" && (
            <Button
              lg
              ghost
              onClick={() => setSelectedService("concur")}
              className={`border font-medium ${
                selectedService === "concur"
                  ? "bg-blue/5 border-blue"
                  : "bg-white border-border-1"
              }`}
              icon="concur"
            >
              Concur
            </Button>
          )}
        </div>
        {selectedService === "email" && (
          <>
            <Input
              value={data.reportName}
              onChange={(e) => setData({ ...data, reportName: e.target.value })}
              label="Report name*"
              placeholder="Report name"
            />
            <Input
              type="email"
              label="Email"
              value={data.cc}
              onChange={(e) => setData({ ...data, cc: e.target.value })}
              placeholder="Send a copy to (email address)"
            />
            {isProfileNamesEnabled ? (
              <Input
                value={data.client}
                onChange={(e) => setData({ ...data, client: e.target.value })}
                label="Client or customer"
                placeholder="Client or customer"
              />
            ) : (
              <div className="grid grid-cols-2 gap-4">
                <Input
                  label="Submitted by"
                  value={data.submitter}
                  onChange={(e) =>
                    setData({ ...data, submitter: e.target.value })
                  }
                  placeholder="Name"
                />
                <Input
                  value={data.client}
                  onChange={(e) => setData({ ...data, client: e.target.value })}
                  label="Client or customer"
                  placeholder="Client or customer"
                />
              </div>
            )}
            <div className="grid grid-cols-2 gap-4">
              <Input
                value={data.project}
                onChange={(e) => setData({ ...data, project: e.target.value })}
                label="Project"
                placeholder="Project"
              />
              {userData.country === COUNTRIES.US && (
                <CurrencyInput
                  fractionalDigits={3}
                  value={data.reimbursementRates?.business}
                  onChange={(e) =>
                    setData({
                      ...data,
                      reimbursementRates: { business: e.target.value },
                    })
                  }
                  noCurrency
                  label="Business rate($)"
                  placeholder={`${currentBusinessRate?.values?.business?.default}/mi`}
                />
              )}
            </div>
            <div className="py-2 border-y border-solid border-border-1 flex justify-between items-center">
              <div className="flex">
                <Text semibold>Mark drives as reported</Text>
                <Icon
                  className="ml-2"
                  name="question-circle"
                  iconRef={iconRef}
                />
              </div>
              <Tooltip
                triggerRef={iconRef}
                offset={{
                  y: 25,
                  x: -130,
                }}
              >
                <Text sm nowrap>
                  Select this option to see which drives have been reported.
                </Text>
              </Tooltip>
              <Switcher
                lg
                isOn={data.shouldReport}
                onChange={() =>
                  setData({ ...data, shouldReport: !data.shouldReport })
                }
              />
            </div>
            {reportError && (
              <Text
                md
                color="red"
                regular
                paragraph
                className="w-full text-left mt-2"
              >
                {reportError}
              </Text>
            )}
            <Button
              lg
              onClick={() => onSubmit()}
              loading={loading}
              disabled={
                !data.reportName?.trim() ||
                (data.cc?.trim() && !isValidEmail(data.cc)) ||
                loading
              }
            >
              Create and send report
            </Button>
          </>
        )}
        {selectedService === "concur" && (
          <ConcurReport
            onSubmit={onSubmit}
            submitLoading={loading}
            reportError={reportError}
            clearError={() => setReportError(null)}
            onIdentitySet={onIdentitySet}
          />
        )}
      </div>
    </div>
  );
};
