import format from "date-fns/format";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import useElement from "src/lib/layers/useElement";

import TeamContext from "src/components/context/TeamContext";
import { useTeamDunning } from "src/components/context/TeamDunningContext";
import { useUser } from "src/components/context/UserContext";
import { useUsers } from "src/components/context/UsersContext";

import Button from "src/components/elements/Button";
import { FlashTypes } from "src/components/elements/Flash";
import Icon from "src/components/elements/Icon";
import Loader from "src/components/elements/Loader";
import MobMenu from "src/components/elements/MobMenu";
import Text from "src/components/elements/Text";

import QuickTips from "src/components/blocks/QuickTips";
import TeamMembersList from "src/components/blocks/TeamMembersList";
import UserActions from "src/components/blocks/reports/UserActions";

import BulkInvite from "src/components/modals/BulkInvite";
import DowngradeUserConfirm from "src/components/modals/DowngradeUserConfirm";
import { DUNNING_SUBSCRIPTION_RECOVER_PAYMENT_MODAL_ID } from "src/components/modals/DunningSubscriptionRecoverPayment";
import InviteUsers from "src/components/modals/InviteUsers";
import InviteUsersConfirm from "src/components/modals/InviteUsersConfirm";
import RemoveUsersConfirm from "src/components/modals/RemoveUsersConfirm";
import UpgradeUserConfirm from "src/components/modals/UpgradeUserConfirm";

import PageLayout from "src/components/PageLayout";

import useFlash from "src/hooks/useFlash";
import useQueryParams from "src/hooks/useQueryParams";
import { DUNNING_STATUS, useTeamsCTA } from "src/hooks/useTeamCTA";
import {
  usePickFirstPlanFlow,
  useUpgradePlanFlow,
} from "src/hooks/useUpgradePlan";

import { MEMBER_STATUS } from "src/models/team-member";
import {
  TEAMS_SUBSCRIPTION_PLANS,
  isTeamsProSubscription,
} from "src/models/team-subscription";
import User, { DRIVERS_LIST_ACTIONS, USER_TYPES } from "src/models/user";

import { tryRefreshToken } from "src/services/auth";
import {
  getInviteUsersAfterFreeTrialPurchase,
  getPendingInvites,
  removeInviteUsersAfterFreeTrialPurchase,
  removePendingInvites,
  removePendingTeamsSubscription,
  setShowWelcomeSlides,
} from "src/services/storage";
import {
  addUsers,
  getTeamMembers,
  removeByEmail,
  updateUser,
} from "src/services/teams";
import {
  TeamsUpdatePaymentSources,
  teamsInviteSources,
  teamsResendInviteTypes,
  teamsUpgradeSources,
  teamsUserUpdateSources,
  trackBulkUploadCompleted,
  trackBulkUploadFailed,
  trackBulkUploadStepCompleted,
  trackTeamsInviteCompleted,
  trackTeamsInviteResent,
  trackTeamsInviteRevoked,
  trackTeamsInviteStarted,
  trackTeamsUpdateUserStarted,
  trackTeamsUpgradeCompleted,
  trackTeamsUpgradeStarted,
} from "src/services/tracking";
import { plural } from "src/services/utils";

import ImgEmptySeat from "public/assets/img/car-with-empty-seat.svg";

export default Users;

const MODALS = {
  INVITE: "INVITE",
  BULK_INVITE: "BULK_INVITE",
  INVITE_CONFIRM: "INVITE_CONFIRM",
  REMOVE_DRIVER_CONFIRM: "REMOVE_DRIVER_CONFIRM",
  UPGRADE_USER_CONFIRM: "UPGRADE_USER_CONFIRM",
  DOWNGRADE_USER_CONFIRM: "DOWNGRADE_USER_CONFIRM",
};

const PAGE_STATES = {
  IDLE: "IDLE",
  DRIVERS_LOADING: "DRIVERS_LOADING",
  DRIVER_SUBMIT_PROGRESS: "DRIVER_SUBMIT_PROGRESS",
  DRIVER_REMOVE_PROGRESS: "DRIVER_REMOVE_PROGRESS",
  CHECKOUT_LOADING: "CHECKOUT_LOADING",
};

function Users() {
  const history = useHistory();
  const queryParams = useQueryParams();
  const { user: me } = useUser();

  const { team, refreshTeam } = useContext(TeamContext);
  const scheduledAfterInvite = useRef([]);
  const [flash, Flash] = useFlash();
  const { selectedUsers, removeUser } = useUsers();

  const [pageState, setPageState] = useState(null);
  const [teamMembers, setTeamMembers] = useState([]);
  const [modalVisible, setModalVisible] = useState(null);
  const [tagsToSubmit, setTagsToSubmit] = useState([]);
  const [userToRemove, setUserToRemove] = useState(null);
  const [userToUpdate, setUserToUpdate] = useState(null);
  const [defaultUserTypeForInvite, setDefaultUserTypeForInvite] = useState(
    USER_TYPES.DRIVER
  );
  const [inviteUsersTitle, setInviteUsersTitle] = useState("Invite Drivers");
  const [inviteUsersSubtitle, setInviteUsersSubtitle] = useState(
    "We'll send them an invitation to join you on MileIQ for Teams."
  );
  const { checkAndHandleDunning } = useTeamsCTA();
  const { resetDunningState } = useTeamDunning();

  const subscriptionRecoverPayment = useElement(
    DUNNING_SUBSCRIPTION_RECOVER_PAYMENT_MODAL_ID,
    {
      props: {
        onClose: () => {
          subscriptionRecoverPayment.deactivate();
          resetDunningState();
        },
        source: TeamsUpdatePaymentSources.INVITE_DRIVERS,
      },
    }
  );

  const nonArchivedDrivers = teamMembers.filter(
    (m) => m.isDriver && m.status != MEMBER_STATUS.ARCHIVED
  );

  let hasFreeSeats = false;
  let availableSeats = 0;

  const subscriptionData = { ...team.subscription };
  if (subscriptionData) {
    hasFreeSeats = subscriptionData.numberOfSeats > nonArchivedDrivers.length;
    availableSeats = subscriptionData.numberOfSeats - nonArchivedDrivers.length;
  }

  const handleBulkSuccessUpload = () => {
    trackBulkUploadStepCompleted({
      orgId: team.orgId,
      orgGroupId: team.orgGroupId,
      bulkUploadStepName: "Upload File",
      bulkUploadStepNumber: 1,
      plan: team.subscription?.plan,
    });
  };

  const handleBulkInviteUploadError = (isError) => {
    if (isError) {
      trackBulkUploadFailed({
        orgId: team.orgId,
        orgGroupId: team.orgGroupId,
        plan: team.subscription?.plan,
      });
    }
  };

  const onReviewUsers = (bulkUsers) => {
    trackBulkUploadCompleted({
      bulkUploadUserCount: bulkUsers.length,

      orgId: team.orgId,
      orgGroupId: team.orgGroupId,
      plan: team.subscription?.plan,
    });
    setTagsToSubmit((currentUsers = []) => {
      // REMOVE DUPLICATES
      let invites = [...bulkUsers, ...currentUsers];
      invites = Array.from(
        new Map(invites.map((invite) => [invite.email, invite])).values()
      );
      return invites;
    });
  };

  const getAnyPlanFlowOpts = {
    onBeforeStart: (opts) => {
      trackTeamsUpgradeStarted({
        src:
          opts?.trackTeamsUpgradeStartedSrc || teamsUpgradeSources.USERS_PAGE,
        orgId: team.orgId,
        orgGroupId: team.orgGroupId,
      });
      setModalVisible(null);
    },
    onDone: () => setModalVisible(null),
    currentSubPlan: team.subscription?.plan,
  };

  const [startGetAnyPlanFlow, renderGetAnyPlanFlow] = team?.subscription
    ? // eslint-disable-next-line
      useUpgradePlanFlow(getAnyPlanFlowOpts) // select new plan and confirm
    : // eslint-disable-next-line
      usePickFirstPlanFlow(getAnyPlanFlowOpts); // select plan and then invite first users

  const getProPlanFlowOpts = {
    onBeforeStart: (opts) => {
      trackTeamsUpgradeStarted({
        src:
          opts?.trackTeamsUpgradeStartedSrc || teamsUpgradeSources.USERS_PAGE,
        orgId: team.orgId,
        orgGroupId: team.orgGroupId,
      });
      setModalVisible(null);
    },
    only: TEAMS_SUBSCRIPTION_PLANS.TEAMS_PRO,
    currentSubPlan: team.subscription?.plan,
  };

  const upgradeToProFlowCommonOptions = {
    onDone: ({ newPlan, prevPlan }) => {
      scheduledAfterInvite.current.push(function () {
        setShowWelcomeSlides(prevPlan, newPlan);
      });
      setModalVisible(MODALS.INVITE); // continue inviting after upgrade
    },
  };

  const [startGetProPlanFlow, renderGetProPlanFlow] = team?.subscription
    ? // eslint-disable-next-line
      useUpgradePlanFlow({
        ...getProPlanFlowOpts,
        ...upgradeToProFlowCommonOptions,
      }) // preselect pro and confirm
    : // eslint-disable-next-line
      usePickFirstPlanFlow(getProPlanFlowOpts); // preselect pro and invite first users

  const [startUpgradeFromMemberListFlow, renderUpgradeFromMemberListFlow] =
    team?.subscription
      ? // eslint-disable-next-line
        useUpgradePlanFlow({
          ...getProPlanFlowOpts,
          onDone: ({ newPlan, prevPlan }) => {
            scheduledAfterInvite.current.push(function () {
              setShowWelcomeSlides(prevPlan, newPlan);
            });
          },
        })
      : // eslint-disable-next-line
        usePickFirstPlanFlow(getProPlanFlowOpts);

  const [startGetProPlanWithBackBtnFlow, renderGetProPlanWithBackBtnFlow] =
    team?.subscription
      ? // eslint-disable-next-line
        useUpgradePlanFlow({
          ...getProPlanFlowOpts,
          ...upgradeToProFlowCommonOptions,
          onBack: () => setModalVisible(MODALS.INVITE),
        }) // preselect pro and confirm
      : // eslint-disable-next-line
        usePickFirstPlanFlow(getProPlanFlowOpts); // preselect pro and invite first users

  const loadUsers = async function (silent = false) {
    if (pageState === PAGE_STATES.DRIVERS_LOADING) return;

    if (!silent) setPageState(PAGE_STATES.DRIVERS_LOADING);

    try {
      const members = await getTeamMembers(team?.id);
      setTeamMembers(members);

      const hasDrivers = members.some((m) => m.isDriver);

      if (
        getInviteUsersAfterFreeTrialPurchase(team?.id) ||
        (!hasDrivers && !team?.isPaymentFailing) ||
        queryParams.get("invite")
      ) {
        removeInviteUsersAfterFreeTrialPurchase(team?.id);
        handleInviteUsersClicked();
        setInviteUsersTitle("To begin, invite drivers to your team");
        setInviteUsersSubtitle(
          "Once you invite drivers, they can start using MileIQ to send you reports of their business drives."
        );
      }
    } catch (err) {
      console.log("failed to load teams data");
    }
    if (!silent) setPageState(PAGE_STATES.IDLE);
  };

  const notifyUsersAdded = (invites) => {
    const added = invites.length;
    if (added === 1) {
      flash(
        <Text>
          <Text bold>{invites[0].email}</Text> has been invited to your team!
        </Text>
      );
    } else if (added > 1) {
      flash(
        <Text>
          <Text bold>{added} Drivers</Text> have been invited to your team!
        </Text>
      );
    }
  };

  // process invites after team subscription was successfully purchased
  const processPendingInvites = async () => {
    try {
      const pendingInvites = getPendingInvites(team?.id);
      if (!pendingInvites) return;

      const usersToAdd = pendingInvites.filter((inv) => inv.email !== me.email);
      const updateMe = pendingInvites.find((inv) => inv.email === me.email);
      if (pendingInvites) {
        setPageState(PAGE_STATES.DRIVERS_LOADING);
        try {
          if (usersToAdd.length > 0) {
            await addUsers(team?.id, usersToAdd);
            notifyUsersAdded(usersToAdd);
          }
          if (updateMe) {
            await updateUser(
              team?.id,
              new User({
                email: updateMe.email,
                is_admin:
                  updateMe.userType === USER_TYPES.ADMIN ||
                  updateMe.userType === USER_TYPES.ADMIN_DRIVER,
                is_driver:
                  updateMe.userType === USER_TYPES.DRIVER ||
                  updateMe.userType === USER_TYPES.ADMIN_DRIVER,
              })
            );
          }

          // here we track the successfull purchase of the subscription
          // and also invite completed
          {
            const seatsAdded = pendingInvites.filter(
              (inv) => inv.userType !== USER_TYPES.ADMIN
            ).length; // since Admin is free it doesn't create additional seat
            trackTeamsInviteCompleted({
              seatsAdded,
              inviteCount: pendingInvites.length,
            });
            trackTeamsUpgradeCompleted({
              newPlan: team?.subscription.plan,
              seatsAddedCount: seatsAdded,
              driversCount: 0, // since this is the first purchase the team couldn't have any drivers yet
              orgId: team?.orgId,
              orgGroupId: team?.orgGroupId,
              freeTrial: team?.subscription?.isFreeTrialActive,
            });
          }

          removePendingTeamsSubscription();
          removePendingInvites();
          await refreshTeam();
        } catch (err) {
          console.log(err);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    async function init() {
      await processPendingInvites();
      loadUsers();
    }
    init();
  }, []);

  const handleInviteUsersClicked = () => {
    const dunningStatus = checkAndHandleDunning();

    if (dunningStatus === DUNNING_STATUS.IN_GRACE_PERIOD && !hasFreeSeats) {
      subscriptionRecoverPayment.activate();
      return;
    }

    if (dunningStatus === DUNNING_STATUS.EXPIRED) return;

    trackTeamsInviteStarted({
      src: queryParams.get("inviteSrc")
        ? decodeURIComponent(queryParams.get("inviteSrc"))
        : teamsInviteSources.USER_PAGE,
    });

    // clean up query params used to trigger the invite flow
    const url = new URL(window.location.href);
    url.searchParams.delete("invite");
    url.searchParams.delete("inviteSrc");
    window.history.replaceState({}, document.title, url.pathname + url.search);

    setDefaultUserTypeForInvite(USER_TYPES.DRIVER);
    if (team.subscription) {
      setModalVisible(MODALS.INVITE);
    } else {
      startGetAnyPlanFlow();
    }
  };

  const handleInviteAdminClicked = () => {
    const dunningStatus = checkAndHandleDunning();

    if (dunningStatus === DUNNING_STATUS.EXPIRED) return;

    trackTeamsInviteStarted({
      src: teamsInviteSources.USER_PAGE,
    });

    setDefaultUserTypeForInvite(USER_TYPES.ADMIN);
    if (isTeamsProSubscription(team.subscription?.plan)) {
      setModalVisible(MODALS.INVITE);
    } else {
      startGetProPlanFlow();
    }
  };

  const handleAction = async (key, member) => {
    const dunningStatus = checkAndHandleDunning();

    if (dunningStatus === DUNNING_STATUS.EXPIRED) return;

    switch (key) {
      case DRIVERS_LIST_ACTIONS.RESEND_INTIVE:
        trackTeamsInviteResent({
          orgId: team.orgId,
          orgGroupId: team.orgGroupId,
          resendType: teamsResendInviteTypes.SUBSET,
          resentUserCount: 1,
        });
        flash(<Text>Invitation resent!</Text>);
        break;
      case DRIVERS_LIST_ACTIONS.REMOVE_FROM_TEAM:
        if (member.status === MEMBER_STATUS.INVITED) {
          try {
            setPageState(PAGE_STATES.DRIVERS_LOADING);
            await removeByEmail(team.id, member);
            loadUsers();
            trackTeamsInviteRevoked({
              orgId: team.orgId,
              orgGroupId: team.orgGroupId,
              revokedUserCount: 1,
            });
            removeUser(member);
            flash(
              <Text>
                Invitation for <Text bold>{member.email}</Text> has been
                revoked!
              </Text>
            );
          } catch (err) {
            setPageState(PAGE_STATES.IDLE);
            flash(
              <Text>
                Failed to revoke the invitation for{" "}
                <Text bold>{member.email}</Text>
              </Text>,
              { type: FlashTypes.ERROR }
            );
            console.log("Failed to revoke invitation", err);
          }
        } else {
          setUserToRemove(member.user);
          setModalVisible(MODALS.REMOVE_DRIVER_CONFIRM);
        }
        break;
      case DRIVERS_LIST_ACTIONS.UPGRADE_TO_ADMIN_AND_DRIVER:
        {
          const dunningStatus = checkAndHandleDunning();

          if (
            dunningStatus === DUNNING_STATUS.IN_GRACE_PERIOD &&
            !hasFreeSeats
          ) {
            subscriptionRecoverPayment.activate();
            return;
          }

          const newUser = member.user.clone();
          newUser.isAdmin = true;
          newUser.isDriver = true;
          // add meta data to be used in UpgradeUserConfirm component
          // to get more context about the upgrade
          newUser.meta["upgradeInfo"] = {
            wasAdmin: member.isAdmin,
            wasDriver: member.isDriver,
          };
          setUserToUpdate(newUser);
          trackTeamsUpdateUserStarted({
            email: newUser.email,
            src: teamsUserUpdateSources.UPGRADE_TO_ADMIN_DRIVER,
          });
        }
        setModalVisible(MODALS.UPGRADE_USER_CONFIRM);
        break;
      case DRIVERS_LIST_ACTIONS.DOWNGRADE_TO_ADMIN:
        {
          const newUser = member.user.clone();
          newUser.isAdmin = true;
          newUser.isDriver = false;
          setUserToUpdate(newUser);
          trackTeamsUpdateUserStarted({
            email: newUser.email,
            src: teamsUserUpdateSources.DOWNGRADE_TO_ADMIN,
          });
        }
        setModalVisible(MODALS.DOWNGRADE_USER_CONFIRM);
        break;
      case DRIVERS_LIST_ACTIONS.DOWNGRADE_TO_DRIVER:
        {
          const newUser = member.user.clone();
          newUser.isAdmin = false;
          newUser.isDriver = true;
          setUserToUpdate(newUser);
          trackTeamsUpdateUserStarted({
            email: newUser.email,
            src: teamsUserUpdateSources.DOWNGRADE_TO_DRIVER,
          });
        }
        setModalVisible(MODALS.DOWNGRADE_USER_CONFIRM);
        break;
      case DRIVERS_LIST_ACTIONS.UPGRADE_TO_PRO:
        startUpgradeFromMemberListFlow();
        break;
    }
  };

  const handleUserActionsSuccess = (msg) => {
    flash(<Text>{msg}</Text>);
  };
  const handleUserActionsFailure = (msg) => {
    flash(<Text>{msg}</Text>, { type: FlashTypes.ERROR, hideAfterMs: 10000 });
  };

  const addUsersBtn = (
    <Button icon="plus" onClick={handleInviteUsersClicked}>
      Invite users
    </Button>
  );

  if (!me?.id || !team?.id) return null;

  if (pageState === null) return null;

  return (
    <>
      <PageLayout className="page-users">
        <PageLayout.Main canShowExpiredExperience>
          {pageState === PAGE_STATES.DRIVERS_LOADING ? (
            <Loader
              className="p-8"
              message="Processing... This may take a few minutes depending on the number of invites."
              timeout={10000}
            />
          ) : (
            <div className="h-full flex flex-col">
              <div className="page-header">
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <MobMenu />
                    <h3>Users</h3>
                  </div>
                  {!hasFreeSeats && addUsersBtn}
                </div>
                {hasFreeSeats && (
                  <div className="banner-free-seats flex items-center justify-between rounded bg-white p-5 mt-5 shadow-app-dd">
                    <div className="banner-free-seats-text flex-grow-0 flex items-center mr-5">
                      <div className="banner-free-seats-aside-img flex-shrink-0 flex-grow-0 flex items-center justify-center rounded p-2 mr-5">
                        <ImgEmptySeat />
                      </div>
                      <div className="flex-grow-0">
                        <Text bold paragraph className="title">
                          <Icon
                            name="chair"
                            color="green"
                            className="mob-version chair-icon-inline"
                          />
                          You have {availableSeats} open{" "}
                          {plural(availableSeats, "seat", "seats")}
                        </Text>
                        <Text paragraph>
                          You can either fill an empty seat by inviting a new
                          user, or MileIQ will automatically remove the seat for
                          you at your next billing cycle on{" "}
                          <Text bold>
                            {format(
                              subscriptionData.nextPaymentAt * 1000,
                              "MMM d, yyyy"
                            )}
                          </Text>
                        </Text>
                      </div>
                    </div>
                    {addUsersBtn}
                  </div>
                )}
              </div>
              {teamMembers.length > 0 && (
                <div className="flex-grow">
                  <TeamMembersList
                    members={teamMembers}
                    onSuccess={handleUserActionsSuccess}
                    onFailure={handleUserActionsFailure}
                    onInviteUserClicked={handleInviteUsersClicked}
                    onInviteAdminClicked={handleInviteAdminClicked}
                    onAction={handleAction}
                    loading={pageState === PAGE_STATES.DRIVERS_LOADING}
                  />
                </div>
              )}
            </div>
          )}
        </PageLayout.Main>
        <PageLayout.Sidebar>
          {selectedUsers.size ? (
            <UserActions
              onAfterRevokeSuccess={loadUsers}
              onSuccess={handleUserActionsSuccess}
              onFailure={handleUserActionsFailure}
            />
          ) : (
            <QuickTips showUserQuickTips />
          )}
        </PageLayout.Sidebar>
      </PageLayout>
      {/* Invite new users */}
      {modalVisible === MODALS.INVITE && (
        <InviteUsers
          show={modalVisible === MODALS.INVITE}
          onBulkInviteButtonClick={() => setModalVisible(MODALS.BULK_INVITE)}
          defaultUserType={defaultUserTypeForInvite}
          invites={tagsToSubmit}
          availableSeats={availableSeats}
          onUpgrade={(tags) => {
            setTagsToSubmit(tags);
            setModalVisible(null);
            console.log("onUpgrade: ", tags);
            startGetProPlanWithBackBtnFlow({
              trackTeamsUpgradeStartedSrc: teamsUpgradeSources.USER_INVITE,
            });
          }}
          onNext={async (emails) => {
            await Promise.all([loadUsers(true), refreshTeam()]);

            setTagsToSubmit(emails);
            if (subscriptionData?.id) {
              setModalVisible(MODALS.INVITE_CONFIRM);
            } else {
              setModalVisible(MODALS.PICK_SUBSCRIPTION);
            }
          }}
          onClose={({ setTags, setInOtherTeams }) => {
            setTags([]);
            setInOtherTeams([]);
            setTagsToSubmit([]);
            setModalVisible(null);
            setInviteUsersTitle("Invite users");
            setInviteUsersSubtitle(
              "We'll send them an invitation to join you on MileIQ for Teams."
            );
          }}
          title={inviteUsersTitle}
          subtitle={inviteUsersSubtitle}
        />
      )}
      {modalVisible === MODALS.BULK_INVITE && (
        <BulkInvite
          show={modalVisible === MODALS.BULK_INVITE}
          onBack={() => {
            setTagsToSubmit([...tagsToSubmit]);
            setModalVisible(MODALS.INVITE);
          }}
          onClose={() => setModalVisible(MODALS.INVITE)}
          onUploadError={handleBulkInviteUploadError}
          onSuccessFileLoad={handleBulkSuccessUpload}
          onReviewUsers={onReviewUsers}
        />
      )}
      {/* Confirm invititation */}
      {modalVisible === MODALS.INVITE_CONFIRM && (
        <InviteUsersConfirm
          team={team}
          invites={tagsToSubmit}
          availableSeats={availableSeats}
          show={modalVisible === MODALS.INVITE_CONFIRM}
          onBack={() => {
            setTagsToSubmit([...tagsToSubmit]);
            setModalVisible(MODALS.INVITE);
          }}
          onConfirm={async () => {
            notifyUsersAdded(tagsToSubmit);
            await refreshTeam();
            loadUsers();
            setModalVisible(null);
            setTagsToSubmit([]);
            if (scheduledAfterInvite?.current) {
              scheduledAfterInvite.current.forEach((cb) => {
                if (typeof cb === "function") {
                  cb();
                }
              });
            }
            setInviteUsersTitle("Invite users");
            setInviteUsersSubtitle(
              "We'll send them an invitation to join you on MileIQ for Teams."
            );
            history.push("/teams/users?tab=INVITED");
          }}
          onClose={() => {
            setModalVisible(null);
          }}
        />
      )}
      {/* Confirm user upgrade */}
      {modalVisible === MODALS.UPGRADE_USER_CONFIRM && (
        <UpgradeUserConfirm
          user={userToUpdate}
          team={team}
          hasFreeSeats={hasFreeSeats}
          show={modalVisible === MODALS.UPGRADE_USER_CONFIRM}
          onConfirm={async () => {
            if (userToUpdate.id === me.id) {
              flash(
                <Text>
                  You have been upgraded to <Text bold>Admin & Driver</Text>!
                </Text>
              );
            } else {
              flash(
                <Text>
                  <Text bold>{userToUpdate.email}</Text> has been upgraded to{" "}
                  <Text bold>Admin & Driver</Text>!
                </Text>
              );
            }
            setModalVisible(null);
            await tryRefreshToken();
            await refreshTeam();
            await loadUsers();
          }}
          onClose={() => setModalVisible(null)}
        />
      )}
      {/* Confirm user downgrade */}
      {modalVisible === MODALS.DOWNGRADE_USER_CONFIRM && (
        <DowngradeUserConfirm
          user={userToUpdate}
          team={team}
          show={modalVisible === MODALS.DOWNGRADE_USER_CONFIRM}
          onConfirm={async () => {
            if (userToUpdate.id === me.id) {
              flash(
                <Text>
                  You have been downgraded to <Text bold>Admin</Text>
                </Text>
              );
            } else {
              flash(
                <Text>
                  <Text bold>{userToUpdate.email}</Text> has been downgraded to{" "}
                  <Text bold>{userToUpdate.isAdmin ? "Admin" : "Driver"}!</Text>
                </Text>
              );
            }
            setModalVisible(null);
            await tryRefreshToken();
            await refreshTeam();
            await loadUsers();
          }}
          onClose={() => setModalVisible(null)}
        />
      )}
      {/* Confirm remove user */}
      {modalVisible === MODALS.REMOVE_DRIVER_CONFIRM && (
        <RemoveUsersConfirm
          team={team}
          user={userToRemove}
          show={modalVisible === MODALS.REMOVE_DRIVER_CONFIRM}
          onConfirm={() => {
            const email = userToRemove.email;
            setModalVisible(null);
            setUserToRemove(null);
            flash(
              <Text>
                <Text bold>{email}</Text> has been removed from your team
              </Text>
            );
            loadUsers();
            refreshTeam();
          }}
          onClose={() => setModalVisible(null)}
        />
      )}
      {/* Get Any Plan flow */}
      {renderGetAnyPlanFlow({ defaultUserType: defaultUserTypeForInvite })}
      {/* Upgrade to Pro flow */}
      {renderGetProPlanFlow({ defaultUserType: defaultUserTypeForInvite })}
      {/* Upgrade to Pro (with back button) flow */}
      {renderGetProPlanWithBackBtnFlow({
        defaultUserType: defaultUserTypeForInvite,
      })}
      {/* Upgrade from member list flow */}
      {renderUpgradeFromMemberListFlow({
        defaultUserType: defaultUserTypeForInvite,
      })}
      {/* Flash Container */}
      {Flash}
    </>
  );
}
