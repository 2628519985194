import { useLazyQuery, useMutation } from "@apollo/client";
import * as braze from "@braze/web-sdk";
import { isSameYear } from "date-fns";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";

import useElement from "src/lib/layers/useElement";

import SettingsContext from "src/components/context/SettingsContext";
import TeamContext from "src/components/context/TeamContext";
import { useTeamsAutoReview } from "src/components/context/TeamsAutoReviewContext";
import { useTeamsCommuteSettings } from "src/components/context/TeamsCommuteSettingsContext";
import { useReportReminder } from "src/components/context/TeamsReportReminderContext";
import { UserDataContext } from "src/components/context/UserContext";

import Button from "src/components/elements/Button";
import { CountryFlag } from "src/components/elements/CountryFlag";
import DatePicker from "src/components/elements/DatePicker";
import { FlashTypes } from "src/components/elements/Flash";
import Icon from "src/components/elements/Icon";
import Input from "src/components/elements/Input";
import Loader from "src/components/elements/Loader";
import MobMenu from "src/components/elements/MobMenu";
import Select from "src/components/elements/Select";
import Switcher from "src/components/elements/Switcher";
import Text from "src/components/elements/Text";

import QuickTips from "src/components/blocks/QuickTips";

import ConcurIntegrationSuccessModal from "src/components/modals/ConcurIntegrationSuccess";
import { CUSTOM_REPORT_REMINDER_MODAL_ELEMENT_ID } from "src/components/modals/CustomReportReminder";
import DeleteTeamConfirm from "src/components/modals/DeleteTeamConfirm";
import RemoveConcurIntegrationModal from "src/components/modals/RemoveConcurIntegration";
import SaveSettingsConfirm from "src/components/modals/SaveSettingsConfirm";
import UpdateConcurIntegrationModal from "src/components/modals/UpdateConcurIntegration";

import PageLayout from "src/components/PageLayout";

import { useFlags } from "src/hooks/useFlags";
import useFlash from "src/hooks/useFlash";
import { DUNNING_STATUS, useTeamsCTA } from "src/hooks/useTeamCTA";
import {
  usePickFirstPlanFlow,
  useUpgradePlanFlow,
} from "src/hooks/useUpgradePlan";

import { REMINDER_PERIOD } from "src/models/report-reminder";
import {
  TEAMS_SUBSCRIPTION_PLANS,
  isTeamsProSubscription,
  isTeamsSubscription,
} from "src/models/team-subscription";

import { updateRates, updateTeam } from "src/services/teams";
import {
  Integration,
  IntegrationStatus,
  teamsSettings,
  teamsUpgradeFeatures,
  teamsUpgradeSources,
  trackExcludeCommuteSettingsUpdateStarted,
  trackIntegrationChecked,
  trackIntegrationEditCompleted,
  trackIntegrationEditFailed,
  trackIntegrationEditStarted,
  trackIntegrationEnableCompleted,
  trackIntegrationEnableStarted,
  trackIntegrationRemoved,
  trackTeamsDeletionStarted,
  trackTeamsSettingUpdated,
  trackTeamsUpgradeStarted,
} from "src/services/tracking";
import { COUNTRIES_DATA } from "src/services/utils";

import { CHECK_INTEGRATION } from "src/graphql/mutations";
import { LIST_INTEGRATIONS } from "src/graphql/queries";

import BadgeTeamsPro from "public/assets/img/badge-sub-pro-silver.svg";

export default Settings;

const MODALS = {
  TEAM_DELETE_CONFIRM: "TEAM_DELETE_CONFIRM",
  SAVE_SETTINGS_CONFIRM: "SAVE_SETTINGS_CONFIRM",
};

const settingsContextData = {
  unsavedTeamName: null,
  unsavedRates: null,
};

function Settings() {
  const { team, refreshTeam } = useContext(TeamContext);
  const history = useHistory();
  const [modalVisible, setModalVisible] = useState(null);
  const [savingAll, setSavingAll] = useState(false);
  const [flash, Flash] = useFlash();
  const {
    miqDashConcurAdminCwsIntegrationWeb,
    miqDashboardTeamsSettingsReportRemindersWeb: isReportRemindersEnabled,
    miqExcludeCommuteMilesAllPlatforms: isExcludeCommuteEnabled,
    miqAutoReviewAllPlatforms: isAutomaticReviewEnabled,
  } = useFlags();
  const blockedPageTransition = useRef(null);
  const { checkAndHandleDunning } = useTeamsCTA();

  useEffect(() => {
    if (!team) return;
    // block page transitions if there is unsaved changes
    const unblock = history.block((pt) => {
      const teamNameUnsaved =
        settingsContextData?.unsavedTeamName !== null &&
        team.name !== settingsContextData.unsavedTeamName;
      const teamRatesUnsaved = settingsContextData?.unsavedRates !== null;
      if (teamNameUnsaved || teamRatesUnsaved) {
        unblock();
        blockedPageTransition.current = pt;
        setModalVisible(MODALS.SAVE_SETTINGS_CONFIRM);
        return "true";
      }
    });
    return () => unblock();
  }, [team]);

  const handleDeleteTeamClicked = () => {
    trackTeamsDeletionStarted();
    setModalVisible(MODALS.TEAM_DELETE_CONFIRM);
  };

  const proceedToBlockedRoute = () => {
    if (blockedPageTransition.current?.pathname) {
      history.push(blockedPageTransition.current.pathname);
    }
  };

  const handleDiscardChanges = () => {
    setModalVisible(null);
    proceedToBlockedRoute();
  };

  const handleTeamInfoSaved = () => {
    flash(<Text>Settings saved!</Text>, {
      type: FlashTypes.BLACK,
    });
  };

  const handleSaveAll = async () => {
    const dunningStatus = checkAndHandleDunning();

    if (dunningStatus === DUNNING_STATUS.EXPIRED) return;

    setSavingAll(true);
    try {
      if (settingsContextData.unsavedTeamName) {
        await updateTeam(settingsContextData.unsavedTeamName, team.country);
        settingsContextData.unsavedTeamName = null;
        trackTeamsSettingUpdated({ setting: teamsSettings.COMPANY_NAME });
      }
      if (settingsContextData.unsavedRates) {
        await updateRates(team.id, settingsContextData.unsavedRates);
        trackTeamsSettingUpdated({ setting: teamsSettings.CUSTOM_RATE });
        settingsContextData.unsavedRates = null;
      }
      await refreshTeam();
      flash(<Text>Settings saved!</Text>, {
        type: FlashTypes.SUCCESS,
        hideAfterMs: 700,
        onHide: proceedToBlockedRoute,
      });
      setModalVisible(null);
    } catch (err) {
      console.log(err);
      setSavingAll(false);
      setModalVisible(null);
      flash(<Text>Failed to update the settings!</Text>, {
        type: FlashTypes.ERROR,
      });
    }
  };
  if (!team?.id) return null;

  const showIntegrations = miqDashConcurAdminCwsIntegrationWeb === "show";

  const shouldRenderAutomaticReview =
    (isTeamsProSubscription(team?.subscription?.plan) ||
      isTeamsSubscription(team?.subscription?.plan)) &&
    isAutomaticReviewEnabled;

  return (
    <>
      <PageLayout className="page-settings">
        <PageLayout.Main className="flex flex-col">
          <SettingsContext.Provider value={settingsContextData}>
            <div className="page-header relative">
              <div className="title-row flex items-center justify-between">
                <div className="flex items-center">
                  <MobMenu />
                  <h3>Settings</h3>
                </div>
              </div>
            </div>
            <div
              className="content bg-white flex-grow px-5 pb-24"
              style={{ maxWidth: "956px" }}
            >
              <div style={{ maxWidth: "740px" }}>
                <div className="border-b border-border-1 py-8">
                  <h5 className="mb-8">Company name</h5>
                  <TeamInfo onSave={handleTeamInfoSaved} />
                </div>

                <div className="border-b border-border-1 py-8">
                  <h5 className="mb-8">Company location</h5>
                  <div className="flex items-end">
                    <span className="mr-1">
                      <CountryFlag country={team.country} />
                    </span>
                    <Text semibold paragraph>
                      {COUNTRIES_DATA[team.country].name}
                    </Text>
                  </div>
                  <Text paragraph className="mt-1">
                    To change the location, currency, or unit of distance for
                    your company, please{" "}
                    <a
                      href="https://support.mileiq.com"
                      target="_blank"
                      rel="noopener"
                      className="text-black underline"
                    >
                      contact customer support
                    </a>
                    .
                  </Text>
                </div>
                {isExcludeCommuteEnabled && (
                  <div className="border-b border-border-1 py-8">
                    <ExcludeCommute />
                  </div>
                )}
                {shouldRenderAutomaticReview && (
                  <div className="border-b border-border-1 py-8">
                    <AutoReview />
                  </div>
                )}
                {isReportRemindersEnabled && (
                  <div className="border-b border-border-1 py-8">
                    <ReportReminders />
                  </div>
                )}
                {showIntegrations && (
                  <div className="border-b border-border-1 py-8">
                    <div className="flex items-center gap-2">
                      <h5>Concur Integration</h5>
                      <BadgeTeamsPro />
                    </div>
                    <ConcurIntegration />
                  </div>
                )}
                <div className="py-8">
                  <h5 className="mb-3">Delete my team</h5>
                  <Text paragraph className="mb-8">
                    This will permanently and irrevocably delete your team and
                    cancel all of your drivers' Unlimited subscriptions.{" "}
                  </Text>
                  <Button
                    secondaryBordered
                    onClick={handleDeleteTeamClicked}
                    className="text-black/50"
                  >
                    Delete my team
                  </Button>
                </div>
              </div>
            </div>
          </SettingsContext.Provider>
        </PageLayout.Main>
        <PageLayout.Sidebar>
          <QuickTips />
        </PageLayout.Sidebar>
      </PageLayout>

      {/* Confirm delete team */}
      {modalVisible === MODALS.TEAM_DELETE_CONFIRM && (
        <DeleteTeamConfirm
          team={team}
          show={modalVisible === MODALS.TEAM_DELETE_CONFIRM}
          onClose={() => setModalVisible(null)}
        />
      )}

      {/* Confirm save settings */}
      {modalVisible === MODALS.SAVE_SETTINGS_CONFIRM && (
        <SaveSettingsConfirm
          show={modalVisible === MODALS.SAVE_SETTINGS_CONFIRM}
          loading={savingAll}
          onConfirm={handleSaveAll}
          onCancel={handleDiscardChanges}
        />
      )}

      {/* Flash Container */}
      {Flash}
    </>
  );
}

function TeamInfo({ onSave }) {
  const { team, refreshTeam } = useContext(TeamContext);
  const [loading, setLoading] = useState(false);

  const [name, setName] = useState(team.name);
  const ctxData = useContext(SettingsContext);

  const { checkAndHandleDunning } = useTeamsCTA();

  useEffect(() => {
    ctxData.unsavedTeamName = null;
    setName(team.name);
  }, [team]);

  const handleChangeName = (e) => {
    const val = e.target.value;
    setName(val);
    ctxData.unsavedTeamName = val;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const dunningStatus = checkAndHandleDunning();

    if (dunningStatus === DUNNING_STATUS.EXPIRED) return;

    if (loading) return;
    setLoading(true);
    try {
      await updateTeam(name, team.country);
      trackTeamsSettingUpdated({ setting: teamsSettings.COMPANY_NAME });
      await refreshTeam();
      if (typeof onSave === "function") onSave();
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  const handleCancel = () => {
    setName(team.name);
  };

  const shouldRenderButtons = name && name !== team.name;

  return (
    <form onSubmit={handleSubmit} style={{ width: "340px" }}>
      <label className="flex flex-col">
        <Text className="mb-2">Name</Text>
        <Input name="teamName" value={name} onChange={handleChangeName} />
      </label>
      {shouldRenderButtons && (
        <div className="flex mt-8">
          <Button type="submit" className="mr-2 font-medium w-[62px]">
            {loading ? <Icon name="spinner" /> : "Save"}
          </Button>
          <Button
            ghost
            type="button"
            className="font-medium"
            onClick={handleCancel}
            disabled={loading}
          >
            Cancel
          </Button>
        </div>
      )}
    </form>
  );
}

const UPDATE_INTEGRATION_MODAL_TYPES = {
  EDIT: "EDIT",
  ADD: "ADD",
};

const MODAL_TYPES = {
  SUCCESSFULLY_ENABLED: "SUCCESSFULLY_ENABLED",
  UPDATE: "UPDATE",
  REMOVE: "REMOVE",
  UPGRADE_TO_PRO: "UPGRADE_TO_PRO",
};

const INTEGRATION_STATUS = {
  HEALTHY: "healthy",
  UNHEALTHY: "unhealthy",
};

const INTEGRATION_STATUS_DATA = {
  [INTEGRATION_STATUS.HEALTHY]: {
    color: "lime",
    text: "Active",
  },
  [INTEGRATION_STATUS.UNHEALTHY]: {
    color: "red",
    text: "Inactive",
  },
};

function ConcurIntegration() {
  const { team } = useContext(TeamContext);
  const { userData } = useContext(UserDataContext);
  const [activeModal, setActiveModal] = useState(null);
  const [modalType, setModalType] = useState(
    UPDATE_INTEGRATION_MODAL_TYPES.ADD
  );
  const [integrations, setIntegrations] = useState(new Map());
  const [selectedIntegration, setSelectedIntegration] = useState(null);

  const [flash, Flash] = useFlash();

  const [listIntegrations, { loading: listIntegrationsLoading, refetch }] =
    useLazyQuery(LIST_INTEGRATIONS, {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
    });

  const [checkIntegration, { loading: checkIntegrationLoading }] = useMutation(
    CHECK_INTEGRATION,
    {
      notifyOnNetworkStatusChange: true,
    }
  );

  const { checkAndHandleDunning } = useTeamsCTA();

  const subscription =
    team?.subscription?.plan || team?.pendingSubscription?.plan;

  const upgradeOptions = {
    forceShowPickPlan: true,
    onBeforeStart: () => {
      trackTeamsUpgradeStarted({
        src: teamsUpgradeSources.SETTINGS_PAGE,
        orgId: team.orgId,
        orgGroupId: team.orgGroupId,
        feature: teamsUpgradeFeatures.CONCUR_INTEGRATION,
      });
    },
    only: TEAMS_SUBSCRIPTION_PLANS.TEAMS_PRO,
    currentSubPlan: team.subscription?.plan,
  };

  const [startUpgradePlanFlow, renderUpgradePlanFlow] = team.subscription
    ? // eslint-disable-next-line
      useUpgradePlanFlow(upgradeOptions) // preselect pro and confirm
    : // eslint-disable-next-line
      usePickFirstPlanFlow(upgradeOptions); // preselect pro and invite first users

  const setIntegrationsData = (integrations) => {
    const newMap = new Map(
      integrations.map((integration) => [
        integration.id,
        {
          ...integration,
          status: team.isExpired
            ? INTEGRATION_STATUS.UNHEALTHY
            : integration.status,
        },
      ])
    );
    setIntegrations(newMap);
  };

  const fetchIntegrations = async () => {
    try {
      const res = await listIntegrations();
      const integrationsData = res?.data?.integrations?.integrations || [];
      setIntegrationsData(integrationsData);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    braze.logCustomEvent("teams_dash_settings_view");
    fetchIntegrations();
  }, []);

  const handleAddIntegration = () => {
    const dunningStatus = checkAndHandleDunning();

    if (dunningStatus === DUNNING_STATUS.EXPIRED) return;

    setModalType(UPDATE_INTEGRATION_MODAL_TYPES.ADD);
    setActiveModal(MODAL_TYPES.UPDATE);
    trackIntegrationEnableStarted({
      subscriptionId: userData?.subscriptionType,
      orgId: team.orgId,
      orgGroupId: team.orgGroupId,
      integration: Integration.CONCUR,
    });
  };

  const handleEditIntegration = (integration) => {
    const dunningStatus = checkAndHandleDunning();

    if (dunningStatus === DUNNING_STATUS.EXPIRED) return;

    setSelectedIntegration(integration);
    setModalType(UPDATE_INTEGRATION_MODAL_TYPES.EDIT);
    setActiveModal(MODAL_TYPES.UPDATE);
    trackIntegrationEditStarted({
      subscriptionId: userData?.subscriptionType,
      orgId: team.orgId,
      orgGroupId: team.orgGroupId,
      integration: Integration.CONCUR,
    });
  };

  const handleRemoveIntegration = (integration) => {
    setSelectedIntegration(integration);
    setActiveModal(MODAL_TYPES.REMOVE);
  };

  const handleCheckIntegration = async (integration) => {
    const dunningStatus = checkAndHandleDunning();

    if (dunningStatus === DUNNING_STATUS.EXPIRED) return;

    try {
      const {
        data: {
          checkIntegration: { status },
        },
      } = await checkIntegration({
        variables: {
          integrationId: integration.id,
        },
      });

      integrations.set(integration.id, {
        ...integration,
        status,
      });

      setIntegrations(new Map(integrations));

      trackIntegrationChecked({
        subscriptionId: userData?.subscriptionType,
        orgId: team.orgId,
        orgGroupId: team.orgGroupId,
        integration: Integration.CONCUR,
        integrationStatus:
          status === INTEGRATION_STATUS.HEALTHY
            ? IntegrationStatus.ACTIVE
            : IntegrationStatus.INACTIVE,
      });

      if (status === INTEGRATION_STATUS.UNHEALTHY) {
        flash(
          <Text>
            Your Concur integration might not be working properly, please try
            again later or message{" "}
            <a
              className="text-white underline"
              href="mailto:support@mileiq.com"
            >
              support@mileiq.com
            </a>
          </Text>,
          {
            type: FlashTypes.ERROR,
            hideAfterMs: 10000,
          }
        );
      }
    } catch (err) {
      console.log(err);
      flash(
        <Text>
          Something went wrong while checking the integration status, please try
          again later or message{" "}
          <a className="text-white underline" href="mailto:support@mileiq.com">
            support@mileiq.com
          </a>
        </Text>,
        {
          type: FlashTypes.ERROR,
        }
      );
    }
  };

  const trackSuccessEvents = () => {
    const props = {
      subscriptionId: userData?.subscriptionType,
      orgId: team.orgId,
      orgGroupId: team.orgGroupId,
      integration: Integration.CONCUR,
    };
    if (activeModal === MODAL_TYPES.UPDATE) {
      switch (modalType) {
        case UPDATE_INTEGRATION_MODAL_TYPES.ADD:
          trackIntegrationEnableCompleted(props);
          break;
        case UPDATE_INTEGRATION_MODAL_TYPES.EDIT:
          trackIntegrationEditCompleted(props);
          break;
      }
    } else if (activeModal === MODAL_TYPES.REMOVE) {
      trackIntegrationRemoved(props);
    }
  };

  const onSuccess = async (message) => {
    trackSuccessEvents();
    if (
      modalType === UPDATE_INTEGRATION_MODAL_TYPES.EDIT ||
      activeModal === MODAL_TYPES.REMOVE
    ) {
      flash(<Text>{message}</Text>, { type: FlashTypes.SAVED });
      setActiveModal(null);
    } else {
      setActiveModal(MODAL_TYPES.SUCCESSFULLY_ENABLED);
    }

    const res = await refetch();
    setIntegrationsData(res?.data?.integrations?.integrations || []);
  };

  const onUpdateError = (msg) => {
    if (
      activeModal === MODAL_TYPES.UPDATE &&
      modalType === UPDATE_INTEGRATION_MODAL_TYPES.EDIT
    ) {
      trackIntegrationEditFailed({
        subscriptionId: userData?.subscriptionType,
        orgId: team.orgId,
        orgGroupId: team.orgGroupId,
        integration: Integration.CONCUR,
        integrationFailure: msg,
      });
    }
  };

  const onRemoveError = (message) => {
    flash(<Text>{message}</Text>, {
      type: FlashTypes.ERROR,
    });
  };

  const handleUpgradeClicked = () => {
    const dunningStatus = checkAndHandleDunning();

    if (dunningStatus === DUNNING_STATUS.EXPIRED) return;

    startUpgradePlanFlow();
  };

  const shouldRenderUpsellWall =
    !subscription || !isTeamsProSubscription(subscription);

  const shouldRenderLoading =
    !shouldRenderUpsellWall && listIntegrationsLoading;

  const shouldRenderEmptyState =
    !shouldRenderUpsellWall &&
    !listIntegrationsLoading &&
    integrations.size === 0;

  const shouldRenderIntegrationsList =
    !shouldRenderUpsellWall &&
    !listIntegrationsLoading &&
    integrations.size > 0;

  return (
    <>
      {shouldRenderIntegrationsList && (
        <Text paragraph className="mt-2.5">
          Concur integration allows you to submit your MileIQ reports directly
          to Concur.
        </Text>
      )}
      {shouldRenderEmptyState && (
        <Text paragraph className="mt-2.5">
          Enable Concur integration so you and your team can submit reports
          directly to Concur.
        </Text>
      )}
      <div className={shouldRenderUpsellWall ? "mt-2.5" : "mt-8"}>
        {shouldRenderUpsellWall && (
          <>
            <p className="text-15 mt-2.5">
              Upgrade to Teams Pro for Concur integration so you and your team
              can submit reports directly to Concur.
            </p>
            <Button
              className="font-medium mt-[30px]"
              onClick={handleUpgradeClicked}
              secondary
            >
              Upgrade now
            </Button>
          </>
        )}
        {shouldRenderLoading && <Loader sm />}
        {shouldRenderIntegrationsList && (
          <div className="flex flex-col gap-2">
            {Array.from(integrations.values()).map((integration) => {
              if (integration.type === "concur") {
                return (
                  <div
                    key={integration.id}
                    className="flex justify-between items-center rounded-20 bg-[#F6F5F4] py-4 px-6"
                  >
                    <div className="flex items-center gap-2">
                      <Text bold>Status: </Text>
                      <Text
                        color={checkIntegrationLoading ? "white" : "black"}
                        bold={!checkIntegrationLoading}
                        className={`miq-badge miq-badge-${
                          checkIntegrationLoading
                            ? "black"
                            : INTEGRATION_STATUS_DATA[integration.status]?.color
                        }`}
                      >
                        {checkIntegrationLoading
                          ? "Checking..."
                          : INTEGRATION_STATUS_DATA[integration.status]?.text}
                      </Text>
                    </div>
                    <div className="flex items-center gap-2">
                      <Button
                        className="mix-blend-multiply"
                        color={checkIntegrationLoading ? "gray" : "black"}
                        icon="eye-fixed"
                        secondaryBordered
                        disabled={checkIntegrationLoading}
                        onClick={() => handleCheckIntegration(integration)}
                      >
                        <Text semibold>Check</Text>
                      </Button>
                      <Button
                        className="mix-blend-multiply"
                        color="black"
                        icon="edit"
                        secondaryBordered
                        onClick={() => handleEditIntegration(integration)}
                      >
                        <Text semibold>Edit</Text>
                      </Button>
                      <Button
                        className="mix-blend-multiply text-red"
                        secondaryBordered
                        color="red"
                        icon="trash"
                        onClick={() => handleRemoveIntegration(integration)}
                      >
                        <Text semibold>Remove</Text>
                      </Button>
                    </div>
                  </div>
                );
              }
            })}
          </div>
        )}
        {shouldRenderEmptyState && (
          <Button
            secondary
            className="mt-8 font-medium"
            onClick={handleAddIntegration}
          >
            Enable Concur Integration
          </Button>
        )}
        {activeModal === MODAL_TYPES.UPDATE && (
          <UpdateConcurIntegrationModal
            integration={selectedIntegration}
            isEdit={modalType === UPDATE_INTEGRATION_MODAL_TYPES.EDIT}
            onClose={() => setActiveModal(null)}
            onSuccess={onSuccess}
            onError={onUpdateError}
          />
        )}
        {activeModal === MODAL_TYPES.REMOVE && (
          <RemoveConcurIntegrationModal
            integration={selectedIntegration}
            onClose={() => setActiveModal(null)}
            onError={onRemoveError}
            onSuccess={onSuccess}
          />
        )}
        {activeModal === MODAL_TYPES.SUCCESSFULLY_ENABLED && (
          <ConcurIntegrationSuccessModal onClose={() => setActiveModal(null)} />
        )}
        {renderUpgradePlanFlow()}
        {Flash}
      </div>
    </>
  );
}

function ReportReminders() {
  const {
    isReminderEnabled,
    toggleReminder,
    reminderPeriod,
    setReminderPeriod,
    setCustomReminderSettings,
    reminderStartDate,
    setReminderStartDate,
    submitReminderSettings,
    isSubmitting,
    fetchReportReminder,
    isFetching,
    hasChanges,
    revertReminderSettingsToOriginal,
    periodOptions,
  } = useReportReminder();

  const customReportReminderModal = useElement(
    CUSTOM_REPORT_REMINDER_MODAL_ELEMENT_ID,
    {
      props: {
        onClose: () => {
          customReportReminderModal.deactivate();
        },
      },
    }
  );

  const { team } = useContext(TeamContext);

  const { checkAndHandleDunning } = useTeamsCTA();

  const getProPlanFlowOpts = {
    forceShowPickPlan: true,
    onBeforeStart: () => {
      trackTeamsUpgradeStarted({
        src: teamsUpgradeSources.SETTINGS_PAGE,
        orgId: team.orgId,
        orgGroupId: team.orgGroupId,
        feature: teamsUpgradeFeatures.REPORT_REMINDERS,
      });
    },
    only: TEAMS_SUBSCRIPTION_PLANS.TEAMS_PRO,
    currentSubPlan: team.subscription?.plan,
  };

  const [startGetProPlanFlow, renderGetProPlanFlow] = team.subscription
    ? // eslint-disable-next-line
      useUpgradePlanFlow(getProPlanFlowOpts)
    : // eslint-disable-next-line
      usePickFirstPlanFlow(getProPlanFlowOpts);

  useEffect(() => {
    fetchReportReminder();
  }, []);

  function handleSubmit(e) {
    e.preventDefault();

    const dunningStatus = checkAndHandleDunning();

    if (dunningStatus === DUNNING_STATUS.EXPIRED) return;

    submitReminderSettings();
  }

  function handleChangePeriod(value) {
    if (value === REMINDER_PERIOD.CUSTOM) {
      customReportReminderModal.activate();
      return;
    }

    setReminderPeriod(value);
    setCustomReminderSettings(null);
  }

  const handleUpgradeClicked = () => {
    const dunningStatus = checkAndHandleDunning();

    if (dunningStatus === DUNNING_STATUS.EXPIRED) return;

    startGetProPlanFlow();
  };

  if (!isTeamsProSubscription(team?.subscription?.plan)) {
    return (
      <div>
        <div className="flex items-center gap-2">
          <h5>Report reminders</h5>
          <BadgeTeamsPro />
        </div>
        <p className="text-15 mt-2.5 max-w-[620px]">
          Upgrade to Teams Pro to schedule automatic reminders to encourage your
          team to submit reports.
        </p>
        <Button
          secondary
          className="font-medium mt-[30px]"
          onClick={handleUpgradeClicked}
        >
          Upgrade now
        </Button>
        {renderGetProPlanFlow()}
      </div>
    );
  }

  return (
    <div>
      <div className="flex justify-between">
        <div className="flex items-center gap-2">
          <h5>Report reminders</h5>
          <BadgeTeamsPro />
        </div>
        {!isFetching && (
          <Switcher
            lg
            isOn={isReminderEnabled}
            label={isReminderEnabled ? "Yes" : "No"}
            onChange={() => {
              const dunningStatus = checkAndHandleDunning();

              if (dunningStatus === DUNNING_STATUS.EXPIRED) return;

              toggleReminder();
            }}
          />
        )}
      </div>
      {isFetching && <Loader sm />}
      {!isFetching && (
        <>
          <p className="text-15 mt-2.5">
            Schedule automatic reminders to encourage your team to submit
            reports.
          </p>
          {isReminderEnabled && (
            <form className="mt-[30px]" onSubmit={handleSubmit}>
              <div className="flex gap-[30px]">
                <div className="flex flex-col gap-2.5">
                  <label className="text-15 font-medium">First reminder</label>
                  <div className="w-[340px]">
                    <DatePicker
                      icon="calendar-no-days"
                      date={reminderStartDate}
                      minDate={new Date()}
                      onSelect={setReminderStartDate}
                      alignment={{
                        offset: { y: -400 },
                      }}
                      customFormat={
                        isSameYear(new Date(), reminderStartDate)
                          ? "EEEE, MMMM d"
                          : "EEEE, MMMM d, yyyy"
                      }
                    />
                  </div>
                </div>
                <div className="flex flex-col gap-2.5">
                  <label className="text-15 font-medium">Repeats</label>
                  <div className="w-[340px]">
                    <Select
                      matchTriggerWidth
                      alignTop
                      options={periodOptions}
                      selected={reminderPeriod}
                      onSelect={handleChangePeriod}
                    />
                  </div>
                </div>
              </div>
              {hasChanges && (
                <div className="mt-[30px] flex gap-2.5">
                  <Button className="font-medium w-[62px]">
                    {isSubmitting ? <Icon name="spinner" /> : "Save"}
                  </Button>
                  <Button
                    disabled={isSubmitting}
                    className="font-medium"
                    ghost
                    onClick={revertReminderSettingsToOriginal}
                  >
                    Cancel
                  </Button>
                </div>
              )}
            </form>
          )}
        </>
      )}
      {renderGetProPlanFlow()}
    </div>
  );
}

function ExcludeCommute() {
  const {
    isEnabled,
    distance,
    isSubmitting,
    isFetching,
    toggleCommuteSettings,
    fetchCommuteSettings,
    submitCommuteSettings,
  } = useTeamsCommuteSettings();
  const { team } = useContext(TeamContext);
  const { userData } = useContext(UserDataContext);
  const { checkAndHandleDunning } = useTeamsCTA();
  const [newDistance, setNewDistance] = useState(0);
  const [isInputDirty, setIsInputDirty] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    if (isTeamsProSubscription(team?.subscription?.plan)) {
      fetchCommuteSettings();
    }
  }, [team]);

  useEffect(() => {
    setNewDistance(distance);
  }, [distance]);

  const onInputChange = (e) => {
    const val = e.target.value;
    // regex to allow only numbers and 2 decimal points
    if (/^(-?\d+(\.\d{0,2})?)?$/.test(val)) {
      setNewDistance(val);
    }
  };

  const cancelEditCommuteSettings = () => {
    setNewDistance(distance);
    setIsInputDirty(false);
  };

  async function handleSubmit(e) {
    e.preventDefault();
    inputRef.current.blur();
    await submitCommuteSettings({
      isEnabled: true,
      distance: newDistance,
    });
    setNewDistance(distance);
    setIsInputDirty(false);
  }

  const getProPlanFlowOpts = {
    forceShowPickPlan: true,
    onBeforeStart: () => {
      trackTeamsUpgradeStarted({
        src: teamsUpgradeSources.SETTINGS_PAGE,
        orgId: team.orgId,
        orgGroupId: team.orgGroupId,
        feature: teamsUpgradeFeatures.EXCLUDE_COMMUTE,
      });
    },
    only: TEAMS_SUBSCRIPTION_PLANS.TEAMS_PRO,
    currentSubPlan: team.subscription?.plan,
  };

  const [startGetProPlanFlow, renderGetProPlanFlow] = team.subscription
    ? // eslint-disable-next-line
      useUpgradePlanFlow(getProPlanFlowOpts)
    : // eslint-disable-next-line
      usePickFirstPlanFlow(getProPlanFlowOpts);

  const handleToggle = () => {
    toggleCommuteSettings();
  };
  const handleUpgradeClicked = () => {
    startGetProPlanFlow();
  };

  const DriveUnitSymbol = () => {
    const distanceUnit = userData?.distanceUnit || "mi";
    return (
      <span className="min-w-fit px-[8px] leading-none mds-body-m text-[var(--color-text-tertiary)]">
        {distanceUnit} / drive
      </span>
    );
  };

  return (
    <div>
      <div className="flex justify-between">
        <div className="flex items-center gap-2">
          <h5>Commute mileage</h5>
          <BadgeTeamsPro />
        </div>
        {!isFetching && (
          <div
            data-chmln={
              isEnabled
                ? "commute-settings-switch-on"
                : "commute-settings-switch-off"
            }
          >
            <Switcher
              lg
              isOn={isEnabled}
              label={isEnabled ? "Yes" : "No"}
              onChange={() => {
                if (isSubmitting) return;
                const dunningStatus = checkAndHandleDunning();
                if (dunningStatus === DUNNING_STATUS.EXPIRED) return;
                isTeamsProSubscription(team?.subscription?.plan)
                  ? handleToggle()
                  : handleUpgradeClicked();
              }}
            />
          </div>
        )}
      </div>

      <p className="text-15 mt-2.5">
        Set a team-wide commute distance and MileIQ will automatically exclude
        that distance from any drives classified as “Business + Commute.”
      </p>
      {isEnabled && (
        <form className="mt-[30px]" onSubmit={handleSubmit}>
          <Input
            ref={inputRef}
            className={"w-[300px]"}
            rightIcon={<DriveUnitSymbol />}
            value={newDistance}
            placeholder={distance}
            onChange={onInputChange}
            onFocus={() => {
              setIsInputDirty(true);
              trackExcludeCommuteSettingsUpdateStarted({
                orgId: team.orgId,
                country: team.country,
                subPlan: team.subscription?.plan,
                freeTrial: team.subscription?.isFreeTrialActive,
              });
            }}
            label="Commute distance (one-way)"
          />
          {isInputDirty && (
            <div>
              <div className="mt-[10px]">
                <Text md className="inline-block text-black/70">
                  {
                    "Changes will not affect drives you have already received, only drives going forward."
                  }
                </Text>
              </div>
              <div className="mt-[30px] flex gap-2.5">
                <Button
                  disabled={newDistance == distance || !newDistance}
                  className="font-medium w-[62px]"
                >
                  {isSubmitting ? <Icon name="spinner" /> : "Save"}
                </Button>
                <Button
                  disabled={isSubmitting}
                  className="font-medium"
                  ghost
                  onClick={cancelEditCommuteSettings}
                >
                  Cancel
                </Button>
              </div>
            </div>
          )}
        </form>
      )}
      {renderGetProPlanFlow()}
    </div>
  );
}

function AutoReview() {
  const { team } = useContext(TeamContext);
  const {
    isEnabled,
    toggleAutoReview,
    fetchAutoReviewSettings,
    isFetching,
    isSubmitting,
  } = useTeamsAutoReview();
  const { checkAndHandleDunning } = useTeamsCTA();

  const getProPlanFlowOpts = {
    forceShowPickPlan: true,
    onBeforeStart: () => {
      trackTeamsUpgradeStarted({
        src: teamsUpgradeSources.SETTINGS_PAGE,
        orgId: team.orgId,
        orgGroupId: team.orgGroupId,
        feature: teamsUpgradeFeatures.AUTOMATIC_REVIEW,
      });
    },
    only: TEAMS_SUBSCRIPTION_PLANS.TEAMS_PRO,
    currentSubPlan: team.subscription?.plan,
  };

  const [startGetProPlanFlow, renderGetProPlanFlow] = team.subscription
    ? // eslint-disable-next-line
      useUpgradePlanFlow(getProPlanFlowOpts)
    : // eslint-disable-next-line
      usePickFirstPlanFlow(getProPlanFlowOpts);

  useEffect(() => {
    if (isTeamsProSubscription(team?.subscription?.plan)) {
      fetchAutoReviewSettings();
    }
  }, [team]);

  const handleToggle = () => {
    toggleAutoReview();
  };

  const handleUpgradeClicked = () => {
    startGetProPlanFlow();
  };

  return (
    <div>
      <div className="flex justify-between">
        <div className="flex items-center gap-2">
          <h5>Auto-approvals</h5>
          <BadgeTeamsPro />
        </div>
        {!isFetching && (
          <div
            data-chmln={
              isEnabled
                ? "automatic-review-settings-switch-on"
                : "automatic-review-settings-switch-off"
            }
          >
            <Switcher
              lg
              isOn={isEnabled}
              label={isEnabled ? "Yes" : "No"}
              onChange={() => {
                if (isSubmitting) return;
                const dunningStatus = checkAndHandleDunning();
                if (dunningStatus === DUNNING_STATUS.EXPIRED) return;
                isTeamsProSubscription(team?.subscription?.plan)
                  ? handleToggle()
                  : handleUpgradeClicked();
              }}
            />
          </div>
        )}
        {renderGetProPlanFlow()}
      </div>

      <p className="text-15 mt-2.5">
        Automatically approve drives that start or end at a{" "}
        <Link className="text-[var(--color-text-link)]" to="/teams/locations">
          Team Location
        </Link>
        . These drives will no longer show up in your Drives to approve, but you
        can review them in the “Drives and reports” tab.
      </p>
    </div>
  );
}
