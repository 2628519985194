import { useLazyQuery, useMutation } from "@apollo/client";
import { createContext, useContext, useEffect, useState } from "react";

import { tryRefreshToken } from "src/services/auth";
import { report } from "src/services/error-reporting";
import { getAccessToken } from "src/services/storage";
import {
  getExistingStandaloneSubscription,
  getTeamUserRole,
  trackTeamsInviteAccepted,
} from "src/services/tracking";
import { parseJwt } from "src/services/utils";

import { ACCEPT_INVITE } from "src/graphql/mutations";
import { GET_NOTIFICATIONS } from "src/graphql/queries";

import TeamContext from "./TeamContext";
import { useUserData } from "./UserContext";

const NotificationsContext = createContext(null);

export function useNotifications() {
  return useContext(NotificationsContext);
}

export default NotificationsContext;

export function NotificationsProvider({ children }) {
  const { refreshTeam } = useContext(TeamContext);
  const { refreshSubscription, userData } = useUserData();
  const [loading, setLoading] = useState(false);
  const [notifications, setNotifications] = useState(null);

  const [getNotificationsQuery, notificationState] = useLazyQuery(
    GET_NOTIFICATIONS,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
    }
  );

  const [acceptInviteMutation, acceptInviteState] = useMutation(ACCEPT_INVITE, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  const acceptInvite = async (
    token = notifications?.invite_token,
    acceptedFromEmailLink = false
  ) => {
    try {
      setLoading(true);
      const { data } = await acceptInviteMutation({ variables: { token } });

      const acceptInvite = data?.acceptInvite;

      if (
        acceptInvite?.code === 403 &&
        acceptInvite?.detail ===
          "Member cannot accept invite from an expired team"
      ) {
        throw new Error(acceptInvite?.detail);
      }

      await tryRefreshToken();
      await fetchNotifications();
      await refreshSubscription();
      const {
        orgId,
        orgGroupId,
        name: teamName,
        subscription: { plan },
      } = await refreshTeam(true);

      const accessToken = getAccessToken();
      const { team_admin, team_driver } = parseJwt(accessToken);

      trackTeamsInviteAccepted({
        orgId,
        orgGroupId,
        acceptedFromEmailLink,
        teamName,
        plan,
        existingSaSubscription: getExistingStandaloneSubscription(userData),
        teamUserRole: getTeamUserRole({
          isDriver: Boolean(team_driver),
          isAdmin: Boolean(team_admin),
        }),
      });
      return data;
    } catch (e) {
      // report and let UI handle error
      report(e);
      throw e;
    } finally {
      setLoading(false);
    }
  };

  async function fetchNotifications() {
    try {
      const {
        data: { getNotifications: notifications },
      } = await getNotificationsQuery();
      if (notifications?.invite_link) {
        const search = new URLSearchParams(
          notifications.invite_link.split("?")[1]
        );
        const invite_token = search.get("accept-invite");
        notifications.invite_link = `${location.origin}/user/teams/accept-invite?token=${invite_token}`;
        notifications.invite_token = invite_token;
      }
      setNotifications(notifications);
      return notifications;
    } catch (err) {
      setNotifications(null);
      console.error(err);
    }
  }

  useEffect(() => {
    fetchNotifications();
  }, []);

  return (
    <NotificationsContext.Provider
      value={{
        acceptInvite,
        fetchNotifications,
        called: notificationState?.called,
        notifications,
        loading:
          loading || notificationState?.loading || acceptInviteState?.loading,
        error: notificationState?.error || acceptInviteState?.error,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
}
